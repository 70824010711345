import React, { useState } from "react"
import { useTranslate, useDataProvider, GET_LIST,  SimpleForm, AutocompleteInput, Title } from "react-admin"
import styled from "styled-components"

import { Snackbar, FormControl, Alert } from "@mui/material"
import { LocalMallOutlined } from "@material-ui/icons"
import { RoutesEnum } from "../../core/restProvider"
import { useEffect } from "react"
import SearchInput from "./SearchInput"
import CheckoutButton from "./CheckoutButton"
import ProductsList from "./ProductsList"
import OrderModal from "./OrderModal"
import CartList from "./CartList"
import TotalInfo from "./TotalInfo"
import { observer, inject } from 'mobx-react'
import { round } from "../../tools/utils"
import { sortABC } from "./utils/functions"
import { AutocompleteInputStyled } from "../common/InputFields"


const Container = styled.div``

const ProductContainer = styled.div`
  flex: 1;
  @media (max-width: 900px) {
    min-height: 70vh;
  }
`

const CheckoutContainer = styled.div`
  flex: 1;
  margin-right: 5vh;
  @media (max-width: 900px) {
    margin-right: 0;
  }
`

const EmptyContainer = styled.div`
  margin-top: 30px;
  text-align: center;
`

const EmptyText = styled.p`
  text-align: center;
  line-height: 1.2;
  font-size: 1rem;
  font-weight: 400;
  margin: 0px;
  word-break: normal;
  color: rgb(96, 96, 119);
`

const EmptyIcon = styled(LocalMallOutlined)`
  color: rgb(220, 0, 78);
  font-size: 2rem;
`

const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0;
  }
`
const StyledSimpleForm = styled(SimpleForm)`
  div[name="do1"] {
    display: flex;
    flex-direction: row!important;
    gap: 16px;  
  }
  

  .MuiCardContent-root:last-child {
    padding-bottom: 0px;
}
`

const StyledContainerInput = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: start;
`

const CashierTab = ({ authStore }) => {
  const restProvider = useDataProvider()
  const translate = useTranslate()

  const [orgSelected, setOrgSelected] = useState("")
  const [systemSelected, setSystemSelected] = useState("")
  const [organizations, setOrganizations] = useState([])
  const [systems, setSystems] = useState([])
  const [products, setProducts] = useState([])
  const [isOpenSnackbar, setOpenSnackbar] = useState(false)
  const [snackbar, setSnackbar] = useState({})
  const [search, setSearch] = useState("")
  const [category, setCategory] = useState("all")
  const [categories, setCategories] = useState([])
  const [selected, setSelected] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [sum, setSum] = useState(0)

  const openSnackbar = (message, severity) => {
    setOpenSnackbar(true)
    setSnackbar({ message: message, severity: severity })
  }

  const getOrgs = async () => {
    try {
      const { data } = await restProvider(GET_LIST, RoutesEnum.ORGANIZATIONS, { filter: {}, sort: {}, pagination: { page: 1, perPage: 1000 } })

      setOrganizations(data)
    } catch (error) {
      openSnackbar(error.message, "error")
    }
  }

  const getSystems = async (orgId) => {
    try {
      const { data } = await restProvider(GET_LIST, RoutesEnum.SYSTEMS, {
        filter: { org_id: orgId },
        sort: {},
        pagination: { page: 1, perPage: 1000 }
      })

      setSystems(data)
    } catch (error) {
      openSnackbar(error.message, "error")
    }
  }

  const getProducts = async (systemId) => {
    try {
      const { data } = await restProvider(GET_LIST, RoutesEnum.PRODUCTS, {
        filter: { org_id: orgSelected, system_id: systemId, sort: [['sortOrder', 1], ['name', 1]], status: 'available,unavailable' },
        sort: {},
        pagination: { page: 1, perPage: 1000 }
      })
      if (data.length > 0) {
        setProducts(data)
        const categories = [...new Set(data.map((a) => a.category))].sort(sortABC)
        setCategories(categories)
      }
    } catch (error) {
      openSnackbar(error.message, "error")
    }
  }

  useEffect(() => {

    if (!authStore.isAutenticated) {
      return
    }

    if (authStore.isSuperAdmin()) {
      getOrgs()
    }
    if (authStore.isOrgManager()) {
      const { org_id } = authStore.getSettings()
      setOrgSelected(org_id)
      getSystems(org_id)
    }

    if (!authStore.isSuperAdmin() && !authStore.isOrgManager()) {
      const { org_id, system_id } = authStore.getSettings()
      getProducts(system_id)
      setOrgSelected(org_id)
      setSystemSelected(system_id)
    }
  }, [authStore.isAutenticated])

  const onFocusInput = () => {
    setCategory("all")
  }

  const handleChange = (e) => {
    setSearch(e.target.value)
  }

  const handleClear = () => {
    setSearch("")
  }

  const handleFilter = (e) => {
    if (e.target.value !== "all") {
      const categoryName = e.target.value
      const array = []
      products.forEach((product) => {
        if (product.category === categoryName) {
          array.push(product)
        }
      })
    }

    setCategory(e.target.value)
    setSearch(e.target.value)
  }

  const orgSelectedChanged = (orgId) => {
    setOrgSelected(orgId)
    setSystemSelected("")
    setProducts([])
    setCategories([])
    getSystems(orgId)
  }

  const systemSelectChanged = (systemId) => {
    setSystemSelected(systemId)

    if (systemId && orgSelected) {
      getProducts(systemId)
    }
  }
  const handleClose = () => setSelected(null)

  const snackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }

  const handleSelect = (item) => setSelected(item)

  const handleAdd = (item) => {
    const items = selectedProducts.sort((x1, x2) => x1.listId > x2.listId);
    const lastListId = items.length > 0 ? items[items.length - 1].listId : 0;
    const listItem = Object.assign({listId: lastListId + 1 }, item);
    if (listItem.quantity) {
      setSum(round(round(listItem.price * listItem.quantity) + sum))
      setSelectedProducts([...selectedProducts, listItem])
    }
    setSelected(null);
  }

  const handleRemove = (listId) => {
    setSelectedProducts(
      selectedProducts.filter((item) => {
        if (item.listId === listId ) {
          setSum(round(sum - round(item.price * item.quantity)))
          return false
        }
        return true
      })
    )
  }

  const getFilteredProducts = () => {
    return search !== ''
      ? products.filter((item) => item.category.indexOf(search) !== -1 || item.name.indexOf(search) !== -1)
      : products
  }

  return (
    <>
    <Title title={translate("customRoot.myMenu.cashier")} />
    <Container>
      <StyledContainerInput>
        <StyledSimpleForm
          name='do1'
          record={{organizationId: '', system_id: ''}}
          toolbar={<></>}
          validate={() => {}}
        >
        {authStore.isSuperAdmin() && (
          <AutocompleteInputStyled
            label='resources.organizations.singular'
            className="autocompeleteMekome"
            choices={organizations}
            sort={{ field: "name", order: "ASC" }}
            onChange={orgSelectedChanged}
            source='organizationId'/>
        )}

        {(authStore.isSuperAdmin() || authStore.isOrgManager()) && (
          <AutocompleteInputStyled
            label='customRoot.organizations.list.system'
            className="autocompeleteMekome"
            choices={systems}
            source='systemId'
            onChange={systemSelectChanged}
            sort={{ field: "name", order: "ASC" }} />
        )}
        </StyledSimpleForm>
      </StyledContainerInput>

      <BodyContainer>
        <ProductContainer>
          <SearchInput
            value={search}
            category={category}
            categories={categories}
            onFocus={onFocusInput}
            handleChange={handleChange}
            handleFilter={handleFilter}
            setSearch={setSearch}
            clear={handleClear}
          />
          {!getFilteredProducts().length && (
            <EmptyContainer>
              <EmptyIcon />
              <EmptyText>{translate("customRoot.upload.no_items")}</EmptyText>
            </EmptyContainer>
          )}
          <ProductsList handleSelect={handleSelect} products={getFilteredProducts()} />
        </ProductContainer>

        <CheckoutContainer>
          <CheckoutButton
            data={{ sum, orgId: orgSelected, systemId: systemSelected, selectedProducts }}
            open={true}
            permanent
            anchor='left'
            disabled={!selectedProducts.length}
          />
          <TotalInfo sum={sum} />
          <CartList list={selectedProducts} handleRemove={handleRemove} isCancel />
        </CheckoutContainer>
      </BodyContainer>

      <OrderModal open={!!selected} onClose={handleClose} onConfirm={handleAdd} {...selected} />
      <Snackbar open={isOpenSnackbar} autoHideDuration={6000} onClose={snackbarClose}>
        <Alert onClose={snackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
    </>
  )
}

export default inject('authStore')(observer(CashierTab))
