// in ./ResetViewsButton.js
import React, { Fragment, useState, useEffect } from "react"
import { Button, useUpdateMany, useRefresh, useNotify, useUnselectAll, useTranslate } from "react-admin"
import styled from "styled-components"
import requests from "../../core/requests"
import Alert from "../common/Alert"
import { Slide, DialogTitle, DialogContent, DialogActions, Dialog, Snackbar, MenuItem, Select } from "@mui/material"
import { observer, inject } from 'mobx-react'

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  min-width: 400px;
`
const StyledSelect = styled(Select)`
  min-width: 120px;
`
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const upload_orders_file = false

const UpdateStatusButton = ({ authStore, selectedIds = [], orders, ...props }) => {
  const translate = useTranslate()
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState("")
  const [order, setOrder] = useState("")
  const { org_id, system_id } = props.filterValues
  const refresh = useRefresh()
  const notify = useNotify()
  const [isOpenSnackbar, setOpenSnackbar] = useState(false)
  const [sendMessageBtnDisabled, setSendMessageBtnDisabled] = useState(false)
  const [snackbar, setSnackbar] = useState({})
  const unselectAll = useUnselectAll()
  
  const [updateMany] = useUpdateMany(
    "orders",
    { 
      ids: [ selectedIds, org_id, system_id ],
      data: { status }
    },
    {
      onSuccess: () => {
        notify(translate("customRoot.status.changed_success"), { type: 'success' })
        refresh()
        unselectAll("orders")
      },
      onError: (error) => {
        if (error.status == 400) {
          notify(error.body.error, { type: "error"})      
        } else {
          notify(translate("customRoot.errors.internal_server_error"), { type: "error"})  
        }
      }
    }
  )


  const isNotOperator = authStore.isPosManager() || authStore.isSuperAdmin() || authStore.isOrgManager()

  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  const handleConfirm = () => {
    updateMany()
    setOpen(false)
  }

  const handleStatusChange = (val) => {
    setStatus(val.target.value)
  }

  const openSnackbar = (message, severity) => {
    setOpenSnackbar(true)
    setSnackbar({ message: message, severity: severity })
  }

  const setContinueBtnDisabledInTime = async () => {
    setInterval(() => {
      setSendMessageBtnDisabled(false)
    }, 5000)
  }

  const sendSmsToCharge = async () => {
    try {
      await requests.sendSmsToCharge(order._id, order.fields.phone)
      openSnackbar(translate("message_sent_successfully"), "success")
    } catch (err) {
      openSnackbar(translate("requestResult.error"), "error")
    }
    setSendMessageBtnDisabled(true)
    await setContinueBtnDisabledInTime()
  }

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }

  useEffect(() => {
    if (orders && Object.keys(orders).length !== 0 && selectedIds.length) {
      setOrder(orders[selectedIds])
    }
  }, [orders, selectedIds])

  const selectedStatuses = orders ? orders.filter(order => selectedIds.includes(order.id)).map(order => order.status) : [];

  return (
    <Fragment>
      {!upload_orders_file && <Button label='customRoot.orders.modal.update_status_button' onClick={handleClick} />}
      {(order?.status === "הזמנה חדשה" || order?.status === "הודעה נשלחה בהצלחה" || order?.status === "כשלון בשליחת הודעה") && (
        <Button disabled={sendMessageBtnDisabled} label='customRoot.orders.modal.send_sms_to_charge' onClick={sendSmsToCharge} />
      )}
      <Dialog open={open} onClose={handleDialogClose} TransitionComponent={Transition}>
        <FormContainer>
          <DialogTitle id='form-dialog-title'>{translate("customRoot.orders.modal.update_status_button")}</DialogTitle>
          <DialogContent>
            <StyledSelect labelId='demo-simple-select-label' id='demo-simple-select' value={status} onChange={(val) => handleStatusChange(val)}>
              { isNotOperator && !selectedStatuses.includes('cancelled') && <MenuItem value='cancelled'>{translate("customRoot.orders.status.cancelled")}</MenuItem>}
              { isNotOperator && !selectedStatuses.includes('refund') && <MenuItem value='refund'>{translate("customRoot.orders.status.refund")}</MenuItem> }
              { !selectedStatuses.includes('external') && <MenuItem value='external'>{translate("customRoot.orders.status.external")}</MenuItem> }
              { !selectedStatuses.includes('done') && <MenuItem value='done'>{translate("customRoot.orders.status.done")}</MenuItem> }
            </StyledSelect>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleDialogClose} color='primary' label='customRoot.orders.modal.cancel' />
            <Button disabled={!status} onClick={handleConfirm} color='primary' label='customRoot.orders.modal.confirm' />
          </DialogActions>
        </FormContainer>
      </Dialog>
      <Snackbar open={isOpenSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Fragment>
  )
}

export default inject('authStore')(observer(UpdateStatusButton))
