import React, { useState, useEffect } from 'react';
import { Button, useRecordContext, Confirm, useTranslate } from 'react-admin';
import requests from "../../core/requests"
import { Add } from "@material-ui/icons"
import { List, ListItem, ListItemText } from "@material-ui/core"
import styled from "styled-components"

const ListItemTextCustom = styled(ListItemText)`
word-break: break-word;
word-wrap: break-word;
overflow-wrap: break-word;
text-align: right;
`

const DialogContent = (props) => {
  const { data } = props 
  const translate = useTranslate()
  return <>
      {data && (
        <List> 
          <ListItem>
            <ListItemTextCustom primary={translate('resources.organizations.singular')} secondary={<React.Fragment>{data.organizationId}</React.Fragment>}/>
          </ListItem>
          <ListItem>
            <ListItemTextCustom primary={translate("customRoot.organizations.list.system")} secondary={<React.Fragment>{data.systemId}</React.Fragment>}/>
          </ListItem>
          <ListItem>
            <ListItemTextCustom primary="חֶשְׁבּוֹן" secondary={<React.Fragment>{data.credentials.account}</React.Fragment>}/>
          </ListItem>
          <ListItem>
            <ListItemTextCustom primary="סוֹד" secondary={data.credentials.secret}/>
          </ListItem>   
        </List>
      )}
    </>
}

const GenerateButton = (props) => {
    const { organizationId } = props
    const record = useRecordContext();
    const [isLoading, setLoading] = useState(false);
    
    const t = useTranslate()

    const [openDialog, setOpenDialog] = useState(false);
    const [hasCredentials, setHasCredentials] = useState(false);
    const [credentials, setCredentials] = useState(null);

    const onGenerate = async () => {
        setLoading(true)
        const systemId = record.id                
        const data = await requests.generateCredsForExternalClient(organizationId, systemId)
        setLoading(false)
        setCredentials(data.data)
        setHasCredentials(true) 
    }

    const handleDialogOpen = async () => {
      setOpenDialog(true)  
        
      const systemId = record.id   
      const data = await requests.getCredsForExternalClient(organizationId, systemId)

      if (data.data.hasCredentials ) {
        setHasCredentials(true)   
        setCredentials(data.data.data)     
      }
    } 

    const handleDialogClose = () => {
        setOpenDialog(false)    
    } 

    return <>
      <Button onClick={handleDialogOpen} label={'יצירת חיבוריות'} disabled={isLoading}><Add/></Button>
      <Confirm
        isOpen={openDialog}
        loading={isLoading}
        title={t('third_party_creds.description')}
        confirm={hasCredentials ? t('third_party_creds.regenerate_btn_text') : t('third_party_creds.generate_btn_new') }
        content={<DialogContent data={credentials}/>}
        onConfirm={onGenerate}
        onClose={handleDialogClose}
      />
    </>
}

export default GenerateButton 