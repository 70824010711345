import {  makeObservable, observable, action, computed, toJS, reaction } from 'mobx'
import fetcher, { endpoints } from '../utils/fetcher'
import { delay } from '../utils/helpers'


class ProductStore {
  _items = []
  loading = false
  systemName = ''
  orgName = ''
  
  constructor() {
    makeObservable(this, {
      _items: observable,
      loading: observable,
      systemName: observable,
      orgName: observable,
      items: computed,
      getItems: action,
      setLoading: action,
      clear: action,
    })
  }
  
  get items() {
    return toJS(this._items) || []
  }

  clear = () => {
    this._items = {}
    this.systemName = ''
    this.orgName = ''
  }


  setLoading = (bool) => {
    this.loading = bool
  }
  
  getItems = async (hash, q = '') => {
    this.loading = true

    await delay(300);
      try {
        const res = await fetcher.get(endpoints.items(hash, q))
        this._items = res.data.data.items
        this.orgName = res.data.data.orgName
        this.systemName = res.data.data.systemName
        this.loading = false

        return true
      } catch (e) {
        this.loading = false
        return false
      }
  }
}

const productStore = new ProductStore()
export default productStore