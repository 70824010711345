import React from "react"
import { Filter, TextInput, SearchInput } from "react-admin"
import styled from "styled-components"
import { TextInputStyled } from "../common/InputFields"

const PostFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInputStyled
        source='name' 
        alwaysOn 
        resettable
      />
    </Filter>
  )
}

export default PostFilter
