import React, { useEffect, useRef, useState } from "react"
import {
  AutocompleteInput,
  Button,
  Datagrid,
  ExportButton,
  Filter,
  FunctionField,
  GET_ONE,
  List,
  ReferenceInput,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useListContext,
  useListController,
  useNotify,
  usePermissions,
  useRefresh,
  useResourceContext,
  useTranslate,
  SearchInput
} from "react-admin"
import { EditButton } from '../common/EditButton'
import { Publish } from "@material-ui/icons"
import styled from "styled-components"
import xlsx from "xlsx"
import ActionToolbar from "../common/ActionToolbar"
import useFetch from "use-http"
import { observer, inject } from 'mobx-react'
import FieldTitle from "../common/FieldTitle"
import InternalUserFlag from "../cashier/InternalUserFlag"
import { RoutesEnum } from "../../core/restProvider"
import CreateExternalCustomerButton from "./CreateExternalCustomerButton"
import { AutocompleteInputStyled, TextInputStyled } from "../common/InputFields"


const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .column-price,
  .column-is_active,
  .column-undefined {
    width: 100px;
  }
`

const exporter = (data) => {
  const newData = data.map((item) => {
    const { budgetNumber, systemId, lastName, firstName } = item;

    return ({
      "budget number": budgetNumber,
      "last name": lastName,
      "first name": firstName,
      "mobile number": item.mobile,
      "email": item.email,
    })
  });

  const wb = xlsx.utils.book_new()
  const s = xlsx.utils.json_to_sheet(newData)

  xlsx.utils.book_append_sheet(wb, s, "Sheet 1")
  xlsx.writeFile(wb, `customers.xlsx`)
}

const ItemsFilter = inject('authStore')(observer(({ authStore, ...props }) => {
  const { filterValues } = props.cprops
  const secondFilter = filterValues ? { filter: { org_id: filterValues.org_id } } : {}
  const isAdmin = authStore.isSuperAdmin()
  const isManager = authStore.isOrgManager()

  return (
    <Filter {...props}>
      {(isAdmin || isManager) && (
        <ReferenceInput
          label='resources.organizations.singular'
          reference='organizations'
          source='org_id'
          alwaysOn
          perPage={1000}
          sort={{ field: "name", order: "ASC" }}
        >
           <AutocompleteInputStyled 
             className="autocompeleteMekome"
             label='resources.organizations.singular' 
             optionText="name" 
             optionValue="id"
             matchSuggestion={(filter, choice) => choice.name.match(filter)}
            />
        </ReferenceInput>
      )}
      {(isAdmin || isManager) && (
        <ReferenceInput
          label='customRoot.organizations.list.system'
          reference='systems'
          source='system_id'
          alwaysOn
          {...secondFilter}
          perPage={1000}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInputStyled 
          className="autocompeleteMekome"
          label='customRoot.organizations.list.system'
          optionText="name" 
          optionValue="id"
          matchSuggestion={(filter, choice) => choice.name.match(filter)}
          />
        </ReferenceInput>
      )}
      <TextInputStyled source='name' alwaysOn resettable />
    </Filter>
  )
}))

const Input = styled.div`
  display: none;
`

const Actions =  inject('authStore')(observer(({ authStore, orgId, systemId, ...props }) => {
  const { maxResults } = props

  const restProvider = useDataProvider()
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()
  const input = useRef()
  const { currentSort, resource, filterValues, total } = useListContext()
  const { post } = useFetch(`/api/v1/organizations/${orgId}/systems/${systemId}/customers/import`, { cachePolicy: "no-cache" })
  const [customer, setCustomer] = useState({
    showAddExternalCustomer: "hidden"
  })

  const handleChange = (e) => {
    const form = new FormData()
    const file = e.target.files[0]
    form.append("file", file)

    post(form).then((r) => {
      if (r.data) {
        notify(translate("customRoot.upload.successMessage"), { type: "success" })
        refresh()
      }
      if (r.errors) {
        notify(r.errors, { type: "error" })
      }
    })
  }

  const handleClick = () => {
    input.current.click()
  }

  const getOrderSettings = async (organizationId, systemId) => {
    const { data } = await restProvider(GET_ONE, RoutesEnum.ORDER_SETTINGS, {
      organizationId,
      systemId
    })
    setCustomer(data.customer)
  }

  useEffect(() => {
    if (orgId && systemId) {
      getOrderSettings(orgId, systemId)
    }
  }, [orgId, systemId])

  return (
    <TopToolbar>
      { authStore.isCreateCustomerPermissions() && customer.showAddExternalCustomer !== "hidden" && (
        <CreateExternalCustomerButton
          navigationUrl='/customers/create'
          orgId={orgId}
          systemId={systemId}
          redirect='/customers'
        />
      )}

      {authStore.isExportCustomerPermissions() && (
        <Button onClick={handleClick}>
          <Publish />
          {translate("ra.action.import")}
        </Button>
      )}

      {authStore.isDownloadCustomerPermissions() && (
        <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={maxResults} />
      )}

      <Input>
        <input type='file' ref={input} accept={[".xlsx", ".csv", ".xls"]} onChange={handleChange} />
      </Input>
    </TopToolbar>
  )
}))

const CustomersList = ({ authStore, ...props }) => {
  const controllerProps = useListController(props)
  const resource = useResourceContext();

  const { filterValues } = controllerProps

  const { org_id, system_id } = filterValues
  
  const resultStorageByIds = authStore._ids
  const orgId = org_id || resultStorageByIds?.org_id
  const systemId = system_id || resultStorageByIds?.system_id

  return (
    <Container>
      <List
        {...props}
        filters={<ItemsFilter cprops={controllerProps} />}
        exporter={exporter}
        perPage={25}
        actions={<Actions {...props} systemId={systemId} orgId={orgId} />}
        empty={false}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source='firstName' label={<FieldTitle label='customRoot.customers.firstname'/>} sortable={false} />
          <TextField source='lastName' label={<FieldTitle label='customRoot.customers.lastname'/>} sortable={false} />
          <TextField source='budgetNumber' label={<FieldTitle label='customRoot.customers.budget_number'/>} sortable={false} />
          <TextField source='mobile' label={<FieldTitle label='customRoot.customers.phone'/>} sortable={false} />
          <TextField source='email' label={<FieldTitle label='customRoot.customers.email'/>} sortable={false} />
          <FunctionField render={(record) => <InternalUserFlag customerSocialId={record.socialId}/>} label={<FieldTitle label='customRoot.customers.internal_customer'/>} sortable={false} />
          {authStore.isEditCustomerPermissions() && (
            <ActionToolbar>
              <EditButton query={`?org_id=${org_id}&system_id=${system_id}`} onClick={() => authStore.setResource(resource, { org_id, system_id })} />
            </ActionToolbar>
          )}
        </Datagrid>
      </List>
    </Container>
  )
}

export default inject('authStore')(observer(CustomersList))
