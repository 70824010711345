import React from "react";
import styled, { keyframes } from "styled-components"
import { Button, Card, CardContent, Grid, TextField, Typography, Paper, Alert as MiuAlert } from "@mui/material"
import { CreditCard, DeleteForever } from "@mui/icons-material"

const StyledMuiAlert = styled(MiuAlert)`
  flex-direction: row-reverse;
`

const Option = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: start;
`

const Op = styled.span`
  opacity: 0.3;
  padding: 0 1em;
  font-size: 0.6em;
`

const LabelItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const DeleteForeverIcon = styled(DeleteForever)`
  padding: 0 10px;
  cursor: pointer;
`

export const Label = ({ item, onDelete, ...props }) => (
  <LabelItemWrapper>
    <Option dir='rtl' {...props}>
      <CreditCard/> {`${item.binNumber} **** **** ${item.lastFourDigits}`}
    </Option>
    <DeleteForeverIcon onClick={() => onDelete(item.id)}/>
  </LabelItemWrapper>
)

export const Alert = React.forwardRef(function Alert(props, ref) {
  return <StyledMuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export const ContainerError = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: calc(100% - 45px);
`;

export const ContentContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: calc(100% - 45px);
`;

export const ContentContainerPublic = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const ContentContainerPublic2 = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;


export const ZoozForm = styled.form`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  #zoozIframe {
    width: 100% !important;
    height: 170px !important;
    overflow: hidden;

    html {
      overflow: hidden;
    }
`;

export const StyledInput = styled(TextField)`
  .MuiFormHelperText-root.Mui-error {
    text-align: ${(p) => (p.isRtl ? 'right' : 'left')};
  }
`;

export const StyledCardInput = styled(TextField)`
  direction: ltr;
  .MuiFormHelperText-root.Mui-error {
    text-align: ${(p) => (p.isRtl ? 'right' : 'left')};
  }

  .MuiInputBase-input {
    -webkit-rtl-ordering: none;
  }
`;

export const StyledCvvInput = styled(TextField)`
  padding-left: 7px;
`;

export const StyledCard = styled(Card)`
  display: ${(p) => (p.loading ? 'none' : 'block')};
  padding: 5%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    margin-right: auto;
    margin-left: auto;
  }
`;

export const StyledCardPublic = styled(Card)`
  display: ${(p) => (p.loading ? 'none' : 'block')};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    margin-right: auto;
    margin-left: auto;
  }
`;

export const StyledCardContent = styled(CardContent)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const StyledTitle = styled(Typography)`
  @media (max-width: 900px) {
    font-size: 22px;
  }
  font-weight: bold;
  font-size: 35px;
  text-align: center;
`;

export const StyledContainerImage = styled.div`
  width: 200px;
  height: 200px;
  @media (max-width: 900px) {
    width: 100px;
    height: 100px;
  }
`;

export const StyledText = styled(Typography)`
  text-align: center;
`;

export const StyledTitleOrderInfo = styled(Typography)`
  font-weight: 600;
  text-align: center;
`;

export const StyledContainerDescription = styled.div`
	margin: 5%;
`;

export const StyledContainerTitle = styled.div`
	display: flex;
	justify-content: center;
`;

export const StyledButton = styled(Button)`
	-moz-box-shadow: 3px 4px 0px 0px #1564ad;
	-webkit-box-shadow: 3px 4px 0px 0px #1564ad;
	box-shadow: 3px 4px 0px 0px #1564ad;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0.05, #79bbff),
		color-stop(1, #378de5)
	);
	background: -moz-linear-gradient(top, #79bbff 5%, #378de5 100%);
	background: -webkit-linear-gradient(top, #79bbff 5%, #378de5 100%);
	background: -o-linear-gradient(top, #79bbff 5%, #378de5 100%);
	background: -ms-linear-gradient(top, #79bbff 5%, #378de5 100%);
	background: linear-gradient(to bottom, #79bbff 5%, #378de5 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#79bbff', endColorstr='#378de5',GradientType=0);
	background-color: #79bbff;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid #337bc4;
	display: inline-block;
	cursor: pointer;
	color: #ffffff;
	font-family: Arial;
	font-size: 17px;
	font-weight: bold;
	padding: 12px 44px;
	text-decoration: none;
	text-shadow: 0px 1px 0px #528ecc;
`;

export const StyledDialogButton = styled(Button)`
	border: 1px solid #337bc4;
	display: inline-block;
	cursor: pointer;
	color: #000000;
	font-family: Arial;
	font-size: 17px;
	font-weight: bold;
	padding: 12px 44px;
	text-decoration: none;
	text-shadow: 0px 1px 0px #528ecc;
`;

export const StyledCardOrderSummary = styled(Card)`
  @media (max-width: 900px) {
    width: 80%
  }
  background-color: whitesmoke;
  padding-right: 3%;
  padding-left: 3%;
  padding-top: 3%;
  margin-bottom: 5%;
  width: 50%
`

export const StyledContainerOrderInfo = styled.div`
    background-color: white;
    padding: 2%;
    margin-top: 4%;
    margin-bottom: 4%
`

export const StyledContainerItem = styled.div`
    display: flex;
    margin: 5%  
`

export const StyledLabel = styled(Typography)`
 flex: 1;
 justify-content: flex-start;
 font-weight: 100
`

export const StyledValue = styled(Typography)`
 flex: 1;
 justify-content: flex-end;
 text-align: left;
`

export const StyledContainerSum = styled.div`
  display: flex;
  margin: 2%;
  padding: 5%;
  background-color: whitesmoke;
`

export const StyledLabelSum = styled(Typography)`
  flex: 1;
  justify-content: flex-start;
  font-weight: bold
`

export const StyledSum = styled(Typography)`
 flex: 1;
 justify-content: flex-end;
 font-weight: bold;
 text-align: left;
`

export const StyledTOU = styled(Typography)`
 font-weight: 100;
 font-size: 12px;
 margin: 5%;
 text-align: center;
`

export const inputLabelProps = {
  shrink: true,
  margin: '2rem',
};

export const styleZoozForm = {
  base: {
    marginBottom: '1rem',
    borderRadius: '4px',
    fontSize: '16px',
    cardImage: {
      marginTop: '8px',
    },
    pan: {
      border: '1px solid #dedede',
      padding: '18.5px 14px',
      height: '57px',
      display: 'block',
      position: 'static',
      width: 'calc(100%)', // -45 (card icon)
    },
    expirationDate: {
      border: '1px solid #dedede',
      padding: '18.5px 14px',
      height: '57px',
      width: 'calc(50% - 6px)', // -45/2 -6px for margin
    },
    cvv: {
      border: '1px solid #dedede',
      padding: '18.5px 14px',
      height: '57px',
      width: 'calc(50% - 6px)', // -45/2 -6px for margin
      marginLeft: '12px',
    },
    field: {
      maxWidth: '250px',
    },
    secureFields: {
      width: 'calc(100% - 45px)',
    },
  },
};

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const anim = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -120px;
  }
`

const Spin = styled.div`
  display: inline-block;
  animation: ${rotate} 1.4s linear infinite;
  width: ${p => p.size ? p.size : '40px'};
  height: ${p => p.size ? p.size : '40px'};

  svg {
    width: ${p => p.size ? p.size : '40px'};
    height: ${p => p.size ? p.size : '40px'};
    circle {
      animation: ${anim} 1.4s ease-in-out infinite;
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0px;
      stroke: currentColor;
    }
  }
`

const Spinner = ({ size }) => (
  <Spin size={size}>
    <svg viewBox='22 22 44 44'>
      <circle cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" />
    </svg>
  </Spin>
)

const LoadingWrap = styled.div`
  display:flex;
  flex:1;
  width:100%;
  height:100%;
  justify-content:center;
  align-items:center;
`

export const Loading = ({ wrapPosition, size }) => <LoadingWrap wrapPosition={wrapPosition}><Spinner size={size} /></LoadingWrap>

const StyledBlockquote = styled(Paper)`
  padding: 1rem;
  background: ${p => p.error && 'rgba(193,54,54,0.3)'};
`

const Blockquote = ({ ...props }) => <StyledBlockquote {...props} />

const StyledForm = styled(Grid)`
  margin: 1rem 0 2rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .button {
    margin: 0.5rem 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ErrorMessage = styled.span`
    color: red;
    display: flex;
    margin-top: 5px;
  `

export const StyledCvvAndExpiryContent = styled.div`
  width: calc(100% - 45px); 
  display: flex; 
  margin: 0px; 
  justify-content: center;

  div {
    margin-top: 0px;
  }
`

export const StyledCvvAndExpiryContentPublic = styled.div`
  display: flex; 
  margin: 0px; 
  justify-content: center;

  div {
    margin-top: 0px;
  }
`

const StyledItem = styled(props => <Grid item {...props} />)`
  display: flex;
  flex-direction: column;
`

const Error = styled(Blockquote)`
  margin-top: 1rem;
  color: ${p => p.theme.color.red};
`


export const Form = ({ sm=12, md=7, children, error='', ...props }) => (
  <StyledForm {...props} >
    <StyledItem item sm={sm} md={md}>{children}</StyledItem>
    {error && <Error error={!!error}>{error}</Error>}
  </StyledForm>
)
