import React, { useState, useEffect } from "react"
import { Form, useTranslate, useNotify, required, useDataProvider, CREATE, TextInput, minLength } from "react-admin"
import styled from "styled-components"
import { Button, Card, Avatar, SxProps, CardContent, CircularProgress } from "@mui/material"
import requests from "../../core/requests"
import LockIcon from '@mui/icons-material/LockReset';
import { useParams, useNavigate } from 'react-router-dom';

const PREFIX = 'RaLogin';
const LoginClasses = {
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`,
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
};

const Description = styled.span`
  display: flex;
  width: 300px;
  padding: 10px;
  text-align: center;
  justify-content: center;
`

const StyledForm = styled(Form, {
        name: PREFIX,
        overridesResolver: (props, styles) => styles.root,
    })(({ theme }) => ({
        [`& .${LoginClasses.content}`]: {
            width: 300,
        },
       /* [`& .${LoginClasses.button}`]: {
            marginTop: theme.spacing(2),
        },
        [`& .${LoginClasses.icon}`]: {
            margin: theme.spacing(0.3),
        },*/
    }
));

const Root = styled('div', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

    [`& .${LoginClasses.card}`]: {
        minWidth: 300,
        marginTop: '6em',
    },
    [`& .${LoginClasses.avatar}`]: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    /*[`& .${LoginClasses.icon}`]: {
        backgroundColor: theme.palette.secondary[500],
    },*/
}));


const PasswordReset = (props) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('')
  const { token } = useParams();
  const translate = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate()

  useEffect(() => {
    requests.validatePasswordToken(token).then(response => {
      if (!response?.ok) { 
        navigate('/')
      } 
    })
  }, [])

  const submit = async (values) => {
    setLoading(true);

    const { email, password } = values

    try {
        const response = await requests.resetPassword(token, { email, password })
        setLoading(false);

        if (response?.ok) {
          notify('reset_password.request_was_sent', { type: 'success' });
          navigate('/login')
        } else {
          notify('errors.bad_request', { type: 'error' });
        }
        
    } catch(e) {
        setLoading(false);
        notify('errors.bad_request', { type: 'error' });
    }
  };

  const confirmPwd = () => (value, allValues, props) => 
    value == password && password != ''
        ? undefined
        : { message: 'errors.validation.confirm_password' };

  return (
    <Root>
        <Card className={LoginClasses.card}>
            <div className={LoginClasses.avatar}>
                <Avatar className={LoginClasses.icon}>
                    <LockIcon />
                </Avatar>
            </div>
            <Description>{translate('reset_password.new_password_description')}</Description>
            <StyledForm
                onSubmit={submit}
                mode="onChange"
                noValidate
            >
                <CardContent className={LoginClasses.content}>
                    <TextInput
                        autoFocus
                        source="email"
                        label={translate('customRoot.customers.email')}
                        validate={required()}
                        fullWidth
                    />
                    <TextInput
                        autoFocus
                        type="password"
                        source="password"
                        label={translate('ra.auth.password')}
                        onChange={(e) => setPassword(e.target.value)}
                        validate={[required(), minLength(7)]}
                        fullWidth
                    />
                    <TextInput
                        autoFocus
                        type="password"
                        source="confirm_password"
                        label={translate('reset_password.confirm_password')}
                        validate={[required(), confirmPwd()]}
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={loading}
                        fullWidth
                        className={LoginClasses.button}
                    >
                        {loading ? (
                            <CircularProgress
                                className={LoginClasses.icon}
                                size={19}
                                thickness={3}
                            />
                        ) : (
                            translate('reset_password.send')
                        )}
                    </Button>
                </CardContent>
            </StyledForm>
        </Card>
    </Root>
  )
}

export default PasswordReset
