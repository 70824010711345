// in src/MyLayout.js
import React from "react"
import { Logout, AppBar, Layout, UserMenu } from "react-admin"
import { observer, inject } from 'mobx-react'
import Menu from "./Menu"
import { Typography } from "@mui/material"
import settings from "../tools/settings"
import styled from "styled-components"

const DEV_APP_ENV = settings.appEnv === "development"

const TitleBox = styled(Typography)`
  display: flex;
  color: rgba(0, 0, 0, 0.54);
  justify-content: center;
  overflow: hidden;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  white-space: nowrap;
  letter-spacing: 0.00938em;
`

const MyUserMenu =  inject('authStore')(observer(({ authStore, ...props }) => {
  const user = authStore._user

  return (
    <UserMenu {...props}>
      <TitleBox>{user ? user.username : ''}</TitleBox>
      <Logout/>
    </UserMenu>
  )
}))

const MyAppBar = (props) => <AppBar {...props} style={{ backgroundColor: DEV_APP_ENV ? "#4a0001" : '#2979FF', color: 'white' }} userMenu={<MyUserMenu />} />

const MyLayout = (props) => <Layout {...props} menu={Menu} appBar={MyAppBar} />

export default MyLayout
