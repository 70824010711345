export default {
  external: "הזמנה מרחוק",
  internal: "הזמנה מקופה",
  share_link: "שתף קישור",
  errors: {
    bad_request: "אירעה שגיאה לא ידועה",
    validation: {
      confirm_password: "מצטערים הסיסמה שגויה"
    },
    import_items: {
      invalid_value: `הערך '%{value}' בשדה %{field} שבשורה %{line} אינו חוקי`,
      missed_field: `השדה %{field} בשורה %{line} חסר`,
      missed_fields: `שדות חסרים %{fields}`,
      empty_row: `הקובץ מכיל שורות ריקות ולכן לא ניתן לטעון אותו, יש למחוק את השורות הריקות ולנסות שוב`,
    },
    customers: {
      social_id: {
        exists: "מספר תעודת זהות כבר קיים במערכת",
        invalid: "מספר תעודת הזהות שגוי"
      }
    }
  },
  reset_password: {
    confirm_password: "אישור סיסמה",
    send: "להגיש",
    description: "יש להכניס את כתובת המייל של חשבון זה ואנו נשלח לינק לכתובת המייל לאיפוס הסיסמה",
    new_password_description: "הכנסת סיסמה חדשה",
    send_successful: "איפוס הסיסמה נשלח בהצלחה לכתובת",
    request_was_sent: "הבקשה נשלחה בהצלחה",
    forgot_description: "שכחת ססמה"
  },
  confirm_dialog: "בקשת אישור",
  third_party_creds: {
    description: "יצירת הרשאות לשרותי צד שלישי",
    regenerate_btn_text: "ייצר מחדש",
    generate_btn_new: "ייצר חדש"
  },
  credit_card: {
    cc_is_invalid: "מספר כרטיס האשראי אינו חוקי.",
    exp_date_is_invalid: 'תאריך תפוגה שגוי',
    cvv_is_invalid: 'CVV שגוי',
    name_is_invalid: 'יש למלא את שדה השם'
  },
  resources: {
    users: {
      name: "משתמשים"
    },
    organizations: {
      name: "ארגונים",
      singular: "ארגון"
    },
    orders: {
      name: "הזמנות"
    },
    items: {
      name: "פריטים"
    },
    systems: {
      name: "מערכת"
    },
    order_settings: {
      name: "הזמנת תצורה"
    },
    customers: {
      name: "לקוחות"
    }
  },
  ra: {
    passwordConfirmDialog: {
      open_dialog_btn: "הצגת נתוני אשראי",
      description: "יש להזין סיסמה על מנת לאפשר את הפעולה",
      confirm_action_btn: "אישור"
    },
    configurable: {
      customize: ""
    },
    report_pos_system_admin: {
      columns: {
        created_at: "תאריך ושעת ההזמנה",
        org_name: "שם הארגון",
        system_name: "שם המערכת",
        order_number: "מספר הזמנה",
        customer_name: "שם",
        phone: "טלפון",
        email: "דוא״ל",
        budget_number: "מספר תקציב /חשבון",
        slika_id: "מספר אירגון בפיננסי",
        entity_id: "entity id",
        address: "כתובת",
        order_type: "סוג הזמנה",
        status: "סטטוס",
        total_sum: "סה״כ לחיוב",
        order_remarks: "הערות הזמנה",
        category: "קטגוריה",
        item: "פריט",
        price: "מחיר",
        quantity: "כמות",
        item_remarks: "הערות פריט"
      },
      system_admin: "מנהל מערכת",
      bookkeeping: "הנהלת חשבונות"
    },
    credit_card_details: {
      app_id: "App ID",
      private_key: "Private API Key",
      public_key: "Public API Key",
      business_number: "מס' בית עסק",
      institution_details: "פרטי מחיב אשראי",
      payment_on_create_order: "בצע תשלום בעת יצירת הזמנה",
      business_address: "כתובת בית העסק",
      business_phone: "טלפון בית העסק"
    },
    action: {
      add_filter: "הוסף סינון",
      add: "הוסף",
      back: "חזור",
      bulk_actions: "פריט אחד נבחר |||| %{smart_count} פריטים נבחרו",
      cancel: "ביטול",
      clear_input_value: "נקה ערך",
      clone: "שכפל",
      confirm: "אשר",
      create: "צור",
      import: "יבוּא",
      edit: "ערוך",
      delete: "מחק",
      list: "רשימה",
      refresh: "רענן",
      remove_filter: "הסר סינון",
      remove: "מחק",
      save: "שמור",
      search: "חפש",
      show: "הצג",
      sort: "מיין",
      undo: "בטל",
      export: "יצא נתונים",
      expand: "הרחב",
      close: "סגור",
      unselect: "unselect",
      export_admin_system: "ייצוא למנהל מערכת",
      export_finance_system: "ייצוא להנהלת חשבונות",
      add_external_user: "הוסף משתמש חיצוני",
    },
    boolean: {
      true: "כן",
      false: "לא"
    },
    page: {
      create: "צור %{name}",
      dashboard: "לוח המחוונים",
      edit: "%{name} #%{id}",
      error: "שגיאה התרחשה",
      list: "רשימת %{name}",
      loading: "טוען",
      not_found: "לא קיים",
      show: "%{name} #%{id}",
      empty: "empty",
      invite: "?Do you want to add one"
    },
    input: {
      password: {
        toggle_hidden: ''
      },
      file: {
        upload_several: "גרור מספר קבצים להעלאה או לחץ לבחור אחד.",
        upload_single: "גרור קובץ להעלאה או לחץ לבחור אחד."
      },
      image: {
        upload_several: "גרור מספר תמונות להעלאה או לחץ לבחור אחת.",
        upload_single: "גרור תמונה להעלאה או לחץ לבחור אחת."
      },
      references: {
        all_missing: "ישויות הקשר לא נמצאו.",
        many_missing: "לפחות אחת מישויות הקשר אינה זמינה יותר.",
        single_missing: "ישות הקשר אינה זמינה יותר."
      }
    },
    message: {
      username_exists: "שם משתמש כבר קיים",
      about: "אודות",
      are_you_sure: "האם את/ה בטוח/ה ?",
      bulk_delete_content: "האם אתה בטוח שברצונך למחוק %{name}? |||| האם אתה בטוח שברצונך למחוק %{smart_count} פריטים?",
      bulk_delete_title: "מחק %{name} |||| מחק %{smart_count} %{name} פריטים",
      delete_content: "האם אתה בטוח שברצונך למחוק את הפריט הזה?",
      delete_title: "מחק %{name} #%{id}",
      details: "פרטים",
      error: "קרתה שגיאת תקשורת והבקשה שלך לא הסתיימה.",
      invalid_form: "הטופס אינו תקין. אנא תקן את שגיאותיך",
      loading: "העמוד טוען, אנא המתן",
      no: "לא",
      yes: "כן",
      not_found: "הכנסת כתובת שגויה או לחצת על קישור לא תקין",
      password_invalid: "הסיסמה לא חוקית"
    },
    navigation: {
      no_results: "לא נמצאו תוצאות",
      no_more_results: "עמוד %{page} מחוץ לתחום. אנא נסה את העמוד הקודם.",
      page_out_of_boundaries: "עמוד %{page} מחוץ לתחום",
      page_out_from_end: "לא ניתן להמשיך מעבר לעמוד האחרון",
      page_out_from_begin: "לא ניתן לגשת לפני העמוד הראשון",
      page_range_info: "%{offsetBegin}-%{offsetEnd} מתוך %{total}",
      page_rows_per_page: "מספר שורות לעמוד:",
      next: "הבא",
      prev: "הקודם",
      skip_nav: "skip_nav"
    },
    auth: {
      user_menu: "פרופיל",
      username: "שם משתמש",
      password: "סיסמא",
      confirm_password: "confirm password",
      auth_check_error: "התחבר לחשבון כדי להמשיך",
      sign_in: "כניסה",
      sign_in_error: "הכניסה נכשלה, אנא נסו שנית",
      logout: "התנתקות"
    },
    notification: {
      updated: "פריט עודכן בהצלחה",
      created: "פריט נוצר בהצלחה",
      deleted: "פריט נמחק בהצלחה",
      bad_item: "פריט לא תקין",
      item_doesnt_exist: "פריט לא קיים",
      http_error: "שגיאה בהתקשרות מול השרת",
      data_provider_error: "שגיאת dataProvider. בדוק את הקונסול לפרטים נוספים.",
      canceled: "הפעולה בוטלה",
      logged_out: "הנך מנותק, אנא התחבר מחדש."
    },
    validation: {
      required: "חובה",
      minLength: "חייב להכיל לפחות %{min} תווים",
      maxLength: "יכול להכיל לכל היותר %{max} תווים",
      minValue: "חייב להיות לפחות %{min}",
      maxValue: "חייב להיות %{max} או פחות",
      number: "חייב להיות מספר",
      email: 'כתובת דוא"ל לא תקנית',
      oneOf: "חייב להיות מאחת האפשרויות הבאות: %{options}",
      regex: "חייב להתאים לתבנית ספציפית (ביטוי רגולרי): %{pattern}",
      invalid_date_format: "תַּבְנִית תַּאֲרִיךְ לֹא חֻקֵּיתָ",
      file_format_is_wrong: "תבנית הקובץ שגויה"

    }
  },
  customRoot: {
    errors: {
      internal_server_error: "שגיאת שרת פנימית",
    },
    status: {
      changed_success: "סטטוס ההזמנה עודכן בהצלחה",
    },
    financialReport: {
      title: "ייצוא להנהלת חשבונות"
    },
    creditReport: {
      title: 'דו"ח אשראי'
    },
    myMenu: {
      orders: "הזמנות",
      users: "משתמשים",
      uploadItems: "העלאת קבצי תפריט",
      organizations: "ארגונים",
      products: "מוצרים",
      systems: "מערכות",
      order_settings: "הזמנת תצורה",
      customers: "לקוחות",
      cashier: "קופאית",
      seller: "משתמש",
    },
    users: {
      list: {
        id: "מזהה",
        firstName: "שם פרטי",
        lastName: "שם משפחה",
        address: "כתובת",
        email: "דואר אלקטרוני",
        phone: "טלפון",
        budgetNumber: "מספר תקציב",
      },
      create: {
        first_name: "שם פרטי",
        last_name: "שם משפחה"
      }
    },
    customers: {
      required: "שדה חובה",
      optional: "שדה רשות",
      hidden: "לא להצגה",
      firstname: "שם פרטי",
      lastname: "שם משפחה",
      email: "דואר אלקטרוני",
      phone: "טלפון",
      address: "כתובת",
      number: "מספר",
      budget_number: "מספר תקציב",
      slika_id: "זיהוי כזה",
      entity_id: "entity id",
      social_id: "מספר ת\"ז",
      show_add_external_customer: "תשלום לקוח שאינו ברשימה",
      internal_customer: "לקוח פנימי",
      external_customer: "לקוח חיצוני"
    },
    products: {
      status: {
        available: 'זמין',
        unavailable: 'אינו זמין',
        hidden: 'לא לתצוגה'
      }
    },
    roles: {
      "super admin": "ניהול מערכת",
      "organization manager": "ניהול אירגון",
      "pos manager": "ניהול קופה",
      operator: "הפעלת קופה"
    },
    items: {
      name: "שם",
      price: "מחיר",
      quantity: "כמות",
      category: "קטגוריה",
      image: "תמונה",
      barcode: "ברקוד",
      description: "תיאור",
      discount: "הנחה",
      weight: "מִשׁקָל",
      is_active: "זמין",
      not_active: "לא זמין",
      select: "בחר תמונה ממאגר התמונות שלך",
      clear: "למחוק תמונה",
      or: "אוֹ",
      sort_order: "סדר המיון",
      sort_order_helper: "פריט עם המספר 1 יוצג ראשון ברשימה",
      status: "סטטוס",
      order: "סדר",
    },
    order_settings: {
      id: "זיהוי",
      name: "תווית",
      placeholder: "מציין מיקום",
      fieldType: "סוג קלט",
      isRequired: "נדרש",
      id_helper: "חייב להיות ייחודי ואנגלי",
      sortNumber: "מספר מיון"
    },
    orders: {
      list: {
        order_number: "מספר הזמנה",
        budget_number: "מספר תקציב",
        social_id: "מספר ת\"ז",
        date: "תאריך",
        order_date: "תאריך ההזמנה",
        org_name: "שם הארגון",
        system_name: "שם המערכת",
        items: "הפריטים בהזמנה",
        customer_name: "שם הלקוח",
        customer_address: "כתובת הלקוח",
        customer_email: 'דוא"ל הלקוח',
        phone: "טלפון",
        order_type: "סוג ההזמנה",
        status: "מצב ההזמנה",
        search: "חיפוש",
        get_orders_button: "הבא הזמנות",
        details: "פרטים",
        createdAt: "נוצר ב"
      },
      status: {
        received: "התקבלה",
        done_paid: 'שולם בכרטיס אשראי',
        done: 'חויב בתקציב - הנה"ח',
        refund: 'זיכוי - הנה"ח',
        cancelled: 'ביטול - הנה"ח',
        external: 'תשלום חיצוני'
      },

      items: {
        item_name: "שם המוצר",
        price: "מחיר",
        quantity: "כמות",
        category: "קטגוריה",
        remarks: "הערות",
        order_remarks: "הערות:",
        total: 'סה"כ',
        sort_order: "סדר המיון",
        sort_order_helper: "מספר גבוה יותר מראה ראשון",
        barcode: "ברקוד"
      },
      modal: {
        print: "הדפס",
        update_status_button: "עדכן סטטוס להזמנה",
        send_sms_to_charge: "שלח הודעה לביצוע תשלום",
        confirm: "אישור",
        cancel: "ביטול"
      }
    },
    upload: {
      org: "בחר ארגון",
      sys: "בחר מערכת",
      no_items: "נראה שאין כאן פריטים",
      upload_label: "בחר קובץ",
      loading: "הקובץ עולה, אנא המתן",
      successMessage: "מצב ההזמנה עודכן בהצלחה",
      failureMessage: "טעינת הקובץ נכשלה",
      noFile: "לא נבחר קובץ",
      fileInvalid: "קובץ לא תקין",
      explainText: "הקפד על מבנה קובץ נכון",
      explainStructure: "name|category|description|image|price|discount|weight|quantity|barcode|is_active",
      select_logo: "בחר לוגו",
      templateText: "הורד קובץ תבנית"
    },
    organizations: {
      list: {
        org_name: "שם הארגון",
        display_name: "שם תצוגה",
        slika_org_id: "מספר מזהה אירגון בסליקה",
        created_at: "תאריך יצירה",
        systems: "מערכות",
        system: "מערכת",
        active: "פעיל",
        org_id: "מזהה ארגון",
        sys_id: "מזהה מערכת",
        business_number: "מספר עסק",
        public_key: "מפתח ציבורי",
        private_key: "מפתח פרטי",
        app_id: "מזהה אפליקציה",
        logo_link: "לוגו קישור",
        title_text: "כותרת טקסט",
        body_text: "טקסט גוף",
        business_address: "כתובת עסקית",
        business_phone: "טלפון עסקי",
        order_type: "סוג הזמנה"
      }
    },
    systems: {
      list: {
        active: "לאפשר תשלום באמצעות כרטיס אשראי",
        externalId: "מספר מזהה קופה במערכת סליקה",
      },
      settings: {
        enableSendSmsWithOrderDetail: "שלח סמס עם קישור לפרטי הקניה"
      }
    },
    cashier: {
      confirm_save_cc_info: "האם לשמור פרטי כרטיס אשראי?",
      all_categories: "כל",
      search_placeholder: "לחפש...",
      checkout: "שליחת הזמנה",
      total: "סיכום",
      sum: "סכום",
      send_with_sms: "שליחת סמס לחיוב",
      measure_unit: "יחידות",
      measure_kg: "קג",
      add_to_cart: "הוספה",
      how_many: "כמות",
      weight: 'מִשׁקָל',
      cancel: "ביטול",
      item_remarks: "הערה",
      item_remarks_placeholder: "כל מידע נוסף",
      remove: "הסר",
      cart_is_empty: "סל הקניות ריק",
      checkout_title: "הכנסת פרטים לתשלום ויצירת קשר",
      back: "חזרה",
      user_search_placeholder: "לחפש לקוחות...",
      place_order: "שליחת הזמנה",
      confirm_title: "תודה!",
      confirm_desc: "הזמנתך נשלחה בהצלחה",
      go_to_home: "חזרה לעמוד הראשי",
      charging_success: "התשלום עבר בהצלחה!",
      popup_title: "חובה למלא שדה זה.",
      popup_text: "יש למלא את שדות החובה. שדות אלו מסומנים באדום.",
      popup_complete: "אישור"
    }
  },
  requestResult: {
    error: "ארעה שגיאה בתהליך הבקשה",
    success: "עבר בהצלחה"
  },
  payments: {
    payment_details: "פרטי תשלום",
    order_number: "מספר הזמנה",
    customer_name: "שם לקוח",
    product_name: "שם המוצר",
    price: "מחיר",
    quantity: "כמות",
    sum: "סכום",
    credit_card_title: "בבקשה להזין את פרטי כרטיס האשראי",
    cardholder_fullname_label: "שם מלא של בעל הכרטיס",
    credit_card_number_placeholder: "מספר כרטיס אשראי",
    save_and_charge: "ביצוע תשלום",
    payment_order: "מעבר לדף תשלום",
    tou_sentence: "ביטול עסקה בהתאם לתקנות הגנת הצרכן (ביטול עסקה), התשע״א-2010 וחוק הגנת הצרכן, התשמ״א-1981"
  },
  title: "כותרת",
  content: "תוכן",
  romove_logo: "הסרת לוגו",
  select_logo: "בחר לוגו",
  change_logo: "החלף לוגו",
  message_sent_successfully: "הודעה נשלחה בהצלחה",
  greeting_title: "ברוכים הבאים למערכת - מקומי קופות רושמות",
  greeting_subtitle: "מקומי - קופות רושמות",
  publicOrder: {
    goods_title: "מוצרים",
    add_to_cart: "הוספה",
    cancel: "ביטול",
    measure_unit: "יחידות",
    measure_kg: "קג",
    how_many: "כמות:",
    cart_is_empty: "סל הקניות ריק",
    cart: "סל קניות",
    items_count: "פריטים",
    checkout: "שליחת הזמנה",
    checkout_title: "הכנסת פרטים לתשלום ויצירת קשר",
    place_order: "שליחת הזמנה",
    payment_order: "מעבר לדף תשלום",
    name_label: "שם",
    phone_label: "טלפון",
    number_label: "מספר תקציב",
    comment_label: "הערות",
    optional: "אופציונאלי",
    required: "נדרש",
    name_placeholder: "שם מלא",
    phone_placeholder: "מספר טלפון",
    number_placeholder: "מספר תקציב",
    comment_placeholder: "הערות נוספות",
    wrong_input: "הקלט אינו תקין",
    max_length: "שדה זה צריך להיות לפחות {{maxLength}} ספרות",
    total: "סיכום",
    back: "חזרה",
    category_filter: "קטגוריות",
    confirm_title: "תודה!",
    confirm_desc: "הזמנתך נשלחה בהצלחה",
    go_to_home: "חזרה לעמוד ראשי",
    no_items: "נראה שאין כאן פריטים",
    item_remarks: "הערה",
    item_remarks_placeholder: "כל מידע נוסף",
    last_orders: "הזמנות אחרונות",
    home: "מסך ראשי",
    privacy: "מדיניות הפרטיות",
    remove: "הסר",
    accessibility: "נגישות",
    acc_desc: "היישום שלנו תואם את הנחיות הנגישות לתוכן אינטרנטי W3C 2.0",
    menu: "תפריט אפליקציה",
    search: "חיפוש",
    categories: "קטגוריות",
    close: "סגירה",
    systems: "מקומות",
    order_type: "סוג הזמנה",
    order_type_pickup: "איסוף",
    order_type_delivery: "משלוח",
    customer_email_label: "כתובת מייל",
    customer_email_placeholder: "כדי לקבל אישור הזמנה במייל, יש לציין כתובת",
    customer_address_label: "כתובת למשלוח",
    customer_address_placeholder: "במידה ומשלוח אפשרי, שנוכל להגיע אליכם בקלות",
    search_placeholder: "לחפש...",
    all_categories: "כל",
    settings: "הגדרות",
    personal: "פרטים אישיים",
    saved_locally: "נשמר באופן מקומי",
    location: "בחר את המיקום שלך",
    current_location: "המיקום הנוכחי הוא:",
    change_location: "שינוי מיקום",
    hi: "היי,",
    before_we_start: "לפני שאנחנו מתחילים",
    username_label: "שם משתמש",
    password_label: "סיסמה",
    username_placeholder: "שם משתמש",
    password_placeholder: "סיסמה",
    user_search_placeholder: "לחפש לקוחות...",
    cardholder_fullname_label: "שם מלא של בעל הכרטיס",
    cardholder_fullname_placeholder: "שם כפי שמופיע על הכרטיס",
    credit_card_number_placeholder: "מספר כרטיס אשראי",
    credit_card_cvv_placeholder: "cvv",
    continue: "המשך",
    credit_card_title: "בבקשה להזין את פרטי כרטיס האשראי",
    order: "הזמנה",
    sum: "סכום",
    order_not_found: "ההזמנה לא נמצאה",
    error_id_url: "שגיאה בניתוב",
    charging_success: "התשלום עבר בהצלחה!",
    credit_card_invalid: "פרטי כרטיס האשראי שהוזנו אינם תקינים.",
    diners_not_available: "כרטיס Diners Club אינו זמין.",
    amex_not_available: "כרטיס Amex אינו זמין.",
    invalid_expiration_date: "תוקף הכרטיס שהכנסת אינו תקין",
    invalid_credit_card_number: "מספר הכרטיס אינו תקין",
    invalid_cvv: "מספר הביטחון שהכנסת אינו תקין",
    requestResult: {
      error: "ארעה שגיאה בתהליך הבקשה",
      success: "עבר בהצלחה"
    },
    save_and_charge: "ביצוע תשלום",
    pay_with_your_saved_credit_card: "שלם עם אמצעי התשלום הקודם",
    use_another_credit_card: "שימוש בכרטיס אשראי אחר",
    last_4_digits: "**** **** ****",
    payment_details: "פרטי תשלום",
    description: "תאור",
    invoice_id: "מספר חשבונית",
    name: "שם לקוח",
    order_number: "מספר הזמנה",
    tou_sentence: "ביטול עסקה בהתאם לתקנות הגנת הצרכן (ביטול עסקה), התשע״א-2010 וחוק הגנת הצרכן, התשמ״א-1981",
    product_name:"שם המוצר",
    price: "מחיר",
    quantity:"כמות",
    credit_card: {
      cc_is_invalid: "מספר כרטיס האשראי אינו חוקי.",
      exp_date_is_invalid: "תאריך תפוגה שגוי",
      cvv_is_invalid: "CVV שגוי"
    },
    payments: {
      payment_details: "פרטי תשלום",
      order_number: "מספר הזמנה",
      customer_name: "שם לקוח",
      product_name: "שם המוצר",
      price: "מחיר",
      quantity: "כמות",
      sum: "סכום",
      credit_card_title: "בבקשה להזין את פרטי כרטיס האשראי",
      cardholder_fullname_label: "שם מלא של בעל הכרטיס",
      credit_card_number_placeholder: "מספר כרטיס אשראי",
      save_and_charge: "ביצוע תשלום",
      payment_order: "מעבר לדף תשלום",
      tou_sentence: "ביטול עסקה בהתאם לתקנות הגנת הצרכן (ביטול עסקה), התשע״א-2010 וחוק הגנת הצרכן, התשמ״א-1981"
    }   
  }
}
