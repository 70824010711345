import {  makeObservable, observable, action, computed, toJS, reaction } from 'mobx'
import fetcher, { endpoints } from '../utils/fetcher'
import storage from '../utils/storage'
import { delay } from '../utils/helpers'

class CartStore {
  isEmpty = false
  loading = false
  
  sum = 0
  _items = null
  _customer = {}
  
  constructor() {
    makeObservable(this, {
      isEmpty: observable,
      sum: observable,
      loading: observable,
      _items: observable,
      items: computed,
      addItem: action,
      removeItem: action,
      calculateSum: action,
      getCustomerDetail: action,
      changeCustomerDetail: action,
      addOrder: action,
      clear: action,
    })

    const data = storage.get('order_')
    if (data !== null) {
      this._items = data
    } else {
      this._items = {}
    }

    this.calculateSum()
  }
  
  get items() {
    return toJS(this._items) || {}
  }

  get customerDetails() {
    return toJS(this._customer) || {}
  }

  setLoading = (bool) => {
    this.loading = bool
  }

  clear = () => {
    this._items = {}
    this._customer = {}
    this.sum = 0

    storage.remove('order_')
    this.calculateSum()
  }

  calculateSum = () => {
    const items = toJS(this._items)
    const sum = Object.values(items).reduce((acc, item) => acc + item.quantity * item.price, 0)
    this.sum = sum

    this.isEmpty = Object.keys(items).length == 0
  }

  getCustomerDetail = (key) => {
    const customer = toJS(this._customer)

    if (!(key in customer)) {
      return ''
    }

    return customer[key];
  }

  changeCustomerDetail = (key, value) => {
    const customer = toJS(this._customer)
    
    customer[key] = value
    this._customer = customer
  }

  removeItem = (id) => {
    const items = toJS(this._items)

    if (id in items) {
      delete items[id]
      this._items = items    
    }

    storage.set('order_', items)

    this.calculateSum();
  }

  addItem = (item) => {
    const items = toJS(this._items)

    if (item.id in items) {
      const currentItem = items[item.id];
      currentItem.quantity += item.quantity
      items[item.id] = currentItem
    } else {
      items[item.id] = item
    }

    this._items = items
    this.calculateSum();

    storage.set('order_', items)
  }

  createOrder = async (hash) => {
    this.loading = true

    await delay(300)

    const customer = toJS(this._customer)
    const items = toJS(this._items)

    const data = { 
      items: Object.values(items), 
      customer, 
      sum: this.sum 
    }

    try {
      const res = await fetcher.post(endpoints.addOrderFs(hash), data)
      const { id, status, hashUrl } = res.data.data
      
      this.loading = false
      return { id, status, hashUrl }
      
    } catch (e) {
      this.loading = false
      return null
    }
  }


  addOrder = async (hash) => {
    this.loading = true

    await delay(300)

    const customer = toJS(this._customer)
    const items = toJS(this._items)

    const data = { 
      items: Object.values(items), 
      customer, 
      sum: this.sum 
    }

    try {
      const res = await fetcher.post(endpoints.addOrder(hash), data)
      const {id, status} = res.data.data
     
      this.loading = false
      return id
     
    } catch (e) {
      this.loading = false
      return null
    }
  }
}

const cartStore = new CartStore()
export default cartStore