import React from 'react'
import styled from 'styled-components'
import { Input as MUInput, TextField } from '@mui/material'

import Text from './Text'
import theme from '../utils/theme'

const InputContainer = styled.div`
  margin-bottom: 1rem;
  position: relative;
`

const StyledInput = styled(TextField)`
  border-radius: 8px;
  background: transparent;
  ${p => p.hasIcon && 'padding-left: 1.5rem'};
  box-shadow:
  2px 2px 4px 1px rgba(193,193,249,0.17),
  -2px -2px 4px 1px ${theme.color.lighter};
  .MuiFormHelperText-root.Mui-error {
     text-align:  ${p => p.isRtl ? 'right' : 'left'};
   }
  .MuiInputAdornment-positionEnd{
    margin:0 8px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  &:before, &:after {
    display: none;
  }
  input, .MuiInputBase-multiline {
    padding: 0.7rem;
  }
  .MuiFormHelperText-root {
    text-align: right;
  }
`

const Label = styled(Text)`
  margin-bottom: 4px;
`

const IconWrap = styled.div`
  position: absolute;
  top: 1.9rem;
  right: 0.5rem;
  color: ${theme.color.icon};
  body[dir='rtl'] & {
    left: 0.5rem;
    right: unset;
  }
`

const Input = ({ label, placeholder, width = 'full', icon, ...props }) => (
  <InputContainer>
    <Label>{label}</Label>
    <StyledInput
      variant='outlined'
      hasIcon={!!icon}
      placeholder={placeholder || ''}
      fullWidth={width === 'full'}
      {...props}
    />
    {icon && <IconWrap>{icon}</IconWrap>}
  </InputContainer>
)

export default Input