import { GET_LIST, GET_ONE, CREATE, UPDATE, DELETE, GET_MANY, GET_MANY_REFERENCE, UPDATE_MANY, fetchUtils } from "react-admin"

import settings from "../tools/settings"
import { Enum } from "../tools/utils"
import { RolesEnum } from "../tools/permissions"
import authStore from "./stores/auth.store"
import moment from "moment"

const apiUrl = settings.apiUrl

export const RoutesEnum = Enum({
  ME: 'me',
  SYSTEMS: "systems",
  FILES: "files",
  ORGANIZATIONS: "organizations",
  PRODUCTS: "products",
  CUSTOMERS: "customers",
  USERS: "users",
  ORDERS: "orders",
  ORDER_SETTINGS: "order-settings",
  PAYMENTS: "payments",
  PAYMENT_METHODS: "payment-methods",
  BY_OPERATOR: "by-operator",
  REPORT_WITH_PAID_ORDERS: "paid-orders-report",
  REPORT_TO_FINANCIAL_SYS: "report-to-financia-system",
  ORDER_NEXT_NUMBER: "order-next-number",
  PAYMENT_CREDENTIALS: "payment-credentals",
  SHARE_SYSTEM_LINK: "share-system-link" 
})

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */

export const addUploadFeature = (requestHandler) => (type, resource, params) => {
  const d = params.data ? params : { data: params }
  if (d.data?.file?.rawFile instanceof File) {
    d.data.file = d.data.file.rawFile
    const form = new FormData()
    for (let key in d.data) {
      form.append(key, d.data[key])
    }
    return requestHandler(type, resource, { id: d.id, data: form })
  }
  return requestHandler(type, resource, params)
}

export default async (type, resource, params) => {
  await authStore.checkAuth()
  
  let url = ""

  const options = {
    credentials: "include",
    headers: new Headers({
      Accept: "application/json"
    })
  }
  const { id, orgId = null } = params

  const resultStorageByIds = authStore.getSettings()
  const resultStorageByResource = authStore.getResource(resource)
  const resultStorageByQueryParams = new URLSearchParams(window.location.hash.replace(/[^\?]+/, ""));

  const orgIdStorage = (resultStorageByResource?.org_id || resultStorageByIds?.org_id || resultStorageByQueryParams.get('org_id')) || orgId
  const systemIdStorage = resultStorageByResource?.system_id || resultStorageByIds?.system_id || resultStorageByQueryParams.get('system_id')

  const isAdmin = authStore.isSuperAdmin()
  const isManager = authStore.isOrgManager()

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination
      const { name = "", org_id, system_id, from = "", to = "", type: orderType = '' } = params.filter
      options.method = "GET"
      switch (resource) {
        case RoutesEnum.ORGANIZATIONS: {
          if (isAdmin || isManager) {
            url = `${apiUrl}/api/v1/${resource}?page=${page}&limit=${perPage}&q=${name}`
          }
          break
        }
        case RoutesEnum.SYSTEMS: {
          if (!isAdmin && !isManager) {
            url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/${resource}?page=${page}&limit=${perPage}`
          }
          if (org_id) {
            url = `${apiUrl}/api/v1/organizations/${org_id}/${resource}?page=${page}&limit=${perPage}`
          }

          break
        }
        case RoutesEnum.PRODUCTS: {
          const { status = null, sort = null } = params?.filter
          let filterQuery = ''

          if (status !== null) {
            filterQuery += `&status=${status}`
          }

          if(sort !== null) {
            const sortQuery = sort.reduce((acc, item) => {
              const [key, value] = item
              return acc + `&sort[${key}]=${value}`
            }, '')

            filterQuery += sortQuery
          } 

          if (!isAdmin && !isManager) {
            url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}?page=${page}&limit=${perPage}&q=${name}${filterQuery}`
          }

          if (org_id && system_id) {
            url = `${apiUrl}/api/v1/organizations/${org_id}/systems/${system_id}/${resource}?page=${page}&limit=${perPage}&q=${name}${filterQuery}`
          }
          break
        }
        case RoutesEnum.CUSTOMERS: {
          if (!isAdmin && !isManager) {
            url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}?page=${page}&limit=${perPage}&q=${name}`
          }

          if (org_id && system_id) {
            url = `${apiUrl}/api/v1/organizations/${org_id}/systems/${system_id}/${resource}?page=${page}&limit=${perPage}&q=${name}`
          }
          break
        }

        case RoutesEnum.ORDERS: {
          if (from && to) {
            const startDate = moment(`${from} 00:00:01`, "YYYY-MM-DD HH:mm:ss").utc().format("DD/MM/YYYY HH:mm:ss")
            const endDate = moment(`${to} 23:59:59`, "YYYY-MM-DD HH:mm:ss").utc().format("DD/MM/YYYY HH:mm:ss")
            if (!isAdmin && !isManager) {
              url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}?page=${page}&limit=${perPage}&q=${name}&startDate=${startDate}&endDate=${endDate}&type=${orderType}`
            }
            if (org_id && system_id) {
              url = `${apiUrl}/api/v1/organizations/${org_id}/systems/${system_id}/${resource}?page=${page}&limit=${perPage}&q=${name}&startDate=${startDate}&endDate=${endDate}&type=${orderType}`
            }
          }

          break
        }

        case RoutesEnum.USERS: {
          if (!isAdmin && !isManager) {
            url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}?page=${page}&limit=${perPage}&q=${name}`
          }

          if (org_id && system_id) {
            url = `${apiUrl}/api/v1/organizations/${org_id}/systems/${system_id}/${resource}?page=${page}&limit=${perPage}&q=${name}`
          }
          
          break
        }

        default:
          url = `${apiUrl}/api/v1/${resource}?page=${page}&limit=${perPage}&q=${name}`
      }

      break
    }
    case GET_ONE:
      options.method = "GET"
      switch (resource) {
        case RoutesEnum.ME: {
          url = `${apiUrl}/api/v1/me`
          break
        }
        case RoutesEnum.SHARE_SYSTEM_LINK: {
          url = `${apiUrl}/api/v1/organizations/${params.organizationId}/systems/${params.systemId}/public-link`
          break
        }
        case RoutesEnum.PAYMENT_METHODS: {
          url = `${apiUrl}/api/v1/organizations/${params.organizationId}/systems/${params.systemId}/charge/${params.customerId}/payment-methods`
          break     
        }
        case RoutesEnum.ORDER_NEXT_NUMBER: {
          url = `${apiUrl}/api/v1/organizations/${params.organizationId}/systems/${params.systemId}/orders/next-number`
          break     
        }
        case RoutesEnum.REPORT_WITH_PAID_ORDERS: {
          const { timezone, date } = params.query
          const query = `timezone=${timezone}&date=${date}`
          
          url = `${apiUrl}/api/v1/organizations/${params.organizationId}/systems/${params.systemId}/reports/paid-with-cc-monthly-report?${query}`
          break
        }
        case RoutesEnum.REPORT_TO_FINANCIAL_SYS: {
          const { timezone, status, date } = params.query
          const query = `timezone=${timezone}&status=${status}&date=${date}`
          
          url = `${apiUrl}/api/v1/organizations/${params.organizationId}/systems/${params.systemId}/reports/financial-monthly-report?${query}`
          break
        }
        case RoutesEnum.SYSTEMS: {
          const {  withPublicPaymentCreds = 0 } = params
          let query = ''
          
          if(withPublicPaymentCreds) {
            query = `?withPublicPaymentCred=1`  
          }

          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/${resource}/${id}${query}`
          break
        }
        case RoutesEnum.PRODUCTS: {
          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}/${id}`
          break
        }
        
        case RoutesEnum.CUSTOMERS: {
          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}/${id}`
          break
        }
        case RoutesEnum.ORDERS: {
          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}/${id}`
          break
        }
        case RoutesEnum.ORDER_SETTINGS: {
          url = `${apiUrl}/api/v1/organizations/${params.organizationId}/systems/${params.systemId}/${resource}`
          break
        }
        case RoutesEnum.ORGANIZATIONS: {
          url = `${apiUrl}/api/v1/${resource}/${id}`
          break
        }
        case RoutesEnum.USERS: {
          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}/${id}`
          break
        }
        default:
      }

      break
    case CREATE:
      options.method = "POST"

      switch (resource) {
        case RoutesEnum.SYSTEMS: {
          const { name , email, externalId = '',  paymentSettings: { active, paymentsOs, chargePage }, settings = null } = params.data;

          options.body = JSON.stringify({
            paymentSettings: {
              active,
              paymentsOs: {
                businessNumber: '',
                publicKey: '',
                privateKey: '',
                appId: '',
                ...paymentsOs,
              },
              chargePage: {
                logoLink: '',
                titleText: '',
                bodyText: '',
                businessAddress: '',
                businessPhone: '',
                ...chargePage,
              },
            },
            avatar: "",
            externalId,
            name,
            email,
            settings: {
              enableSendSmsWithOrderDetail: !settings ? false : settings.enableSendSmsWithOrderDetail  
            }
          })

          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/${resource}`
          break
        }
        case RoutesEnum.PAYMENT_CREDENTIALS: {
          options.body = JSON.stringify({ password: params.password })

          url = `${apiUrl}/api/v1/organizations/${params.organizationId}/systems/${params.systemId}/payment-settings`
          break
        }
        case RoutesEnum.PRODUCTS: {
          options.body = JSON.stringify({ ...params.data, image: params.data?.image ?? '' })

          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}`
          break
        }
        case RoutesEnum.CUSTOMERS: {
          options.body = JSON.stringify(params.data)

          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}`
          break
        }
        case RoutesEnum.USERS: {
          options.body = JSON.stringify({
            ...params.data,
            systemId: systemIdStorage,
            organizationId: orgIdStorage,
            roles: [params.data.roles]
          })

          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}`
          break
        }
        case RoutesEnum.ORDERS: {
          const { organizationId = "", systemId = "", items = [], customer = {}, status, paymentMethod } = params.filter
          options.body = JSON.stringify({ items, customer, status, paymentMethod })
          
          if (!isAdmin && !isManager) {
            url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}`
          }
          
          if (organizationId && systemId) {
            url = `${apiUrl}/api/v1/organizations/${organizationId}/systems/${systemId}/${resource}`
          }

          break
        }
        case RoutesEnum.ORDER_SETTINGS: {
          options.body = JSON.stringify(params.data)
          url = `${apiUrl}/api/v1/organizations/${params.organizationId}/systems/${params.systemId}/${resource}`
          break
        }
        case RoutesEnum.ORGANIZATIONS: {
          options.body = JSON.stringify(params.data)
          url = `${apiUrl}/api/v1/${resource}`
          break
        }
        case RoutesEnum.PAYMENTS: {
          const { organizationId , systemId, ...data } = params.filter;
          options.body = JSON.stringify(data)
          url = `${apiUrl}/api/v1/organizations/${organizationId}/systems/${systemId}/charge`
          break
        }
        default:
      }

      break
    case UPDATE:
      options.method = "PUT"

      switch (resource) {
        case RoutesEnum.FILES: {
          const { organizationId, systemId, fileId, data } = params      
          options.body = JSON.stringify(data);

          url = `${apiUrl}/api/v1/organizations/${organizationId}/systems/${systemId}/upload/files/${fileId}`
          break  
        }
        case RoutesEnum.SYSTEMS: {
          const data = params.data
          data['externalId'] = data['externalId'] === null ? '' : data['externalId']
          
          options.body = JSON.stringify(data);

          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/${resource}/${params.id}`
          break
        }
        case RoutesEnum.PRODUCTS: {
          const productsData = {}
          for (const key in params.data) {
            if (params.data[key]?.toString()) {
              productsData[key] = params.data[key]
            }
          }
          options.body = JSON.stringify(productsData)

          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}/${params.id}`
          break
        }
        case RoutesEnum.CUSTOMERS: {
          const { mobile, email ,...data} = params.data

          options.body = JSON.stringify({ mobile: mobile ? mobile : '', email: email ? email : '', ...data })

          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}/${params.id}`
          break
        }
        case RoutesEnum.ORDERS: {
          options.body = JSON.stringify(params.data)

          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}/${params.id}`
          break
        }
        case RoutesEnum.ORGANIZATIONS: {
          options.body = JSON.stringify(params.data)

          url = `${apiUrl}/api/v1/${resource}/${params.id}`
          break
        }
        case RoutesEnum.USERS: {
          options.body = JSON.stringify({
            ...params.data,
            systemId: [params.data.roles].flat(1)[0] === RolesEnum.ORG_MANAGER ? null : systemIdStorage,
            organizationId: orgIdStorage,
            roles: [params.data.roles].flat(1)
          })
          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}/${id}`
          break
        }
        case RoutesEnum.BY_OPERATOR: {
          const { id, organizationId, systemId, email, mobile } = params.data

          options.body = JSON.stringify({ email, mobile });

          url = `${apiUrl}/api/v1/organizations/${organizationId}/systems/${systemId}/customers/${id}/by-operator`
          break
        }
        default:
      }
      break
    case UPDATE_MANY:
      options.method = "POST"

      switch (resource) {
        case RoutesEnum.ORDERS: {
          const [selectedIds, org_id = orgIdStorage, system_id = systemIdStorage] = params?.ids
          options.body = JSON.stringify({ status: params.data.status, ids: selectedIds })
          url = `${apiUrl}/api/v1/organizations/${org_id}/systems/${system_id}/${resource}/statuses`
          break
        }
        default:
      }

      break
    case DELETE:
      options.method = "DELETE"

      switch (resource) {
        case RoutesEnum.FILES: {
          const { organizationId, systemId, fileId } = params      
          url = `${apiUrl}/api/v1/organizations/${organizationId}/systems/${systemId}/upload/files/${fileId}`
          break  
        }

        case RoutesEnum.SYSTEMS: {
          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/${resource}/${params.id}`
          break
        }
        case RoutesEnum.PRODUCTS: {
          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}/${params.id}`
          break
        }
        case RoutesEnum.CUSTOMERS: {
          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}/${params.id}`
          break
        }
        case RoutesEnum.ORDERS: {
          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}/${params.id}`
          break
        }
        case RoutesEnum.ORGANIZATIONS: {
          url = `${apiUrl}/api/v1/${resource}/${params.id}`

          break
        }
        case RoutesEnum.USERS: {
          url = `${apiUrl}/api/v1/organizations/${orgIdStorage}/systems/${systemIdStorage}/${resource}/${params.id}`

          break
        }
        default:
      }

      break
    case GET_MANY: {
      break
    }
    case GET_MANY_REFERENCE: {
      break
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`)
  }

  const { fetchJson } = fetchUtils
  return await fetchJson(url, options).then((response) => {
    if (!response.json) {
      return { data: [], total: 0 }
    }

    return response.json
  })
}
