import React from 'react'
import styled from 'styled-components'
import { Paper } from '@mui/material'

const StyledBlockquote = styled(Paper)`
  padding: 1rem;
  background: ${p => p.error && 'rgba(193,54,54,0.3)'};
`

const Blockquote = ({ ...props }) => <StyledBlockquote {...props} />

export default Blockquote