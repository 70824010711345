import React, { useState, useRef, useEffect } from 'react';
import { useTranslate } from "react-admin"
import { observer, inject } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Snackbar, Table, TableRow, TableHead, TableContainer, TableCell, TableBody } from '@mui/material';
import { Button } from '../components';
import { useScript } from '../utils/hooks';
import { paymentsOS } from '../../cashier/utils/settings';
import { useExtendedState } from '../utils/helpers'
import {
  Form,
  Loading,
  StyledTOU,
  StyledSum,
  StyledLabelSum,
  StyledContainerSum,
  StyledValue,
  StyledLabel,
  StyledContainerItem,
  StyledContainerOrderInfo,
  StyledCardOrderSummary,
  StyledContainerDescription,
  StyledCardContent,
  ContentContainer,
  ContainerError,
  StyledCardInput,
  StyledCvvInput,
  StyledInput,
  ErrorMessage,
  StyledCvvAndExpiryContent,
  ZoozForm,
  StyledTitle,
  StyledTitleOrderInfo,
  StyledText,
  StyledContainerImage,
  Image,
  StyledCardPublic,
  StyledContainerTitle,
  Alert,
  styleZoozForm,
  inputLabelProps,
  ContentContainerPublic,
  ContentContainerPublic2,
  StyledCvvAndExpiryContentPublic,
  Label
} from '../../cashier/styled-component';
import {
  isDinersCreditCard,
  isAmericanCreditCard,
  invalidExpiration,
  invalidCreditCardNumber,
  invalidCVV
} from '../../cashier/utils/creditCardValidation'


export const StyledButton = styled(Button)`
	-moz-box-shadow: 3px 4px 0px 0px #1564ad;
	-webkit-box-shadow: 3px 4px 0px 0px #1564ad;
	box-shadow: 3px 4px 0px 0px #1564ad;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0.05, #79bbff),
		color-stop(1, #378de5)
	);
	background: -moz-linear-gradient(top, #79bbff 5%, #378de5 100%);
	background: -webkit-linear-gradient(top, #79bbff 5%, #378de5 100%);
	background: -o-linear-gradient(top, #79bbff 5%, #378de5 100%);
	background: -ms-linear-gradient(top, #79bbff 5%, #378de5 100%);
	background: linear-gradient(to bottom, #79bbff 5%, #378de5 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#79bbff', endColorstr='#378de5',GradientType=0);
	background-color: #79bbff;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px!important;
	border: 1px solid #337bc4!important;
	display: inline-block;
	cursor: pointer;
	color: #ffffff!important;
	font-family: Arial;
	font-size: 17px!important;
	font-weight: bold!important;
	padding: 12px 44px!important;
	text-decoration: none;
	text-shadow: 0px 1px 0px #528ecc!important;
`;

const Charge = ({ customerStore }) => {
  const translate = useTranslate()
  const h = useNavigate();
  const zoozForm = useRef();
  const isLoaded = useScript(paymentsOS.script);

  const params = useParams()
  const [ error, setError ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ name, setName ] = useState('');
  const [ card, setCard ] = useState("");
  const [ errors, setErrors ] = useState({ card: '', cvv: '', expirationDate: '' })
  const [ expirationDate, setExpirationDate ] = useState("");
  const [ cvv, setCvv ] = useState("");
  const [ chargeBtnDisabled, setChargeBtnDisabled ] = useState(false);
  const [ token, setToken, getToken ] = useExtendedState('');
  const [ isOpenSnackbar, setOpenSnackbar ] = useState(false);
  const [ snackbar, setSnackbar ] = useState({});
  const [ systemSettings, setSystemSetings ] = useState({ active: false });
  const [ order, setOrder] = useState({});
  
  const openSnackbar = (message, severity) => {
    setOpenSnackbar(true);
    setSnackbar({ message: message, severity: severity });
  };

  const isValidCreditCard = (json) => {
    if (isDinersCreditCard(json.vendor)) {
      setError(translate('diners_not_available'));
      return false;
    }

    if (isAmericanCreditCard(json.vendor)) {
      setError(translate('amex_not_available'));
      return false;
    }

    if (invalidExpiration(json)) {
      setError(translate('invalid_expiration_date'));
      return false;
    }

    if (invalidCreditCardNumber(json)) {
      setError(translate('invalid_credit_card_number'));
      return false;
    }

    if (invalidCVV(json)) {
      setError(translate('invalid_cvv'));
      return false;
    }

    if (!json.token) {
      setError(translate('credit_card_invalid'));
      return false;
    }

    return true;
  };

  const setChargeBtnDisabledInTime = async () => {
    setInterval(() => {
      setChargeBtnDisabled(false)
    }, 5000)
  }

  const handleResponsePaymentsOS = async (result) => {
    setChargeBtnDisabled(true)

    try {
      const json = JSON.parse(result);
    
      if (!isValidCreditCard(json)) {
        setChargeBtnDisabled(false)
        return;
      }

      setToken(json.token)
      await charge();
    
    } catch (error) {
      setChargeBtnDisabled(false)
      openSnackbar(translate('requestResult.error'), 'error');
    }

    await setChargeBtnDisabledInTime()
  };

  const charge = async () => {
    setChargeBtnDisabled(true)
    
    try {
      const paymentToken = await getToken()

      const { data } = await customerStore.charge(
        order.id, 
        order.totalPayments, 
        paymentToken
      )

      if (data.status === 'success') {
        openSnackbar(translate('requestResult.success'), 'success');
        h(`/order/${data.hashUrl}/success`, {
          state: systemSettings.chargePage?.logoLink,
        });

      } else {
        setChargeBtnDisabled(false)
        openSnackbar(translate('requestResult.error'), 'error');
      }
    } catch (error) {
      setChargeBtnDisabled(false)
      openSnackbar(translate('requestResult.error'), 'error');
    }
    await setChargeBtnDisabledInTime()
  };

  const closeSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const getFullName = fields => {
    if (!fields) return ''
    const { name, full_name, firstName, lastName } = fields
    if (name) return name
    if (full_name) return full_name
    if (firstName && lastName) return `${firstName} ${lastName}`
    return ''
  };

  useEffect(() => {
    if (isLoaded && systemSettings.isActive) {
      window.POS.setEnvironment(process.env.REACT_APP_PAYMENTS_OS_ENV);
      window.POS.setPublicKey(systemSettings.publicKey);
    }
  }, [ isLoaded, systemSettings ]);

  useEffect(() => {
    customerStore.getOrder(params.id).then((data) => {
        const { settings, order} = data
        setSystemSetings(settings)
        setOrder(order)

        if (!settings.isActive) {
          h('/404')
        }
         
      }).catch((err) => {
        if (err?.response) {
          if (err?.response?.status == 404) {
            h('/404')
          }
        }

        openSnackbar(translate('requestResult.error'), 'error');
      })
  }, []);

  const formatExpirationDate = (value) => {
    if (value == '') {
      return value
    } 

    const { format } = value.split('').reduce((acc ,val) => {
      acc.format += acc.n == 2 ? ` / ${val}` :`${val}`
      acc.n += 1

      return acc      
    }, { format: '', n: 0 })

    return format
  }

  const formatCard = (value) => {
    const { format } = value.split('').reduce((acc, val) => {
      if (acc.n == 4) {
        acc.format += ` ${val}`
        acc.n = 1
      } else {
        acc.format += `${val}`
        acc.n += 1
      }
      
      return acc
    }, { format: '', n: 0})

    return format
  }

  const validateFormData = () => {
    let isValid = true

    const formErrors = {
      card: '',
      cvv: '',
      expirationDate: ''
    }
    
    if (!/^\d{16,19}$/.test(card)) {
      formErrors.card = translate('credit_card.cc_is_invalid') 
      isValid = false
    }

    if (!/^[0-9]{3,4}$/.test(cvv)) {
      formErrors.cvv = translate('credit_card.cvv_is_invalid') 
      isValid = false
    }

    if (!/^(0[1-9]|1[0-2])[2-4][0-9]$/.test(expirationDate)) {
      formErrors.expirationDate = translate('credit_card.exp_date_is_invalid') 
      isValid = false
    }

    setErrors(formErrors)
    return isValid
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!validateFormData()) {
      openSnackbar(translate('requestResult.error'), 'error');
      return
    }

    window.POS.tokenize({
        "token_type": "credit_card",
        "holder_name": name,
        "expiration_date": `${expirationDate.substring(0, 2)}-20${expirationDate.substring(2, 4)}`,
        "card_number": card,
        "cvv": cvv
    }, function (result) {
        handleResponsePaymentsOS(result)
    })
  }

  return (
    <>
      {loading ? <Loading /> : null}
      <StyledCardPublic loading={loading}>
        <StyledCardContent>
          {systemSettings?.chargePage?.logoLink && (
            <StyledContainerImage>
              <Image src={systemSettings.chargePage.logoLink} />
            </StyledContainerImage>
          )}
          <StyledContainerDescription>
            <StyledTitle>{systemSettings.chargePage?.titleText}</StyledTitle>
            <StyledText>{systemSettings.chargePage?.bodyText}</StyledText>
          </StyledContainerDescription>
          <StyledCardOrderSummary>
            <StyledContainerTitle>
              <StyledTitleOrderInfo>{translate('payments.payment_details')}</StyledTitleOrderInfo>
            </StyledContainerTitle>
            <StyledContainerOrderInfo>
              <StyledContainerItem>
                <StyledLabel>{translate('payments.order_number')}</StyledLabel>
                <StyledValue>{order.orderNumber}</StyledValue>
              </StyledContainerItem>
              <StyledContainerItem>
                <StyledLabel>{translate('payments.customer_name')}</StyledLabel>
                <StyledValue>{getFullName(order.customer)}</StyledValue>
              </StyledContainerItem>
              <StyledContainerItem>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">{translate('payments.product_name')}</TableCell>
                            <TableCell align="right">{translate('payments.price')}</TableCell>
                            <TableCell align="right">{translate('payments.quantity')}</TableCell>
                          </TableRow>
                      </TableHead>
                    <TableBody>
                        {order.items?.map((item) => (
                            <TableRow key={item._id}>
                                <TableCell align="right">{item.name}</TableCell>
                                <TableCell align="right">{Number.parseFloat(item.price).toFixed(2)}</TableCell>
                                <TableCell align="right">{item.quantity}</TableCell>
                              </TableRow>
                          ))}
                      </TableBody>
                  </Table>
                </TableContainer>
              </StyledContainerItem>
              <StyledContainerSum>
                <StyledLabelSum>{translate('payments.sum')}</StyledLabelSum>
                <StyledSum>{Number.parseFloat(order.totalPayments).toFixed(2)} ₪</StyledSum>
              </StyledContainerSum>
            </StyledContainerOrderInfo>
          </StyledCardOrderSummary>
          <ZoozForm
            id="payment-form"
            ref={zoozForm}
            onSubmit={handleSubmit}
          >
            <StyledText>{translate("payments.credit_card_title")}</StyledText>
            <ContentContainerPublic2>
              <StyledInput
                id="holder_name"
                isRtl={false}
                variant="outlined"
                InputLabelProps={inputLabelProps}
                placeholder={translate('payments.cardholder_fullname_label')}
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </ContentContainerPublic2>
            <ContentContainerPublic2>
                  <StyledCardInput
                    id="pan"
                    isRtl={true}
                    variant="outlined"
                    InputLabelProps={inputLabelProps}
                    placeholder={translate('payments.credit_card_number_placeholder')}
                    fullWidth={true}
                    value={formatCard(card)}
                    autoComplete='cc-number'
                    onChange={(e) => {
                      const value = e.target.value.replace(/[\s]*/g, '')
                      if (/^(|[\d+\*]+)$/g.test(value)) {
                        setCard(value)   
                      } 
                    }}
                />
              {errors.card != '' && <ErrorMessage>{errors["card"]}</ErrorMessage>}
            </ContentContainerPublic2>
            <StyledCvvAndExpiryContentPublic>
            <ContentContainerPublic>
              <StyledCvvInput
                id="cvv"
                isRtl={false}
                variant="outlined"
                InputLabelProps={inputLabelProps}
                placeholder={'cvv'}
                fullWidth={true}
                value={cvv}
                onChange={(e) => {
                  if (/^(|\d{1,3})$/g.test(e.target.value)) {
                    setCvv(e.target.value)   
                  }
                }}
              />
              {errors.cvv != '' && <ErrorMessage>{errors["cvv"]}</ErrorMessage>}
            </ContentContainerPublic>
            <ContentContainerPublic>
              <StyledCardInput
                id="expiration_date"
                isRtl={false}
                variant="outlined"
                InputLabelProps={inputLabelProps}
                autoComplete='cc-exp'
                placeholder={"mm / yy"}
                fullWidth={true}
                value={formatExpirationDate(expirationDate)}
                onChange={(e) => {
                  const value = e.target.value.replace(/[\s\/]*/g, '')

                  if (/^\d{6}$/g.test(value)) {
                    setExpirationDate(`${value.substring(0,2)}${value.substring(4,6)}`)
                  } else if (/^(|\d{1,4})$/g.test(value)) {
                    setExpirationDate(value)   
                  }
                }}
              />
              {errors.expirationDate != '' && <ErrorMessage>{errors["expirationDate"]}</ErrorMessage>}
            </ContentContainerPublic>
            </StyledCvvAndExpiryContentPublic>
            <ContentContainerPublic>
              <StyledButton
                style={{ width: '100%' }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={chargeBtnDisabled}
              >
                {translate('payments.save_and_charge')}
              </StyledButton>
            </ContentContainerPublic>
          </ZoozForm>
          <StyledTOU>{translate('payments.tou_sentence')}<br />{systemSettings.chargePage?.businessAddress}<br />{systemSettings.chargePage?.businessPhone}</StyledTOU>
          <ContainerError>
            <Form error={error} />
          </ContainerError>
        </StyledCardContent>
      </StyledCardPublic>
      <Snackbar
        open={isOpenSnackbar}
        autoHideDuration={6000}
        onClose={closeSnackbarClose}
      >
        <Alert onClose={closeSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default inject('customerStore')(observer(Charge))
