import React from "react"
import { useTranslate, useDataProvider, GET_LIST, GET_ONE } from "react-admin"
import { observer, inject } from 'mobx-react'
import { format, parseISO } from "date-fns"
import { IconButton } from "@mui/material"
import DownloadIcon from "@material-ui/icons/GetApp"
import { getExporter } from "../../tools/utils"
import { RoutesEnum } from "../../core/restProvider"

const SHEET_NAME = "Orders"

const ExportToSystemAdmin = ({ authStore, filterValues, currentSort }) => {

  const { org_id = '', system_id = '' } = filterValues

  const resultStorageByIds = authStore._ids
  const orgId = org_id || resultStorageByIds?.org_id
  const systemId = system_id || resultStorageByIds?.system_id
  
  const restProvider = useDataProvider()
  const translate = useTranslate()

  const getColumns = (order) => {
    const orderColumns = [
      {
        header: translate("ra.report_pos_system_admin.columns.created_at"),
        key: "createdAt"
      },
      {
        header: translate("ra.report_pos_system_admin.columns.org_name"),
        key: "orgName"
      },
      {
        header: translate("ra.report_pos_system_admin.columns.system_name"),
        key: "systemName"
      },
      {
        header: translate("ra.report_pos_system_admin.columns.order_number"),
        key: "orderNumber"
      },
      {
        header: translate("customRoot.myMenu.seller"),
        key: "seller"
      },
      {
        header: translate("ra.report_pos_system_admin.columns.customer_name"),
        key: "customerName"
      },
      {
        header: translate("ra.report_pos_system_admin.columns.total_sum"),
        key: "totalSum"
      },
      {
        header: translate("ra.report_pos_system_admin.columns.status"),
        key: "status"
      }
    ]
    for (const key in order.fields) {
      orderColumns.push({ header: key, key: key })
    }
    const itemColumns = [
      {
        header: translate("ra.report_pos_system_admin.columns.category"),
        key: "category"
      },
      {
        header: translate("ra.report_pos_system_admin.columns.item"),
        key: "item"
      },
      {
        header: translate("ra.report_pos_system_admin.columns.price"),
        key: "pricePerOne"
      },
      {
        header: translate("ra.report_pos_system_admin.columns.quantity"),
        key: "quantity"
      },
      {
        header: translate("ra.report_pos_system_admin.columns.item_remarks"),
        key: "itemRemarks"
      }
    ]
    return [...orderColumns, ...itemColumns]
  }

  const getOrderData = (order, orgName, systemName, index) => {
    const sum = order.items.reduce((a, b) => {
      return b.pricePerOne * b.quantity + a
    }, 0)

    const orderData = {
      createdAt: format(parseISO(order.createdAt), "HH:mm dd/MM/yyyy"),
      orgName: orgName,
      customerName: `${order.customer.firstName} ${order.customer.lastName}`,
      systemName: systemName,
      orderNumber: order.orderNumber,
      totalSum: order.status == 'refund' ? sum * -1 : sum,
      status: translate(`customRoot.orders.status.${order.status}`),
      seller: order.seller ? `${order.seller.fname} ${order.seller.lname}` : ''
    }
    for (const key in order.fields) {
      orderData[key] = order.fields[key]
    }
    if (index > 0) {
      for (const key in orderData) {
        orderData[key] = ""
      }
    }
    return orderData
  }

  const getItemData = (item) => {
    return {
      category: item.category,
      item: item.name,
      pricePerOne: item.pricePerOne,
      quantity: item.quantity,
      itemRemarks: item.remarks
    }
  }

  const getRecords = (orders, orgName, systemName) =>
    orders.flatMap((order) =>
      order.items.map((item, index) => {
        return {
          ...getOrderData(order, orgName, systemName, index),
          ...getItemData(item)
        }
      })
    )

  const getFileName = (orgName, systemName) => `${orgName}-${systemName}-${translate("ra.report_pos_system_admin.system_admin")}`

  const getOrders = async () => {
    const res = await restProvider(GET_LIST, RoutesEnum.ORDERS, { filter: filterValues, sort: currentSort, pagination: {} })
    return Object.values(res.data)
  }

  const getOrgName = async () => {
    const res = await restProvider(GET_ONE, RoutesEnum.ORGANIZATIONS, { id: orgId })
    const { data } = res

    return data.displayName
  }

  const getSystemName = async () => {
    const res = await restProvider(GET_ONE, RoutesEnum.SYSTEMS, { id: systemId, orgId })
    const { data } = res

    return data.name
  }

  const exportToXls = async () => {
    try {
      const orders = await getOrders()
      if (!orders.length) {
        return
      }

      const orgName = await getOrgName()
      const systemName = await getSystemName()

      const columns = getColumns(orders[0])
      const records = getRecords(orders, orgName, systemName)
      getExporter(getFileName(orgName, systemName))(SHEET_NAME, columns, records)
    } catch (e) {
      console.log("error", e)
    }
  }

  return (
    <IconButton size='small' color='primary' onClick={exportToXls}>
      <DownloadIcon />
      {translate("ra.action.export_admin_system")}
    </IconButton>
  )
}
export default inject('authStore')(observer(ExportToSystemAdmin))
