import React, { useState } from "react"
import styled from "styled-components"

import { Clear, Search as SearchIcon } from "@material-ui/icons"
import { Autocomplete, TextField } from "@mui/material"
import { useTranslate } from "react-admin"
import InternalUserFlag from "./InternalUserFlag"

const Search = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  padding: 2px 40px 2px 10px;
  border-radius: 8px;
  box-shadow: rgb(183 183 239) 4px 4px 6px 1px, rgb(255 255 255) -4px -4px 6px 1px;
`

const SSearchIcon = styled(SearchIcon)`
  color: rgb(90, 190, 240);
  margin: 0 6px;
  transition: all 0.3s ease-in-out;
  transform: scaleX(${(p) => (p.hassearch ? 0 : 1)});
  position: absolute;
  right: 0;
`

const SClean = styled(Clear)`
  color: rgb(90, 190, 240);
  margin: 0 6px;
  transform: scaleX(${(p) => (p.hassearch ? 1 : 0)});
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 0;
`

const SInput = styled(TextField)`
  .MuiInputBase-root {
    :before,
    :after,
    .MuiAutocomplete-endAdornment {
      display: none;
    }
  }
`

const Option = styled.div`
  padding-left: 6px !important;
  padding-right: 6px !important;
`

const Op = styled.span`
  padding: 0 0.5em;
  font-size: 0.75em;
  text-align: center;
`

const Column = styled.div`
  display: flex;
  justify-content: center;
  flex: 15% 0 0;
  padding: 0;
`

const Label = ({ item, ...props }) => {
  return (
    <Option dir='rtl' {...props}>
      <Column ><Op>{item.firstName + " " + item.lastName}</Op></Column>
      <Column ><Op>{item.budgetNumber}</Op></Column>
      <Column style={{ flex: '25% 0 0' }}><Op>{item.mobile}</Op></Column>
      <Column style={{ flex: '30% 1 1' }}><Op>{item.email}</Op></Column>
      <Column ><Op>{<InternalUserFlag customerSocialId={item.socialId} fontSize="small" text />}</Op></Column>
    </Option>
  )
}

const CustomerSearch = ({ customers = [], handleChange, setSelectedCustomer, selectedCustomer }) => {
  const [value, setValue] = useState("")
  const translate = useTranslate()

  const onInputChange = (_, v) => setValue(v)

  const clear = () => {
    setValue("")
    setSelectedCustomer(null)
  }

  return (
    <Search>
      <SClean onClick={clear} hassearch={!!value ? 1 : 0} />
      <SSearchIcon hassearch={!!value ? 1 : 0} />
      <Autocomplete
        className="customerSearchInputMekome"
        getOptionLabel={(o) => `${o.firstName} ${o.lastName}`}
        value={selectedCustomer}
        onChange={handleChange}
        inputValue={value}
        onInputChange={onInputChange}
        freeSolo
        style={{ width: "100%"}}
        options={customers}
        renderOption={(props, o) => <Label {...props} item={o} />}
        renderInput={(params) => <SInput fullWidth {...params} placeholder={translate("customRoot.cashier.user_search_placeholder")} />}
      />
    </Search>
  )
}

export default CustomerSearch
