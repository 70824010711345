import tc from 'tinycolor2'

const base = tc('#f6f6fd')
const background = base.toHexString()
const d = base.darken(5).toHexString()
const darker = base.darken(10).toHexString()
const lighter = base.lighten(50).toHexString()

const theme = {
  unit: 'rem',
  fontSize: {
    text: '1rem',
    sub: '0.8rem',
    heading1: 'calc(1.625rem + 1.6vw)',
    heading2: 'calc(1.375rem + 1.5vw)',
    heading3: 'calc(1.25rem + 1vw)',
    heading4: 'calc(1.135rem + .6vw)',
    heading5: 'calc(1.15rem + .2vw)',
    heading6: 'calc(1rem + .2vw)',
    heading7: 'calc(1rem + .1vw)'
  },
  color: {
    base: '#212133',
    text: '#606077',
    primary: '#8cbfca',
    error: '#ca2119',
    warning: '#f5cdcd',
    secondary: '#129ca6',
    
    background: background,
    bbb: d,
    darker: darker,
    lighter: lighter,
    icon: 'rgba(90,190,240,1)',
    
    accent: '#195a63',
    disable: '#cccccc',
    link: '#2460c3',
    white: '#ffffff',
    red: '#c13636',
    green: '#2f8256',
    back: '$F6F6FD',
    black: '#000000',
    grey: '#999999',
    blue: '#2095ad',
    pink: '#f5cdcd'
  },
  space(...args) {
    const result = (args.length ? [...args] : [1]).reduce((str, curr) => `${str} ${curr}rem`, '')
    return result
  },
  css: {
    centered: 'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);',
    transition: 'all 300ms ease-in-out;',
  }
}

export default theme