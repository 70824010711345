import React, { Suspense } from 'react'
import styled, { keyframes } from 'styled-components'
import { Container, Grid } from '@mui/material'
import { isMobile } from '../utils/helpers'
import Loading from './Loading'

const fadeInLeft = keyframes`
  0% {
    opacity: 0.01;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const StyledPage = styled(Container)`
  padding: ${p => (p.padded === 'true' ? '1rem' : 0)};
  padding-top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: all 200ms ease-in-out;
  animation: ${fadeInLeft} 300ms ease-in-out 1;
`

const GridContainer = styled(Grid)`
  ${isMobile && 'flex: 1'};
`

const Page = ({ maxWidth = 'lg', children, padded = 'true', ...props }) => (
  <Suspense fallback={<Loading />}>
    <StyledPage fixed maxWidth={maxWidth} padded={padded} {...props}>
      <GridContainer container direction={isMobile ? 'column' : 'row'}>
        {children}
      </GridContainer>
    </StyledPage>
  </Suspense>
)

export default Page