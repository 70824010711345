import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styled, { keyframes } from "styled-components";
import { Table, TableRow, TableHead, TableCell, TableBody } from '@mui/material';
import publicRequests from '../../core/publicRequests'
import moment from "moment"


const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const anim = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -120px;
  }
`

const Spin = styled.div`
  display: inline-block;
  animation: ${rotate} 1.4s linear infinite;
  width: ${p => p.size ? p.size : '40px'};
  height: ${p => p.size ? p.size : '40px'};

  svg {
    width: ${p => p.size ? p.size : '40px'};
    height: ${p => p.size ? p.size : '40px'};
    circle {
      animation: ${anim} 1.4s ease-in-out infinite;
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0px;
      stroke: currentColor;
    }
  }
`

const Spinner = ({ size }) => (
  <Spin size={size}>
    <svg viewBox='22 22 44 44'>
      <circle cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" />
    </svg>
  </Spin>
)

const LoadingWrap = styled.div`
  display:flex;
  flex:1;
  width:100%;
  height:100%;
  justify-content:center;
  align-items:center;
`

const Loading = ({ wrapPosition, size }) => <LoadingWrap wrapPosition={wrapPosition}><Spinner size={size} /></LoadingWrap>


const Image = styled.img`
  width: 60px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

const Container = styled.div`
  font-family: monaco,Consolas,Lucida Console,monospace; 
  max-width: 400px;
  min-width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid пкфн;
  border-radius: 5px;
  padding: 8px 10px;
`

const Title = styled.div`
 border-bottom: 4px dashed #9e9e9e;
 font-size: 18px;
 padding-bottom: 15px;
 padding-top: 15px;
 display:flex;
 align-items:center;
 justify-content: center;
 font-weight: bold;
 text-align: center;
`

const Title2 = styled.div`
 border-bottom: 1px dotted #9e9e9e;
 border-top: 1px dotted #9e9e9e;
 font-size: 14px;
 padding-bottom: 15px;
 padding-top: 15px;
 display:flex;
 align-items:center;
 justify-content: center;
`

const Title3= styled.div`
 font-size: 18px;
 padding-bottom: 15px;
 padding-top: 15px;
 display:flex;
 align-items:center;
 justify-content: center;
 font-weight: bold;
`

const Receipt = (props) => {
  const location = useLocation();
  const h = useNavigate();
  const [ order, setOrder] = useState({});
  const [ loading, setLoading ] = useState(true);
  const params= useParams();

  useEffect(() => {
    publicRequests.getReceiptIonfo(params.code).then((res) => {       
        const { orderNumber, customer, sum, items, createdAt } = res.data;
        setOrder({ orderNumber, customer, sum, items, createdAt })
        setLoading(false)
      }).catch((err) => {
        console.log(err)
        h("/404")
      })
  }, []);


  return (
    <>
      <Image src="/mekome_logo.png" alt="image" />
      <hr/>

      {loading 
        ? <Loading /> 
        : <Container>
        <Title>בוצע תשלום עבור הזמנה ל הזמנה מספר {order.orderNumber}</Title>    
        <Table>
            <TableBody>        
                <TableRow key={"k-1"}>
                    <TableCell style={{borderBottom:"none", padding: "5px 16px"}} align="left">
                    שם:
                    </TableCell>
                    <TableCell style={{borderBottom:"none", padding: "5px 16px"}} align="right">
                    {order.customer.firstName}
                    </TableCell>
                </TableRow>   
                <TableRow key={"k-2"}>
                    <TableCell style={{borderBottom:"none", padding: "5px 16px"}} align="left">
                    שם משפחה:
                    </TableCell>
                    <TableCell style={{borderBottom:"none", padding: "5px 16px"}} align="right">
                    {order.customer.lastName}
                    </TableCell>
                </TableRow>
                <TableRow key={"k-3"}>
                    <TableCell style={{borderBottom:"none", padding: "5px 16px"}} align="left">
                    טלפון:
                    </TableCell>
                    <TableCell style={{borderBottom:"none", padding: "5px 16px"}} align="right">
                    {order.customer.phone}
                    </TableCell>
                </TableRow>      
                <TableRow key={"k-4"}>
                    <TableCell style={{borderBottom:"none", padding: "5px 16px"}} align="left">
                    כתובת מייל:
                    </TableCell>
                    <TableCell style={{borderBottom:"none", padding: "5px 16px"}} align="right">
                    {order.customer.email}
                    </TableCell>
                </TableRow>         
                <TableRow key={"k-5"}>
                    <TableCell style={{borderBottom:"none", padding: "5px 16px"}} align="left">
                    מספר תקציב:
                    </TableCell>
                    <TableCell style={{borderBottom:"none", padding: "5px 16px"}} align="right">
                    {order.customer.budgetNumber}
                    </TableCell>
                </TableRow>                          
            </TableBody>
        </Table>  
        <Title2>
        <Table>
            <TableBody>        
                <TableRow key={"c-details-1"}>
                    <TableCell style={{borderBottom:"none", padding: "0px 16px"}} align="left">
                    מספר הזמנה:                   
                    </TableCell>
                    <TableCell style={{borderBottom:"none", padding: "0px 16px"}} align="right">
                    {order.orderNumber}
                    </TableCell>
                </TableRow>   
                <TableRow key={"c-details-2"}>
                    <TableCell style={{borderBottom:"none", padding: "0px 16px"}} align="left">
                    אמצעי תשלום:
                    </TableCell>
                    <TableCell style={{borderBottom:"none", padding: "0px 16px"}} align="right">
                    כרטיס אשראי:                  
                    </TableCell>
                </TableRow>   
                <TableRow key={"c-details-3"}>
                    <TableCell style={{borderBottom:"none", padding: "0px 16px"}} align="left">
                    תאריך ההזמנה:
                    </TableCell>
                    <TableCell style={{borderBottom:"none", padding: "0px 16px"}} align="right">
                      {moment.utc(order.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").local().format("DD/MM/YYYY HH:mm")}
                    </TableCell>
                </TableRow>             
            </TableBody>
        </Table>  
        </Title2>
        <Title>הפרטים שהוזמנו</Title> 
        <Table>
        <TableHead>
            <TableRow>
                <TableCell style={{borderBottom:"double"}} align="left">שם המוצר</TableCell>
                <TableCell style={{borderBottom:"double"}} align="left">מחיר</TableCell>
                <TableCell style={{borderBottom:"double"}} align="left">כמות</TableCell>
                <TableCell style={{borderBottom:"double"}} align="left">סה"כ</TableCell>
              </TableRow>
        </TableHead>
            <TableBody>
              {order.items.map((item, i) => 
                <TableRow key={`${i}-product-item`}>
                  <TableCell align="left">
                    {item.name}
                  </TableCell>
                  <TableCell align="left">
                    {item.pricePerOne}
                  </TableCell>
                  <TableCell align="left">
                    {item.quantity}
                  </TableCell>
                  <TableCell align="left">
                    {item.price}
                  </TableCell>  
                </TableRow>
              )}        
                                          
            </TableBody>
        </Table>
        <Title3> סך הכל שולם:&nbsp;  <span> {order.sum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} ₪ </span></Title3> 
      </Container>}
    </>
  );
};

export default Receipt;
