import * as React from 'react';
import { Link } from 'react-router-dom';
import ContentCreate from '@mui/icons-material/Create';
import {
    useResourceContext,
    useRecordContext,
    useCreatePath,
    Button
} from 'react-admin';

const scrollStates = {
    true: { _scrollToTop: true },
    false: {},
};

const defaultIcon = <ContentCreate />;
const stopPropagation = e => e.stopPropagation();

export const EditButton = (props) => {
    const {
        query = '',
        icon = defaultIcon,
        label = 'ra.action.edit',
        scrollToTop = true,
        ...rest
    } = props;

    const resource = useResourceContext(props);
    const record = useRecordContext(props);
    const createPath = useCreatePath();
    if (!record) return null;
    
    return (
        <Button
            component={Link}
            to={createPath({ type: 'edit', resource, id: record.id }) + query}
            state={scrollStates[String(scrollToTop)]}
            label={label}
            onClick={stopPropagation}
            {...rest}
        >
            {icon}
        </Button>
    );
};