import React from "react"
import styled from "styled-components"
import { Button, Typography } from "@mui/material"
import { ChevronLeft } from "@material-ui/icons"

import { CheckCircleOutline } from "@material-ui/icons"
import { Link } from "react-router-dom"
import { useTranslate } from "react-admin"

const Title = styled(Typography)`
  line-height: 1.1;
  font-size: calc(1.25rem + 1vw);
  margin: 0.2rem 0px;
  font-weight: bold;
  color: rgb(33, 33, 51);
  margin-bottom: 30px;
`

const Description = styled(Typography)`
  line-height: 1.2;
  font-size: 1rem;
  font-weight: 400;
  margin: 0px;
  word-break: normal;
  color: rgb(96, 96, 119);
  text-align: start;
  margin-bottom: 10px;
`

const StyledCheckCircleOutline = styled(CheckCircleOutline)`
  color: rgb(47, 130, 86);
  font-size: 3rem;
  margin-bottom: 30px;
`

const PageContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`

const Back = styled(Button)`
  color: white;
  background: linear-gradient(160deg, hsl(222, 75%, 78%) 0%, hsl(232, 60%, 64%) 100%);
  box-shadow: 0 6px 10px -4px hsla(226, 65%, 70%, 0.75);
  border-radius: 8px;
  border: none !important;
  &.Mui-disabled {
    opacity: 0.7;
    color: white;
  }
`

const Confirm = () => {
  const translate = useTranslate()
  return (
    <PageContainer>
      <Title>{translate("customRoot.cashier.confirm_title")}</Title>
      <Description>{translate("customRoot.cashier.confirm_desc")}</Description>
      <StyledCheckCircleOutline />
      <Back
        css={`
          direction: ltr;
        `}
        variant='outlined'
        color='secondary'
        component={Link}
        to='/cashier'
      >
        <ChevronLeft /> {translate("customRoot.cashier.go_to_home")}{" "}
      </Back>
    </PageContainer>
  )
}

export default Confirm
