import React from "react"
import styled from "styled-components"
import { useTranslate } from "react-admin"
import { Typography } from "@mui/material"

const Total = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #999;
`

const Summary = styled(Typography)`
  line-height: 1.2;
  font-size: 1rem;
  font-weight: 600;
  color: rgb(96, 96, 119);
`

const TotalInfo = ({ sum }) => {
  const translate = useTranslate()

  return (
    <Total>
      <Summary>{translate("customRoot.cashier.total")}</Summary>
      <Summary>₪ <span style={{ direction: "ltr", display: "inline-block", textAlign: "right" }}>{sum.toFixed(2)}</span></Summary>
    </Total>
  )
}

export default TotalInfo
