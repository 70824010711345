import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { useTranslate, useDataProvider, UPDATE, DELETE } from "react-admin"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Paper, IconButton, OutlinedInput } from "@mui/material"
import { Check, Edit as EditIcon, Add as AddIco, Delete as DeleteIcon } from "@material-ui/icons"
import { useInput } from "react-admin"
import { observer, inject } from 'mobx-react'
import useFetch from "use-http"
import Spinner from "../common/Spinner"
import { RoutesEnum } from "../../core/restProvider"

const SPaper = styled(Paper)`
  padding: 1rem;
  padding-bottom: 3rem;
  width: 250px;
  height: calc(250px + 1.5rem);
  margin: 0.5rem;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ImageWrap = styled.div`
  width: 250px;
  height: 250px;
  flex: 0 0 250px;
  cursor: pointer;
`

const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const InputWrap = styled(OutlinedInput)`
  width: 250px;
`

const Image = ({ organizationId, systemId, name, Key, ra_props, ...props }) => {
  const ref = useRef()

  const [focus, setFocus] = useState(false)
  const [newName, setNewName] = useState(name)

  const onBlur = () => {
    setTimeout(() => {
      setFocus(false)
    }, 100)
  }

  const onChange = (e) => {    
    setNewName(e.target.value)
  }

  const onOk = async () => {
    props.renameImage(newName)
  }

  const deleteImage = () => {
    props.deleteImage()
  }

  const setF = () => ref.current.focus()

  return (
    <SPaper>
      <ImageWrap>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          {name}
          <IconButton>
            <DeleteIcon onClick={deleteImage} />
          </IconButton>
        </div>        
        <Img {...props} />
      </ImageWrap>
    </SPaper>
  )
}

const Loading = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80vw;
  min-height: 30vh;
`

const SDialog = styled(Dialog)`
  transition: all 300ms ease-in-out;
`

const ImagePicker = inject('authStore')(observer(({ authStore, open, onClose, ra_props, orgId, systemId, ...rest }) => {

  const { field: { onChange } } = useInput(rest)
  const [images, setImages] = useState([])
  const [ids, setIds] = useState(null)
  const restProvider = useDataProvider()
  
  const { id } = ra_props
  
  useEffect(() => {
    const resultStorageByResource = authStore.getResource(RoutesEnum.PRODUCTS)
    if (resultStorageByResource) {
      setIds(resultStorageByResource)
    }
  }, [])

  const { get, loading } = useFetch(`/api/v1/organizations/${orgId}/systems/${systemId}/upload/files`, { cachePolicy: "no-cache" })

  const getImages = () => {
    get().then(res => {
      setImages(res.data)
    })
  }

  useEffect(() => {
    if (open) getImages()
  }, [open])

  const renameImage = (image, newName) => {
    restProvider(UPDATE, RoutesEnum.FILES, {
      organizationId: image.organizationId,
      systemId: image.systemId,
      fileId: image.id,
      data: { name: newName }
    }).then((res) => {
      getImages()
    })
  }

  const deleteImage = (image) => {
    restProvider(DELETE, RoutesEnum.FILES, {
      organizationId: image.organizationId,
      systemId: image.systemId,
      fileId: image.id,
    }).then((res) => {
      getImages()
    })
  }

  const onClick = (src) => {
    onChange(src)
    onClose()
  }

  return (
    <SDialog open={open} onClose={onClose} maxWidth='xl'>
      <DialogTitle>אוסף התמונות שלי</DialogTitle>
      <DialogContent>
        <Gallery>
          {images.map((i) => (
            <Image 
              key={i.id}
              ra_props={ra_props} 
              onClick={() => onClick(i.src)} 
              renameImage={(name) => renameImage(i, name)}
              deleteImage={() => deleteImage(i)}
              {...i}  
            />
          ))}
        </Gallery>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>סגור</Button>
      </DialogActions>
    </SDialog>
  )
}))

export default ImagePicker
