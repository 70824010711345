// in src/Dashboard.js
import React from "react"
import { Card, CardContent } from "@mui/material"
import { Title, useTranslate } from "react-admin"
import styled from "styled-components"

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: left;
`

const Dashboard = () => {
  const translate = useTranslate();

  return (
    <Container>
      <Card>
        <Title title='greeting_title'/>
        <CardContent>{translate('greeting_subtitle')}</CardContent>
      </Card>
    </Container>
  );
};

export default Dashboard
