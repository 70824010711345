import React, { useState } from 'react'
import { useTranslate } from "react-admin"
import styled from 'styled-components'
import { Clear, ExpandMore, Search as SearchIcon } from '@mui/icons-material'
import { InputBase, MenuItem, Select } from '@mui/material'
import Surface from './Surface'
import theme from '../utils/theme'

const Search = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 2px 0;
  padding-left: unset;
  padding-right: 40px;
  
`

const SSearchIcon = styled(SearchIcon)`
  color: ${theme.color.icon};
  cursor: pointer;
  margin: 0 6px;
  transition: all .3s ease-in-out;
  transform: scaleX(${p => p.hassearch ? 0 : 1});
  position: absolute;
  right: 0;
`

const SClean = styled(Clear)`
  color: ${theme.color.icon};
  margin: 0 6px;
  transform: scaleX(${p => p.hassearch ? 1 : 0});
  transition: all .3s ease-in-out;
  position: absolute;
  left: unset;
  right: 0;
`

const SInput = styled(InputBase)`
  flex: 1 1 100%;
`

const SSelect = styled(Select)`
  flex: 0 1 auto;
  .MuiSelect-select {
    background: transparent;
    color: #555;
    padding-right: unset;
    padding-left: 36px; 
  }
  text-align: left;
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
  .MuiSelect-icon {
    top: calc(50% - 12px);
  }
`

const SExpand = styled(ExpandMore)`
  color: ${theme.color.icon};
  transition: all .3s ease-in-out;
`

const SearchInput = ({ value, category, categories, handleChange, handleFilter, setSearch, ...props }) => {
  const t = useTranslate()
  return (
    <Surface>
      <Search>
        <SClean onClick={() => setSearch('')} hassearch={!!value ? 1 : 0} />
        <SSearchIcon hassearch={!!value ? 1 : 0} />
        <SInput value={value} onChange={handleChange} placeholder={t`publicOrder.search_placeholder`} />
       
      </Search>
    </Surface>
  )
}

export default SearchInput