import { makeStyles } from "@material-ui/core"
import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react"
import { Edit, SimpleForm, useEditController,SelectInput, TextInput, BooleanInput, required, Toolbar, SaveButton, DeleteButton, usePermissions, useNotify, useTranslate } from "react-admin"
import { observer, inject } from 'mobx-react'
import useFetch from "use-http"
import styled from "styled-components"

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between"
  }
}))

const SimpleFormStyled = styled(SimpleForm)`
  @media (min-width: 800px) {
    width: 50%;
  }  

  width: 100%;
  .ra-input {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`
const OrgEdit = ({ authStore, ...props }) => {
  const { id } = useParams();
  const classes = useStyles()
  const translate = useTranslate()
  const navigate = useNavigate();
  const notify = useNotify()
  const [ slikaOrganizations, setSlikaOrganizations ] = useState([])
  const [ emptyValue, setEmptyValue] = useState('')

  const { get } = useFetch(`/api/v1/organizations/slika/organizations`, { cachePolicy: "no-cache" })

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/organizations');
  };

  const { record, isLoading } = useEditController({ resource: 'organizations', id });

  const getSlikaOrganizations = () => {
    get().then(res => {
      setSlikaOrganizations(res.data)
    })
  }

  useEffect(() => {
    getSlikaOrganizations()
  }, [])

  useEffect(() => {
    if (!isLoading && Array.isArray(slikaOrganizations)) {
     setEmptyValue(slikaOrganizations.map((i) => i.id).includes(record.slika_org_id) ? '' : record.slika_org_id);
    }
  }, [slikaOrganizations])

  const UserEditToolbar = (props) => (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton />

      {authStore.isDeleteOrgPermissions() && <DeleteButton />}
    </Toolbar>
  )

  if (isLoading) return null;
 
  return (
    <Edit {...props} mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <SimpleFormStyled toolbar={<UserEditToolbar />}>
        <TextInput source='id' label='customRoot.users.list.id' validate={required()} disabled />
        <SelectInput
            source='slika_org_id'
            optionText='id'
            optionValue='id'
            label='customRoot.organizations.list.slika_org_id'
            emptyText={emptyValue}
            emptyValue={emptyValue}
            choices={slikaOrganizations}
          />

        <TextInput source='name' label='customRoot.organizations.list.org_name' validate={required()} />
        <TextInput source='displayName' label='customRoot.organizations.list.display_name' validate={required()} />
        <BooleanInput label='customRoot.organizations.list.active' source='active' />
      </SimpleFormStyled>
    </Edit>
  )
}
export default inject('authStore')(observer(OrgEdit))
