import { makeStyles } from "@mui/styles"
import styled from "styled-components"
import { useParams, useNavigate } from "react-router-dom";
import React from "react"
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  usePermissions,
  Toolbar,
  SaveButton,
  DeleteButton,
  SelectInput,
  email,
  useTranslate,
  FormDataConsumer,
  useNotify
} from "react-admin"
import { observer, inject } from 'mobx-react'

const SimpleFormStyled = styled(SimpleForm)`
  @media (min-width: 800px) {
    width: 50%;
  }  

  width: 100%;
  .ra-input {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "256px"
  }
}))

const UserEdit = ({ authStore, ...props }) => {
  const { id } = useParams();
  const classes = useStyles()
  const validateRequired = [required(), email()]
  const user = authStore.getUser()
  const translate = useTranslate()
  const navigate = useNavigate();
  const notify = useNotify()

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/users');
  };

  const onError = (error) => {
    if(error.status == 400) {
      notify(translate(`ra.message.${error.body.error}`), { type: 'error' });   
    } else {
      notify(translate(`ra.message.error`), { type: 'error' });
    }
  };

  const roles = {
    admin: translate("customRoot.roles.super admin"),
    org_manager: translate("customRoot.roles.organization manager"),
    pos_manager: translate("customRoot.roles.pos manager"),
    operator: translate("customRoot.roles.operator")
  }

  const isDisabled = (formData) => {
    if (!('id' in user)) {
      return false
    }

    return id === user.id || formData.roles[0] === user.roles[0]
  }

  const UserEditToolbar = (props) => (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton />

      {authStore.isDeleteUserPermissions() && <DeleteButton />}
    </Toolbar>
  )
  return (
    <Edit {...props} mutationOptions={{ onSuccess, onError }} mutationMode="pessimistic">
      <SimpleFormStyled toolbar={<UserEditToolbar />}>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
                <TextInput source='id' label='customRoot.users.list.id' disabled />
                <TextInput source='firstName' label='customRoot.users.create.first_name' validate={required()} />
                <TextInput source='lastName' label='customRoot.users.create.last_name' validate={required()} />
                <TextInput source='username' label='ra.auth.username' />
                <TextInput source='email' label='customRoot.users.list.email' validate={validateRequired} />
                <TextInput source='phoneNumber' label='customRoot.users.list.phone' validate={required()} />

                <SelectInput
                  disabled={isDisabled(formData)}
                  validate={required()}
                  label='customRoot.users.list.role'
                  source='roles'
                  choices={authStore.permissionUpdateRoles(roles)}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off"
                    }
                  }}
                />
              </>
            )
          }}
        </FormDataConsumer>
      </SimpleFormStyled>
    </Edit>
  )
}
export default inject('authStore')(observer(UserEdit))
