import React, { useState, Fragment } from "react"
import {
  List,
  Datagrid,
  TextField,
  useListController,
  TopToolbar,
  sanitizeListRestProps,
  Filter,
  ReferenceInput,
  TextInput,
  DateField,
  AutocompleteInput,
  useTranslate,
  FunctionField,
  DateInput,
  usePermissions, 
  Pagination, 
  SearchInput
} from "react-admin"
import styled from "styled-components"
import { format, subWeeks } from "date-fns"
import ItemList from "./itemList"
import OrderModal from "./orderModal"
import ExportToSystemAdmin from "./exportSystemAdmin"
import ExportPaymentWithCreditCard from "./reports/ExportPaymentWithCreditCard"
import ExportToFinancialSystem from "./reports/ExportToFinancialSystem"
import { getExporter } from "../../tools/utils"
import { observer, inject } from 'mobx-react'
import UpdateStatusButton from "./updateStatus"
import FieldTitle from "../common/FieldTitle"
import { AutocompleteInputStyled, TextInputStyled, DateInputStyled } from "../common/InputFields"

const Container = styled.div`
  .column-order_number {
    width: 100px;
  }
`

const PostPanel = (props) => <ItemList {...props} label='customRoot.orders.list.items' sortable={false} />

const ListActions = inject('authStore')(observer(({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  authStore,
  ...rest
}) => {
  return (
    <TopToolbar className={className} style={{ height: '100%', display: 'flex', alignItems: 'flex-start' }} {...sanitizeListRestProps(rest)}>
      {!authStore.isOperator() && authStore.isDownloadOrderPermissions() && <ExportToSystemAdmin filterValues={filterValues} currentSort={currentSort} />}
      {!authStore.isOperator() && authStore.isDownloadOrderPermissions() && <ExportPaymentWithCreditCard />}
      {!authStore.isOperator() && authStore.isDownloadOrderPermissions() && <ExportToFinancialSystem />}
    </TopToolbar>
  )
}))

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null
}

const datesDefault = {
  to: format(new Date(), "yyyy-MM-dd"),
  from: format(subWeeks(new Date(), 1), "yyyy-MM-dd")
}

const OrdersFilter = inject('authStore')(observer(({ authStore, ...props }) => {
  const { filterValues } = props.cprops

  const secondFilter = filterValues ? { filter: { org_id: filterValues.org_id } } : {}

  const isAdmin = authStore.isSuperAdmin()
  const isManager = authStore.isOrgManager()
  const t = useTranslate()

  return (
    <Filter {...props}>
      {(isAdmin || isManager) && (
        <ReferenceInput
          label='resources.organizations.singular'
          reference='organizations'
          source='org_id'
          alwaysOn
          perPage={1000}
          sort={{ field: "name", order: "ASC" }}
       > 
          <AutocompleteInputStyled
           className="autocompeleteMekome"
           label='resources.organizations.singular'
           matchSuggestion={(filter, choice) => choice.name.match(filter)}
           optionText="name" 
           //sx={{ height: '20px' }}
           optionValue="id"/>
        </ReferenceInput>
      )}

      {(isAdmin || isManager) && (
        <ReferenceInput
          label='customRoot.organizations.list.system'
          reference='systems'
          source='system_id'
          alwaysOn
          {...secondFilter}
          perPage={1000}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInputStyled
           label='customRoot.organizations.list.system'
           className="autocompeleteMekome"
           matchSuggestion={(filter, choice) => choice.name.match(filter)}
           optionText="name" 
           optionValue="id"/>
        </ReferenceInput>
      )}

      <AutocompleteInputStyled
        source='type' 
        className={`autocompeleteMekome2 autocomplete-padding`}
        optionText='name' 
        optionValue='id' 
        label='customRoot.organizations.list.order_type'
        choices={[{id: '', name: ''},{id: 'internal', name: t('internal')}, {id: 'external', name: t('external')}]} 
        alwaysOn
      />
      <TextInputStyled source='name' alwaysOn   />
      <DateInputStyled source='from' alwaysOn />
      <DateInputStyled source='to' alwaysOn  />
    </Filter>
  )
}))

const ffformat = {
  hour12: false,
  hour: "2-digit",
  minute: "2-digit",
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric"
}

const PostBulkActionButtons = (props) => (
  <Fragment>
    <UpdateStatusButton {...props} />
  </Fragment>
)

const StickyDataGrid = styled(Datagrid)`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 82vh;
  
  thead {
    flex: 0 0 auto;
  }
  tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }

  tr {
    width: 100%;
    display: table;
    table-layout: fixed;
  }

  .MuiTableCell-paddingCheckbox {
    padding: 0;
    width: 4%;
  }
`

const OrderList = ({ authStore, ...props }) => {
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState(false)
  const controllerProps = useListController(props)
  const translate = useTranslate()

  const { filterValues } = controllerProps

  const postRowClick = (id, basePath, record) => {
    setModalData(record)
    setOpen(true)
  };

  return (
    <Container>
      <List
        {...props}
        filters={<OrdersFilter cprops={controllerProps} {...props} />}
        perPage={50}
        bulkActionButtons={<PostBulkActionButtons orders={controllerProps.data} />}
        actions={<ListActions maxResults={100000} filterValues={filterValues}/>}
        filterDefaultValues={datesDefault}
        empty={false}
        exporter={getExporter()}
        pagination={<Pagination rowsPerPageOptions={[50, 100, 200]} />}
      >
        <StickyDataGrid expand={<PostPanel {...props} />} rowClick={postRowClick} isRowSelectable={ record => ['received', 'cancelled', 'external'].includes(record.status) || (record.status == 'done' && record.refundedOrder == null) }>
          <TextField sortable={false} source='orderNumber' label={<FieldTitle label='customRoot.orders.list.order_number' />} />
          <FunctionField sortable={false} render={(record) => record.seller ? `${record.seller.fname} ${record.seller.lname}` : ''} label={<FieldTitle label='customRoot.myMenu.seller' />} />
          <DateField sortable={false} source='createdAt'  label={<FieldTitle label='customRoot.orders.list.createdAt' />} showTime options={ffformat} locales={'he'} />
          <TextField sortable={false} source='customer.budgetNumber' label={<FieldTitle label='customRoot.orders.list.budget_number' />} />
          <TextField sortable={false} source='customer.socialId' label={<FieldTitle label='customRoot.orders.list.social_id' />} />
          <FunctionField sortable={false} render={(record) => `${record.customer.firstName} ${record.customer.lastName}`} label={<FieldTitle label='customRoot.orders.list.customer_name' />} />
          <FunctionField sortable={false} render={(record) => translate(`customRoot.orders.status.${record.status}`)} label={<FieldTitle label='customRoot.orders.list.status' />} />
        </StickyDataGrid>
      </List>
      {modalData && <OrderModal open={open} setOpen={setOpen} record={modalData} />}
    </Container>
  )
}

export default inject('authStore')(observer(OrderList))
