import { useNavigate } from "react-router-dom"
import { useTranslate } from "react-admin"
import { observer, inject } from 'mobx-react'
import React from "react"
import styled from "styled-components"
import { Button } from "@mui/material"

const StyledFormContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CreateExternalCustomerButton = ({ authStore, orgId, systemId, navigationUrl, redirect, ...props }) => {
  const translate = useTranslate();
  const navigate = useNavigate();

  const { style } = props;

  const redirectToCustomerCreate = () => {
    authStore.setResource('customers', { org_id: orgId, system_id: systemId })
    navigate(navigationUrl, { state: { org_id: orgId, system_id: systemId, is_create_external: true, redirect } })
  }

  return (
    <StyledFormContainer style={style}>
      <Button
        variant="outlined"
        onClick={redirectToCustomerCreate}
      >
        {translate('ra.action.add_external_user')}
      </Button>
    </StyledFormContainer>
  )
}

export default inject('authStore')(observer(CreateExternalCustomerButton))
