import React from 'react';
import { useTranslate } from 'react-admin';
import styled from 'styled-components';
import { Typography, Card, Button } from '@mui/material';
import { Done } from '@material-ui/icons';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const StyledCard = styled(Card)`
  width: 70%;
  margin: 50px auto 0;
  padding: 5%
`

const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 35px;
  text-align: center;
`
const StyledIcon = styled(Typography)`
  text-align: center;
  color: rgb(0,128,0);
`

const StyledContainerOrderInfo = styled.div`
  display: flex;
  justify-content: center;
`

const ReturnButton = styled(Button)`
  max-width: 200px;
  color: white;
  background: linear-gradient(160deg, rgb(130, 217, 140) 0%, rgb(38, 151, 40) 100%);
  box-shadow: rgb(38 151 40 / 75%) 0px 6px 10px -4px;
  border-radius: 8px;
  border: none !important;
  display: flex !important;
  width: 60%;
  margin: 50px auto 0 !important;
`

const ChargePublicSuccess = () => {
  const translate = useTranslate();

  return (
    <>
      <StyledCard>
        <StyledContainerOrderInfo>
          <StyledTitle>{translate('customRoot.cashier.charging_success')}</StyledTitle>
        </StyledContainerOrderInfo>
        <StyledIcon>
          <Done fontSize="large"/>
        </StyledIcon>
      </StyledCard>
    </>
  )
}

export default ChargePublicSuccess;
