import React from 'react'
import styled from 'styled-components'

const StyledSpace = styled.div`
  height: ${p => `${p.height}rem`};
  width: ${p => `${p.width}rem`};
  flex: ${p => (p.grow ? 1 : 0)} 0 auto;
`

const Space = ({ width, height = 1, grow = false, ...props }) => <StyledSpace width={width} height={height} grow={grow} {...props} />

export default Space