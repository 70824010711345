import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslate } from 'react-admin'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Check } from "@material-ui/icons"
import { Button as MUIButton, Snackbar } from '@mui/material'
import { Input, Text, Space, ChevronNext, Col, Button, Page, Alert } from '../components'
import { isMobile } from '../utils/helpers'
import theme from '../utils/theme'
import { DesktopCart } from '../cart'
import storage from '../utils/storage'


const CPage = styled(Page)`
   padding-top: 20px;
  .MuiFormControlLabel-root {
    margin-inline-start: -11px;
    margin-inline-end: 16px;
  }
`
const Cart = styled(DesktopCart)`
  margin-top: 78px;
`
const ConfirmButton = styled(Button)`
  width: 100%;
  min-width: calc(30% - 10px);
  color: white!important;
  background: linear-gradient(160deg, rgb(130, 217, 140) 0%, rgb(38, 151, 40) 100%);
  box-shadow: rgb(38 151 40 / 75%) 0px 6px 10px -4px;
  border-radius: 8px!important;
  border: none !important;
  padding: 6px 16px!important;
`
const PaymentButton = styled(Button)`
  width: 100%;
  min-width: calc(30% - 10px);
  color: white!important;
  background: linear-gradient(160deg, rgb(32, 149, 173) 0%, rgb(32, 149, 173) 100%);
  box-shadow: rgb(38 151 40 / 75%) 0px 6px 10px -4px;
  border-radius: 8px!important;
  border: none !important!important;
  margin-right: 10px!important;
  padding: 6px 16px!important;
`

function validateEmail(email) {
  if (email === '') return [true, '']
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return [re.test(String(email).toLowerCase()), 'ra.validation.email']
}

function validateBudgetNumber(data) {
  return [/^\d+$/.test(String(data)), 'ra.validation.number']
}

function validatePhoneNumber(data) {
  return [/^0\d{9}$/.test(String(data)), 'ra.validation.number']
}

const isNotEmpty = input => {
  const inputString = input
  const regex = new RegExp(/.+/)
  if (!inputString) return [false, 'publicOrder.required']
  return [regex.test(inputString), 'publicOrder.required']
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
const ContainerButtons = styled.div`
  display: flex;
  margin-bottom: 50px;
`

function getValidation(fieldType, isRequired) {
  const v = []

  if (isRequired) v.push(isNotEmpty)
  if (fieldType === 'email') v.push(validateEmail)
  if (fieldType === 'budgetNumber') v.push(validateBudgetNumber)
  if (fieldType === 'phone') v.push(validatePhoneNumber)
  return v
}

const Field = inject('cartStore')(observer(function ({ type, id, cartStore, setInputError }) {
  const t = useTranslate()
  const isRequired = type == 'required'
  const [ value, setValue ] = useState('')
  const [ error, setError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')

  const validate = (value) => {
    const validationArray = getValidation(id, isRequired)
    const result = validationArray.reduce((acc, isValid) => {
      if (!acc[0])  {
        return acc
      }

      return isValid(value)
    }, [true, ''])

    setError(!result[0])   
    setErrorMessage(result[1])
    setInputError(!result[0])
  }

  return <Input 
    key={`${id}_input`} 
    label={t(`customRoot.users.list.${id}`)}
    placeholder={id == 'phone'? "0540000000" : ''}
    helperText={error ? t(errorMessage) : t('publicOrder.required')}
    error={error}
    type={'text'}
    value={value}
    onFocus={e => {
      cartStore.changeCustomerDetail(id, e.target.value)
      setValue(e.target.value)
      validate(e.target.value)
    }}
    onChange={(e) => {
      cartStore.changeCustomerDetail(id, e.target.value)
      setValue(e.target.value)
      validate(e.target.value)
  }} />
}))

const Checkout = ({ cartStore, customerStore }) => {
  const t = useTranslate()
  const navigate = useNavigate();
  const params = useParams()
  const [ snackbar, setSnackbar ] = useState({})
  const [ isOpenSnackbar, setOpenSnackbar ] = useState(false)
  const [ inputErrors, setInputErrors ] = useState({})
  const [ disabledBtn, setDisabledBtn ] = useState(false)

  const handleBack = () => navigate(-1)

  const openSnackbar = (message, severity) => {
    setOpenSnackbar(true);
    setSnackbar({ message: message, severity: severity })
  }

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  }

  const gotoCharge = async () => {  
    const orderId = await cartStore.addOrder(params.id)
    
    if (orderId !== null) {
      cartStore.clear()
      navigate(`/order/${orderId}/charge`)
    } else {
      openSnackbar(t('requestResult.error'), 'error')
    }
  }

  const createOrder = async () => {  
    const order = await cartStore.createOrder(params.id)
    
    if (order !== null) {
      cartStore.clear()
      navigate(`/order/${order.hashUrl}/success`)
    } else {
      openSnackbar(t('requestResult.error'), 'error')
    }
  }

  useEffect(() => {
    customerStore.getSettings(params.id).then(res => {
      const nextInputErrors = Object.keys(customerStore.items).reduce((acc, key) => {
        acc[key] = true
        return acc
      }, {})

      const countOfErrors = Object.keys(nextInputErrors).filter(key => nextInputErrors[key]) 
  
      setInputErrors(nextInputErrors)
      setDisabledBtn(countOfErrors.length > 0)
    })
    
  }, [])

  if (cartStore.isEmpty) return navigate(`/order/${params.id}`)

  return (
    <CPage>
      <Col>
        <Header>
          <Text.H5 css="margin-bottom: 1rem;">{t('publicOrder.checkout_title')}</Text.H5>
          <MUIButton onClick={handleBack} css={`color: ${theme.color.blue}`}>{t('publicOrder.back')} <ChevronNext /></MUIButton>
        </Header>

        {Object.entries(customerStore.items).map(([key, type], index) => (
          <Field 
            key={`${key}_field`} 
            id={key} 
            type={type} 
            setInputError={(error) => {     
              const nextInputErrors = {...inputErrors }
              nextInputErrors[key] = error

              const countOfErrors = Object.keys(nextInputErrors).filter(key => nextInputErrors[key]) 
              setInputErrors(nextInputErrors)
              setDisabledBtn(countOfErrors.length > 0)
            }} 
          />
        ))}

        <Space height={2} />
        <Space grow />
        <ContainerButtons>
          {customerStore.hasBudgetNumber && 
            (<ConfirmButton
              disabled={disabledBtn} 
              variant='contained' 
              fullWidth 
              onClick={createOrder}
            >
              <Check /> {t("customRoot.cashier.place_order")}
            </ConfirmButton>
           )}
          {customerStore.allowPayWithCC
            && (
            <PaymentButton 
              disabled={disabledBtn} 
              variant='contained' 
              fullWidth 
              onClick={gotoCharge}
            >
              <ArrowBackIcon /> {t('publicOrder.payment_order')}
            </PaymentButton>
            )
          }
        </ContainerButtons>
      </Col>

      {!isMobile && (
        <Col>
          <Cart withoutButton />
        </Col>
      )}

      <Snackbar
        open={isOpenSnackbar}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </CPage>
  )
}

export default inject('cartStore', 'customerStore')(observer(Checkout))