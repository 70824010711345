import React from "react"
import styled from "styled-components"

import { Clear, ExpandMore, Search as SearchIcon } from "@material-ui/icons"
import { InputBase, MenuItem, Select } from "@mui/material"
import { useTranslate } from "react-admin"

const Search = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 2px 40px 2px 10px;
  border-radius: 8px;
  box-shadow: rgb(183 183 239) 4px 4px 6px 1px, rgb(255 255 255) -4px -4px 6px 1px;
`

const SSearchIcon = styled(SearchIcon)`
  margin: 0 6px;
  transition: all 0.3s ease-in-out;
  transform: scaleX(${(p) => (p.hassearch ? 0 : 1)});
  position: absolute;
  right: 0;
`

const SClean = styled(Clear)`
  margin: 0 6px;
  transform: scaleX(${(p) => (p.hassearch ? 1 : 0)});
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 0;
`

const SInput = styled(InputBase)`
  flex: 1 1 100%;
`

const SSelect = styled(Select)`
  flex: 0 1 auto;
  .MuiSelect-select {
    background: transparent;
    color: #555;
  }
  text-align: left;
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
  .MuiSelect-icon {
    top: calc(50% - 12px);
  }
`

const SExpand = styled(ExpandMore)`
  transition: all 0.3s ease-in-out;
`

const SearchInput = ({ value, category, categories, handleChange, handleFilter, setSearch, onFocus, clear }) => {
  const translate = useTranslate()
  return (
    <Search>
      <SSearchIcon hassearch={!!value ? 1 : 0} />
      <SClean onClick={clear} hassearch={!!value ? 1 : 0} />
      <SInput value={value} onFocus={onFocus} onChange={handleChange} placeholder={translate("customRoot.cashier.search_placeholder")} />
      {categories && (
        <SSelect value={category} className="itemCategoriesMekome" onChange={handleFilter} IconComponent={SExpand}>
          <MenuItem value='all'>{translate("customRoot.cashier.all_categories")}</MenuItem>
          {categories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </SSelect>
      )}
    </Search>
  )
}

export default SearchInput
