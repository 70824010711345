import React from 'react'
import styled from 'styled-components'
import { useTranslate } from "react-admin"


const Field = styled.div`
  font-weight: bold;
  font-size: 16px;
`

const FieldTitle = ({ label, ...resProps }) => {
    const t = useTranslate()
    return (<Field {...resProps}>{t(label)}</Field>)
}

export default FieldTitle

