import React from "react"
import styled from "styled-components"
import { ShoppingCart } from "@material-ui/icons"
import { useNavigate } from "react-router-dom"
import { observer, inject } from 'mobx-react'
import { useTranslate } from "react-admin"
import { Button } from "@mui/material"

const StyledContainer = styled.div``

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  color: white;
  background: linear-gradient(160deg, rgb(130, 217, 140) 0%, rgb(38, 151, 40) 100%);
  box-shadow: rgb(38 151 40 / 75%) 0px 6px 10px -4px;
  border-radius: 8px;
  border: none !important;
  height: 60px;
`

const CheckoutButton = inject('authStore')(observer(({ authStore, data, disabled }) => {
  const translate = useTranslate()
  const navigate = useNavigate()
  const handleCheckout = () => {
    localStorage.setItem("cashier", JSON.stringify({ ...data }))
    authStore.setResource("systems", { org_id: data.orgId, system_id: data.systemId })
    navigate(`/checkout?org_id=${data.orgId}&system_id=${data.systemId}`, {
      state: { ...data }
    })
  }

  return (
    <StyledContainer>
      <StyledButton fullWidth disabled={disabled} onClick={handleCheckout}>
        <ShoppingCart /> {translate("customRoot.cashier.checkout")}
      </StyledButton>
    </StyledContainer>
  )
}))

export default CheckoutButton
