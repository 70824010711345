import React from "react"
import styled from "styled-components"
import { List, ListItem, Typography } from "@mui/material"
import { Clear } from "@material-ui/icons"

import { useTranslate } from "react-admin"
import { round } from "../../tools/utils"

const Item = styled(ListItem)`
  padding: 0.7rem;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Sum = styled(Typography)`
  color: grey;
  white-space: nowrap;
  margin: 0 0.5rem;
  direction: ltr;
`

const StyledList = styled(List)`
  flex: 1;
  max-height: 70vh;
  padding-bottom: 100px;
`

const Empty = styled(Typography)`
  line-height: 1.2;
  font-size: 1rem;
  font-weight: 400;
  margin: 0px;
  word-break: normal;
  color: rgb(96, 96, 119);
  font-family: Heebo, system-ui, sans-serif;
`

const CartList = ({ list, handleRemove, isCancel }) => {
  const translate = useTranslate()

  return (
    <StyledList>
      {list.map(({ id, name, measure = "unit", quantity, price, listId }) => (
        <Item key={id + listId}>
          <Typography>{name}</Typography>
          <>
            <Sum thin>
              {quantity} {translate(`customRoot.cashier.measure_${measure}`)}
            </Sum>
            {!!price && (
              <Typography
                css={`
                  text-align: right;
                  direction: ltr;
                  white-space: nowrap;
                `}
              >
                ₪ <span style={{ direction: "ltr", display: "inline-block", textAlign: "right" }}>{round(quantity * price).toFixed(2)}</span>
              </Typography>
            )}
          </>
          {isCancel && <Clear color='#f50057' onClick={() => handleRemove(listId)} role='button' name={`${translate("customRoot.cashier.remove")} ${name}`} />}
        </Item>
      ))}

      {list.length === 0 && (
        <ListItem>
          <Empty>{translate("customRoot.cashier.cart_is_empty")}</Empty>
        </ListItem>
      )}
    </StyledList>
  )
}

export default CartList
