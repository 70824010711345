import { useState } from 'react'

export function debounce(fn, delay) {
  const ms = parseInt(delay, 10)
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => fn(...args), isNaN(ms) ? 1000 : ms)
  }
}

export function throttle(delay, fn) {
  const ms = parseInt(delay, 10)
  let timer = null
  return (...args) => {
    if (!timer) {
      fn(...args)
      timer = setTimeout(() => timer = null, isNaN(ms) ? 1000 : ms)
    }
  }
}

export const toString = query => Object.keys(query).reduce((str, curr) => `${str}${curr}=${query[curr]}&`, '?')

export function setManifest(selector, query) {
  var myDynamicManifest = {
    "name": "Mekome Takeaway",
    "short_name": "Takeaway",
    "description": "Orders for you ",
    "start_url": `https://delivery.mekome.net/?org_id=${query.org_id}`,
    "display": "standalone",
    "background_color": "#F6F6FD",
    "theme_color": "#F6F6FD",
    "icons": [{
      "src": "icon.png",
      "sizes": "any",
      "type": "image/png"
    }]
  }
  
  const stringManifest = JSON.stringify(myDynamicManifest);
  const blob = new Blob([stringManifest], {type: 'application/json'});
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector(selector).setAttribute('href', manifestURL);
}


export const useExtendedState = (initialState) => {
  const [state, setState] = useState(initialState)
  const getLatestState = () => {
    return new Promise((resolve, reject) => {
      setState((s) => {
        resolve(s);
        return s;
      });
    });
  };
  return [state, setState, getLatestState]
}

export const sortABC = (a, b) => a.localeCompare(b, undefined, { numeric: true })
