import {  makeObservable, observable, action, computed, toJS, reaction } from 'mobx'
import fetcher, { endpoints } from '../utils/fetcher'
import storage from '../utils/storage'
import { delay } from '../utils/helpers'


class CustomerStore {
  _items = {}
  loading = false
  allowPayWithCC = false
  hasBudgetNumber = false
  
  constructor() {
    makeObservable(this, {
      _items: observable,
      loading: observable,
      allowPayWithCC: observable,
      hasBudgetNumber: observable,
      items: computed,
      getSettings: action,
      charge: action,
      setLoading: action,
      clear: action,
    })
  }
  
  get items() {
    return toJS(this._items) || {}
  }

  clear = () => {
    this._items = {}
    this.allowPayWithCC = false
    this.hasBudgetNumber = false
  }

  setLoading = (bool) => {
    this.loading = bool
  }

  getOrder = async (hash) => {
    const res = await fetcher.get(endpoints.getOrder(hash))
    const data = res.data.data

    if (!data.settings.isActive) {
      throw new Error('Charge Error')
    }

    return data
  }

  charge = async (orderId, amount, paymentToken) => {
    const res = await fetcher.post(endpoints.chargeOrder(orderId), {
      amount, 
      paymentToken
    })

    storage.remove('order_')
    return res.data
  }
  
  getSettings = async (hash) => {
    this.loading = true

    await delay(300)

    try {
      const res = await fetcher.get(endpoints.settings(hash))
      this.allowPayWithCC = res.data.data.allowPayWithCC
      this._items = Object.entries(res.data.data.customer).reduce((acc, [key, type]) => {
            
        if (type !== 'hidden') {
          acc[key] = type        
        }

        if (key == 'budgetNumber' && type == 'required') {
          this.hasBudgetNumber = true
        }

        return acc
      }, {})
       
      this.loading = false
    } catch (e) {
      this.loading = false
    }
  }
}

const customerStore = new CustomerStore()
export default customerStore