import React from "react"
import styled from "styled-components"
import { TextInput, AutocompleteInput, DateInput, SearchInput } from "react-admin"

export const AutocompleteInputStyled  = styled(AutocompleteInput)`
  @media (min-width: 800px) {
    min-width: 300px;
  }  

  min-width: 160px;
`
export const TextInputStyled  = styled(SearchInput)`
  @media (min-width: 800px) {
    min-width: 300px;
  }  

  min-width: 160px;
`
export const DateInputStyled = styled(DateInput)`
  @media (min-width: 800px) {
    min-width: 300px;
  }  

  min-width: 160px;
`