import React from 'react'
import styled from 'styled-components'
import theme from '../utils/theme'

const SSurface = styled.div`
  border-radius: 8px;
  box-shadow:
  4px 4px 6px 1px ${theme.color.darker},
  -4px -4px 6px 1px ${theme.color.lighter};
`


export default SSurface