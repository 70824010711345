import React from 'react'
import styled from 'styled-components'

import { Grid } from '@mui/material'
import Blockquote from './Blockquote'

const StyledForm = styled(Grid)`
  margin: 1rem 0 2rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
   margin-bottom: 0;
  }
  .button {
    margin: 0.5rem 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Error = styled(Blockquote)`
  margin-top: 1rem;
  color: ${p => p.theme.color.red};
`

const StyledItem = styled(props => <Grid item {...props} />)`
  display: flex;
  flex-direction: column;
`

const Form = ({ sm=12, md=7, children, error='', ...props }) => (
  <StyledForm {...props} >
    <StyledItem item sm={sm} md={md}>{children}</StyledItem>
    {error && <Error error={!!error}>{error}</Error>}
  </StyledForm>
)

export default Form