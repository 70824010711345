import { useState, useEffect } from 'react'

export const useScript = url => {
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    const script = document.createElement('script')
    document.body.appendChild(script)
    script.onload = () => setIsLoaded(true)
    script.src = url;

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
  return isLoaded
}
