import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Dialog, DialogTitle, DialogContent, Select, DialogActions, MenuItem, Button, DialogContentText, TextField } from "@mui/material"
import { Add as AddIcon, Clear } from "@material-ui/icons"

import { useTranslate } from "react-admin"
import { Grid } from "@material-ui/core"

const StyledSelect = styled(Select)`
  width: 100%;
  padding: 0 0.5rem;
`

const DialogContainer = styled(DialogContent)`
  min-width: 20vw;
  @media (max-width: 700px) {
    min-width: 70vw;
  }
`

const AddButton = styled(Button)`
  color: rgb(47, 130, 86);
`

const CancelButton = styled(Button)`
  color: rgb(193, 54, 54);
`

const StyledTextField = styled(TextField)`
  width: 100%;
  margin-top: 20px;
`

const OrderModal = ({ id, name, measure, price, category, onClose, onConfirm, ...props }) => {
  const translate = useTranslate()
  const range = measure === "kg" ? [...Array(20).keys()].map((key) => ((key + 1) * 0.1).toFixed(3)) : [...Array(20).keys()].map((key) => key + 1)

  const initialState = {
    quantity: range[0],
    value: "",
    quantityType: "count"
  }

  const [quantity, setQuantity] = useState(initialState.quantity)
  const [value, setValue] = useState(initialState.value)
  const [quantityType, setQuantityType] = useState(initialState.quantityType)

  const handleSelectQuantityType = (e)  => {
    setQuantityType(e.target.value)

    if (e.target.value == 'weight') {
      setQuantity('1.250')
    }
    if (e.target.value == 'count') {
      setQuantity(range[0])
    }
  }

  const resetState = () => {
    setQuantity(initialState.quantity)
    setValue(initialState.value)
    setQuantityType(initialState.quantityType)
  }

  const handleClose = () => {
    onClose()
  }

  const handleSelectWeight = (e) => {
    const value = e.target.value

    if (value == '') {
      setQuantity('')
    }

    if (/^\d+(\.\d{1,3})?$/g.test(value)) {
      setQuantity(value)
    }

    if (/^\.\d{1,3}$/g.test(value)) {
      setQuantity('0' + value)
    }

    if (/^\d+\.$/g.test(value)) {
      setQuantity(value.trim('.'))
    }
  }

  const handleSelect = (e) => setQuantity(e.target.value)
  
  const handleConfirm = () => {
    const orderDetail = { id, name, price, quantity, category, remarks: value }
    onConfirm(orderDetail)
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    if (props.open) {
      resetState()
    }
  }, [props.open])

  return (
    <Dialog {...props} maxWidth='md' dir='rtl'>
      <DialogTitle>
      <DialogContentText>{name}</DialogContentText>
        
      </DialogTitle>
      <DialogContainer dividers>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledSelect value={quantityType} onChange={handleSelectQuantityType}>
              <MenuItem key={'count'} value={'count'}>
                {translate("customRoot.cashier.how_many")}
              </MenuItem>
              <MenuItem key={'weight'} value={'weight'}>
                {translate("customRoot.cashier.weight")}
              </MenuItem>
            </StyledSelect>
          </Grid>  
          <Grid item xs={6}>
            {quantityType == 'count' && (
              <StyledSelect value={quantity} onChange={handleSelect}>
                {range.map((unit) => (
                  <MenuItem key={unit} value={unit}>{unit}</MenuItem>
                ))}
              </StyledSelect>
            )}

            {quantityType == 'weight' && (
              <TextField
                placeholder={1.500}
                id='outlined-multiline-static'
                variant='outlined'
                value={quantity}
                onChange={handleSelectWeight}
              />
            )}
          </Grid>
       </Grid>
        <StyledTextField
          label={translate("customRoot.cashier.item_remarks")}
          placeholder={translate("customRoot.cashier.item_remarks_placeholder")}
          multiline
          id='outlined-multiline-static'
          rows={4}
          variant='outlined'
          value={value}
          onChange={handleChange}
        />
      </DialogContainer>
      <DialogActions>
        <CancelButton onClick={handleClose}>
          <Clear />
          {translate("customRoot.cashier.cancel")}
        </CancelButton>
        <AddButton onClick={handleConfirm} disabled={!quantity}>
          <AddIcon />
          {translate("customRoot.cashier.add_to_cart")}
        </AddButton>
      </DialogActions>
    </Dialog>
  )
}

export default OrderModal
