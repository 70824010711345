import authProvider from './authProvider'

const baseUrl = process.env.REACT_APP_API
const options = {
    headers: new Headers({
      "Content-Type": "application/json"
    })
}

export default {
    payWithSms: async (code, amount, paymentToken, paymentMethodId, saveCardDetails) => {
      const response = await fetch(`${baseUrl}/api/v1/pay-with-sms/charge/${code}`, {
        method: 'POST',
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({ amount, paymentToken, paymentMethodId, saveCardDetails })  
      })

      return response.json()
    }, 
    getOrderIonfo: async (code) => {
      const response = await fetch(`${baseUrl}/api/v1/pay-with-sms/order/info/${code}`, options)
      return response.json()
    },
    
    getReceiptIonfo: async (code) => {
      const response = await fetch(`${baseUrl}/api/v1/order/${code}`, options)
      return response.json()
    },
    
}