import React from "react"
import { 
  List, 
  Datagrid, 
  TextField,
  usePermissions, 
  DateField, 
  BooleanField, 
  ExportButton, 
  useListContext, 
  useTranslate, 
  TopToolbar, 
  Link, 
  EditButton 
} from "react-admin"
import styled from "styled-components"
import PostFilter from "./postFilter"
import { Button } from "@mui/material"
import { observer, inject } from 'mobx-react'
import { Add } from "@material-ui/icons"
import ActionToolbar from "../common/ActionToolbar"
import FieldTitle from "../common/FieldTitle"

const CButton = styled(Button)`
  color: #2196f3;
  padding: 2px;
`

const Actions = inject('authStore')(observer(({ authStore,...props }) => {
  const { maxResults } = props

  const { currentSort, resource, filterValues, total } = useListContext()

  const { org_id, system_id } = filterValues

  const translate = useTranslate()

  return (
    <TopToolbar>
      {authStore.isCreateOrgPermissions() && (
        <CButton
          component={Link}
          to={{
            pathname: "/organizations/create",
            state: {
              org_id,
              system_id
            }
          }}
        >
          <Add />
          {translate("ra.action.create")}
        </CButton>
      )}
      {authStore.isDownloadOrgPermissions() && (
        <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={maxResults} />
      )}
    </TopToolbar>
  )
}))

const OrgList = ({ authStore, ...props }) => {
  
  return (
    <List {...props} filters={<PostFilter />} actions={<Actions {...props} />} empty={false}>
      <Datagrid bulkActionButtons={false}>
        <TextField source='name' label={<FieldTitle label='customRoot.organizations.list.org_name'/>} sortable={false} />
        <TextField source='displayName' label={<FieldTitle label='customRoot.organizations.list.display_name'/>} sortable={false} />
        <TextField source='slika_org_id' label={<FieldTitle label='customRoot.organizations.list.slika_org_id'/>} sortable={false} />
        <BooleanField source='active' label={<FieldTitle label='customRoot.organizations.list.active'/>} sortable={false} />
        <DateField source='createdAt' label={<FieldTitle label='customRoot.organizations.list.created_at'/>} locales='he-IL' sortable={false} />
        {authStore.isEditOrgPermissions() && (
          <ActionToolbar>
            <EditButton />
          </ActionToolbar>
        )}
      </Datagrid>
    </List>
  )
}

export default inject('authStore')(observer(OrgList))
