import React from 'react'
import { useTranslate } from "react-admin"
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import { ShoppingCart } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { Text, Button } from '../components'
import Cart from './Cart'

const COButton = styled.div`
  display: flex;
  justify-content: center;
`
const Total = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #999;
`

const CartButton = styled(Button)`
  color: white!important;
  border-radius: 8px!important;
  border: none !important;
  padding: 6px 16px!important;
`

const DesktopCart = ({ cartStore, withoutButton, ...props }) => {
  const t = useTranslate()
  const navigate = useNavigate()

  const params = useParams()
  const handleCheckout = () => navigate(`/order/${params.id}/checkout`)
  
  return (
    <div style={{ padding: '0 1rem 1rem' }}>
      {!withoutButton && <COButton style={{ justifyContent: 'center' }}>
        <CartButton
          fullWidth
          variant='outlined'
          disabled={cartStore.isEmpty}
          onClick={handleCheckout}
          name={t`publicOrder.checkout`}
        >
          <ShoppingCart /> {t('publicOrder.checkout')}
        </CartButton>
      </COButton>}
      <Total>
        <Text bold>{t('publicOrder.total')}</Text>
        <Text bold>₪ {cartStore.sum}</Text>
      </Total>
      <Cart />
    </div>
  )
}

export default inject('cartStore')(observer(DesktopCart))