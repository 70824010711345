import React, { useState, Fragment } from "react"
import { makeStyles } from "@mui/styles"
import { IconButton, DialogContent, Dialog, DialogTitle} from '@mui/material';
import styled from "styled-components"
import DoneIcon from "@material-ui/icons/Done"
import IconCancel from '@material-ui/icons/Cancel';
import { 
  useNotify,
  useTranslate, 
  SimpleForm,
  TextInput,
  Button, 
  Toolbar,
  SaveButton,
  FormDataConsumer
} from "react-admin"

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "256px"
  }
}))

const CancelButton = styled(Button)`
  padding: 6px 16px;
  color: white;
  line-height: 1.75;
  background: linear-gradient(160deg, rgb(193, 54, 54) 0%, rgb(193, 54, 54) 100%);
  box-shadow: rgb(193, 54, 54 / 75%) 0px 6px 10px -4px;
  border-radius: 4px;
  margin-right: 10px;
  border: none !important;
  font-size: 0.875rem;
  font-weight: 500;
`
const SimpleFormStyled = styled(SimpleForm)`
  .ra-input {
    display: flex;
    justify-content: center;
  }
`
const StyledToolbar = styled(Toolbar)`
  justify-content:center;
  background-color: white;
`

const PaymentCredentials = ({ handleAction }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const [openModal, setModalStatus] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  
  const query = {
    password: '',
  }

  const handleCloseClick = () => {
    setModalStatus(false)  
  }

  const handleOpenClick = () => {
    setModalStatus(true)  
  }

  const CustomToolbar = (props) => {
    return (
      <StyledToolbar {...props}>
        <SaveButton
          label="ra.passwordConfirmDialog.confirm_action_btn"
          icon={<DoneIcon />}
          disabled={isDisabled}
        />    
        <CancelButton type="button"
          label="ra.action.cancel"
          onClick={handleCloseClick}
          variant="contained"
          size="medium"
        >
          <IconCancel />
        </CancelButton>
      </StyledToolbar>
    )
  }

  const handleValidate = (values) => {
    const errors = {}

    if (!values.password) {
      errors.organizationId = translate('ra.validation.required')
    }

    return errors
  }

  return (
    <Fragment>
      <Button label='ra.passwordConfirmDialog.open_dialog_btn' style={{ marginBottom: '20px' }} variant='contained' onClick={handleOpenClick}/>
      <Dialog
        fullWidth
        open={openModal}
        onClose={handleCloseClick}
       >
        <DialogTitle>{translate("ra.passwordConfirmDialog.description")}</DialogTitle>
        <DialogContent>
          <SimpleFormStyled
            style={{alignItems: "center", justifyContent: "center"}} 
            name='do1' 
            autocomplete="off"
            record={query} 
            onSubmit={async (data) => {
              const result = await handleAction(data)
              if (!result) {
                setIsDisabled(true)
              }              
            }} 
            toolbar={<CustomToolbar/>}
            validate={handleValidate}
          >
            <FormDataConsumer>
          {({ formData }) => {
            return (
              <div className={classes.formContainer}>
            <TextInput 
              type="password"
              source="password" 
              autocomplete="false"
              placeholder=""
              onChange={(e) => {
                setIsDisabled(!e.target.value)
              }}
              label="customRoot.users.list.password" 
            />
            </div>
            )
          }}
          </FormDataConsumer>
          </SimpleFormStyled>
        </DialogContent>
      </Dialog>
    </Fragment>
  );   
}

export default PaymentCredentials
