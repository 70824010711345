import { Enum } from "./utils"

export const PermissionsEnum = Enum({
  create_org_manager: "create_org_manager",
  create_pos_manager: "create_pos_manager",
  create_operator: "create_operator",
  update_org_manager: "update_org_manager",
  update_pos_manager: "update_pos_manager",
  update_operator: "update_operator",
  delete_org_manager: "delete_org_manager",
  delete_pos_manager: "delete_pos_manager",
  delete_operator: "delete_operator",
  create_org: "create_org",
  update_org: "update_org",
  delete_org: "delete_org",
  get_list_org: "get_list_org",
  create_system: "create_system",
  update_system: "update_system",
  delete_system: "delete_system",
  get_list_system: "get_list_system",
  create_order: "create_order",
  update_order: "update_order",
  delete_order: "delete_order",
  get_list_order: "get_list_order",
  update_order_settings: "update_order_settings",
  upload_product: "upload_product",
  create_product: "create_product",
  update_product: "update_product",
  delete_product: "delete_product",
  get_list_product: "get_list_product",
  upload_customer: "upload_customer",
  update_customer: "update_customer",
  get_list_customer: "get_list_customer",
  delete_customer: "delete_customer",
  create_customer: "create_customer"
})

export const RolesEnum = Enum({
  SUPER_ADMIN: "super admin",
  ORG_MANAGER: "organization manager",
  POS_MANAGER: "pos manager",
  OPERATOR: "operator"
})