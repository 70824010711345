import { makeStyles } from "@mui/styles"
import { required } from "react-admin"
import { observer, inject } from 'mobx-react'
import { Card } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { restProvider } from "../../core"
import { RoutesEnum } from "../../core/restProvider"
import {
  FormDataConsumer,
  useNotify,
  useRedirect,
  CREATE,
  GET_ONE,
  useDataProvider,
  Title,
  SelectInput,
  SimpleForm,
  Toolbar,
  SaveButton,
  GET_LIST,
  useTranslate,
  AutocompleteInput,
  useAuthState, Loading
} from "react-admin"

const SimpleFormStyled = styled(SimpleForm)`
  @media (min-width: 800px) {
    width: 50%;
  }  

  width: 100%;
  .ra-input {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "256px"
  }
}))

const initCustomerValues = {
  firstName: "required",
  lastName: "required",
  budgetNumber: "required",
  address: "required",
  email: "required",
  phone: "required",
  showAddExternalCustomer: "hidden"
}


const EditOrderSettings = ({ authStore, ...props }) => {
  const dataProvider = useDataProvider()
  const redirect = useRedirect()
  const translate = useTranslate()
  const notify = useNotify()

  const [organizations, setOrganizations] = useState([])
  const [systems, setSystems] = useState([])

  const showOrganizationList = () => {
    return authStore.isSuperAdmin();
  }

  const showSystemList = () => {
    return authStore.isSuperAdmin() || authStore.isOrgManager()
  }

  const [record, setRecord] = useState({
    organizationId:"",
    systemId: "",
    customer: initCustomerValues
  })

  const getOrgs = async (mounted) => {
    try {
      const { data } = await restProvider(GET_LIST, RoutesEnum.ORGANIZATIONS, { filter: {}, sort: {}, pagination: { page: 1, perPage: 1000 } })

      if (mounted) {
        setOrganizations(data)
      }
    } catch (error) {}
  }

  const getSystems = async (orgId) => {
    try {
      const { data } = await restProvider(GET_LIST, RoutesEnum.SYSTEMS, {
        filter: { org_id: orgId },
        sort: {},
        pagination: { page: 1, perPage: 1000 }
      })

      setSystems(data)
      setRecord({
        organizationId: orgId,
        systemId: "",
        customer: initCustomerValues
      })
    } catch (error) {}
  }

  useEffect(() => {
    if (!authStore.isAutenticated) {
      return;
    }

    const userSettings = authStore.getSettings()

    const newRecord = {
      organizationId: showOrganizationList() ? "" : userSettings?.org_id,
      systemId: showSystemList() ? "" : userSettings?.system_id,
      customer: initCustomerValues
    } 

    setRecord(newRecord)

    // Since Admin does not have specific organization,
    // need to select it from select box.
    if (showOrganizationList()) {
      getOrgs(true)
    } else if (showSystemList()) {
      getSystems(newRecord.organizationId)

      // Since users with role operator and pos manager
    } else {
      dataProvider(GET_ONE, RoutesEnum.ORDER_SETTINGS, {
        organizationId: newRecord.organizationId,
        systemId: newRecord.systemId
      }).then((res) => {
        setRecord({
          organizationId: newRecord.organizationId,
          systemId: newRecord.systemId,
          customer: res.data.customer
        })
      })
    }

  }, [authStore.isAutenticated])

  const onOrgChange = (obj) => {
    const resourceId = obj

    // Since Admin and org manager do not have specific system,
    // need to select it from select box.
    if (showSystemList()) {
      getSystems(resourceId)
    }
  }

  const onSystemChange = (obj) => {
    const resourceId = obj

    dataProvider(GET_ONE, "order-settings", {
      organizationId: record.organizationId,
      systemId: resourceId
    }).then((res) =>
      setRecord({
        organizationId: record.organizationId,
        systemId: resourceId,
        customer: res.data.customer
      })
    )
  }

  const onSave = (data) => {
    const organizationId = data.organizationId
    const systemId = data.systemId

    dataProvider(CREATE, "order-settings", {
      organizationId,
      systemId,
      data: { customer: data.customer }
    }).then((res) => {
      notify("ra.notification.created", {
        type: "success",
        messageArgs: { smart_count: 1 }
      })
      redirect(false)
    })
  }

  const fieldList = [
    { source: "customer.firstName", label: "customRoot.customers.firstname" },
    { source: "customer.lastName", label: "customRoot.customers.lastname" },
    { source: "customer.budgetNumber", label: `${translate("customRoot.customers.budget_number")} / ${translate("customRoot.customers.social_id")}` },
    { source: "customer.email", label: "customRoot.customers.email" },
    { source: "customer.phone", label: "customRoot.customers.phone" },
    { source: "customer.address", label: "customRoot.customers.address" },
  ]

  const choices = [{ name: "required" }, { name: "optional" }, { name: "hidden" }]

  const OrderSettingsToolbar = (props) => {
    const classes = useStyles()

    return (
      <Toolbar className={classes.toolbar} {...props}>
        <SaveButton />
      </Toolbar>
    )
  }

  const validateOrderSettingsCreation = (values) => {
    const errors = {}

    if (!values.organizationId && showOrganizationList()) {
      errors.organizationId = "The organization is required"
    }

    if (!values.systemId && showSystemList()) {
      errors.systemId = "The system is required"
    }

    return errors
  }

  return (
    <>
      <Title title={translate("customRoot.myMenu.order_settings")} />
      <Card>
        <SimpleFormStyled name='do' record={record} toolbar={<OrderSettingsToolbar />} validate={validateOrderSettingsCreation} onSubmit={onSave}>
        <FormDataConsumer>
          {({ formData }) => {
            return ( 
            <>
          {showOrganizationList() && (
            <AutocompleteInput
              source='organizationId'
              className="autocompeleteMekome"
              optionText='name'
              optionValue='id'
              label='customRoot.upload.org'
              choices={organizations}
              onChange={(data) => {
                onOrgChange(data)
              }}
            />
          )}

          {showSystemList() && record.organizationId && (
            <AutocompleteInput 
              source='systemId' 
              className="autocompeleteMekome"
              optionText='name' 
              optionValue='id' 
              label='customRoot.upload.sys' 
              choices={systems} 
              onChange={onSystemChange} 
            />
          )}

          {fieldList.map(({ source, label }) => (
            <SelectInput
              key={source}
              validate={required()}
              source={source}
              optionText={({ name }) => translate(`customRoot.customers.${name}`)}
              optionValue='name'
              label={label}
              choices={choices}
            />
          ))}
              <SelectInput
                key={'customer.showAddExternalCustomer'}
                validate={required()}
                source={'customer.showAddExternalCustomer'}
                optionText={({ name }) => translate(`customRoot.customers.${name}`)}
                optionValue='name'
                label={'customRoot.customers.show_add_external_customer'}
                choices={[{ name: "required" }, { name: "hidden" }]}
              />
          </>
          )
        }}
      </FormDataConsumer>
        </SimpleFormStyled>
      </Card>
    </>
  )
}

export default inject('authStore')(observer(EditOrderSettings))
