import React, { useState } from "react"
import { Form, useTranslate, useNotify, required, useDataProvider, CREATE, TextInput } from "react-admin"
import styled from "styled-components"
import { RoutesEnum } from "../../core/restProvider"
import { Button, Card, Avatar, SxProps, CardContent, CircularProgress } from "@mui/material"
import requests from "../../core/requests"
import LockIcon from '@mui/icons-material/LockReset';

const PREFIX = 'RaLogin';
const LoginClasses = {
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`,
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
};

const Description = styled.span`
  display: flex;
  width: 300px;
  padding: 10px;
  text-align: center;
`

const StyledForm = styled(Form, {
        name: PREFIX,
        overridesResolver: (props, styles) => styles.root,
    })(({ theme }) => ({
        [`& .${LoginClasses.content}`]: {
            width: 300,
        },
       /* [`& .${LoginClasses.button}`]: {
            marginTop: theme.spacing(2),
        },
        [`& .${LoginClasses.icon}`]: {
            margin: theme.spacing(0.3),
        },*/
    }
));

const Root = styled('div', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

    [`& .${LoginClasses.card}`]: {
        minWidth: 300,
        marginTop: '6em',
    },
    [`& .${LoginClasses.avatar}`]: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    /*[`& .${LoginClasses.icon}`]: {
        backgroundColor: theme.palette.secondary[500],
    },*/
}));


const PasswordSend = (props) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();

  const submit = async (values) => {
    setLoading(true);

    try {
        const response = await requests.sendEmailToResetPassword(values.email)
        setLoading(false);

        notify('reset_password.send_successful', { type: 'success' });
    } catch(e) {
        setLoading(false);
        //notify('ra.auth.sign_in_error', { type: 'error' });
    }
  };

  return (
    <Root>
        <Card className={LoginClasses.card}>
            <div className={LoginClasses.avatar}>
                <Avatar className={LoginClasses.icon}>
                    <LockIcon />
                </Avatar>
            </div>
            <Description>{translate('reset_password.description')}</Description>
            <StyledForm
                onSubmit={submit}
                mode="onChange"
                noValidate
            >
                <CardContent className={LoginClasses.content}>
                    <TextInput
                        autoFocus
                        source="email"
                        label={translate('customRoot.customers.email')}
                        validate={required()}
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={loading}
                        fullWidth
                        className={LoginClasses.button}
                    >
                        {loading ? (
                            <CircularProgress
                                className={LoginClasses.icon}
                                size={19}
                                thickness={3}
                            />
                        ) : (
                            translate('reset_password.send')
                        )}
                    </Button>
                </CardContent>
            </StyledForm>
        </Card>
    </Root>
  )
}

export default PasswordSend
