import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { observer, inject } from 'mobx-react'
import { List, ListItem } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { useTranslate } from "react-admin"
import { Text, Space } from '../components'
import theme from '../utils/theme'

const Item = styled(ListItem)`
  padding: 0.7rem;
`
const Sum = styled(Text)`
  color: ${theme.color.grey};
  white-space: nowrap;
  margin: 0 0.5rem;
  direction: ltr;
`
const CartList = styled(List)`
  max-height: 70vh;
  //overflow: scroll;
  padding-bottom: 100px;
`

const Cart = ({ cartStore }) => {
  const t = useTranslate()
  const handleRemove = id => cartStore.removeItem(id)
  const order = Object.values(cartStore.items)
  
  return (
    <CartList>
          <div>
            {order.map(({ id, name, measure = 'unit', quantity, price }) => (
              <Item key={`cart_tem_${id}`}>
                <Text>{name}</Text>
                <Space grow />
                <>
                  <Sum thin style={{ marginLeft: '10px' }}>{quantity} {t(`publicOrder.measure_${measure}`)}</Sum>
                  {!!price && (<Text style={{ textAlign: 'right', direction: 'ltr', whiteSpace: 'nowrap' }}>₪ {(quantity * price).toFixed(2)}</Text>)}
                </>
                <Clear color='secondary' onClick={() => handleRemove(id)} style={{ margin: '0 0.5rem', cursor: 'pointer' }} role='button' name={`${t`publicOrder.remove`} ${name}`} />
              </Item>
            ))}
          </div>
      {cartStore.isEmpty && <ListItem style={{ justifyContent: 'center' }}><Text>{t('publicOrder.cart_is_empty')}</Text></ListItem>}
    </CartList>
  )
}

export default inject('cartStore')(observer(Cart))