import { useState } from 'react'

export const isMobile = document.body.offsetWidth < 799

export async function delay(ms) {
  return await new Promise(resolve => setTimeout(resolve, ms));
}

export const useExtendedState = (initialState) => {
  const [state, setState] = useState(initialState)
  const getLatestState = () => {
    return new Promise((resolve, reject) => {
      setState((s) => {
        resolve(s);
        return s;
      });
    });
  };
    
  return [state, setState, getLatestState]
}