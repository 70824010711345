import React from 'react'
import { Dialog, DialogTitle ,DialogContent, DialogActions, Button } from '@mui/material'
import { useTranslate } from 'react-admin'

const Accessibility = ({ open, onClose }) => {
  const t = useTranslate()
  
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{t`publicOrder.accessibility`}</DialogTitle>
      <DialogContent style={{ paddingBottom: '2rem' }}>
        {t`publicOrder.acc_desc`}
      </DialogContent>
      <DialogActions>
        <Button name='close' onClick={onClose}>{t`publicOrder.close`}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default Accessibility