import React, { useState, useRef, useEffect } from "react"
import { useFormContext } from "react-hook-form";
import { makeStyles } from "@material-ui/core"
import styled from 'styled-components'
import { useParams, useNavigate } from "react-router-dom";
import { CREATE, GET_ONE, Button, useUpdate, useDataProvider, useEditController, SimpleForm, TextInput, required, email, Edit, DeleteButton, SaveButton, Toolbar, BooleanInput, usePermissions, useNotify, useTranslate } from "react-admin"
import { observer, inject } from 'mobx-react'
import { PaymentCredentials } from "./components";
import { RoutesEnum } from "../../core/restProvider"


const SimpleFormStyled = styled(SimpleForm)`
  @media (min-width: 800px) {
    width: 50%;
  }  

  width: 100%;
  .ra-input {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`


const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between"
  }
}))

const SystemEditToolbar = inject('authStore')(observer(({ authStore, ...props }) => {
  const classes = useStyles()
  const [update] = useUpdate();
  const { getValues } = useFormContext();


  return (<Toolbar className={classes.toolbar} {...props}>
    <SaveButton  />

    {authStore.isDeleteSystemPermissions() && <DeleteButton />}
  </Toolbar>)
}))

const SystemsEdit = ({ authStore, ...props }) => {
  const { id } = useParams();
  const translate = useTranslate()
  const notify = useNotify()
  const navigate = useNavigate();
 
  const isAdmin = authStore.isSuperAdmin()

  const dataProvider = useDataProvider()
  const { record, isLoading } = useEditController({ resource: 'systems', id });

  const validateRequired = [required(), email()]
  const [isActivePaymentCard, setIsActivePaymentCard ] = useState(false);
  const [isLoaded, setIsLoaded ] = useState(false);
  const [isOpenedPaymentCredentials, setIsOpenedChangedPaymentCredentials] = useState(false)
  const [paymentsOs, setPaymentOsSettings] = useState({
    businessNumber: '',
    publicKey: '',
    privateKey: '',
    appId: ''
  })

  if (record?.paymentSettings && !isLoaded) {
    setIsActivePaymentCard(record?.paymentSettings.active)
    setIsLoaded(true)

    if (record?.paymentSettings?.paymentsOs) {
      setIsOpenedChangedPaymentCredentials(true)
      setPaymentOsSettings({
        businessNumber: record.paymentSettings?.paymentsOs.businessNumber,
        publicKey: record.paymentSettings?.paymentsOs.publicKey,
        privateKey: record.paymentSettings?.paymentsOs.privateKey,
        appId: record.paymentSettings?.paymentsOs.appId
      })
    }
  }

  if (isLoading) return null;

  const generateLink = (record) => {
    return dataProvider(GET_ONE, RoutesEnum.SHARE_SYSTEM_LINK, {
      organizationId: record.organizationId,
      systemId: record.id,
    }).then((res) => {
      navigator.clipboard.writeText(`${window.location.origin}/#/order/${res.data.publicUrl}`);
      notify(translate("Copied"), { type: "success" })

      return true  
    }).catch(e => {
      notify(translate('ra.message.password_invalid'), { type: "error" })
      return false
    })
  }

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/systems');
  };

  const showPaymnetCredentials = (data) => {
    return dataProvider(CREATE, RoutesEnum.PAYMENT_CREDENTIALS, {
      organizationId: record.organizationId,
      systemId: record.id,
      password: data.password,
    }).then((res) => {
      
      if (res.data) {
        setPaymentOsSettings(res.data.paymentsOs)
        setIsOpenedChangedPaymentCredentials(true)
        return true  
      }

      return false
    }).catch(e => {
      notify(translate('ra.message.password_invalid'), { type: "error" })
      return false
    })
  }

  return (
    <Edit {...props}  mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <SimpleFormStyled toolbar={<SystemEditToolbar />}  record={record}>
        <TextInput source='name' label='customRoot.organizations.list.display_name' validate={required()} />
        <TextInput source='email' label='customRoot.users.list.email' validate={validateRequired} />
        <TextInput source='externalId' label='customRoot.systems.list.externalId' />
        <div style={{ width: "235px" }}>
        <Button 
          label='share_link' 
          style={{ marginBottom: '20px', width: '100%' }} 
          variant='contained' 
          onClick={() => {
            generateLink(record)
          }}
        />
        </div>
        { isAdmin && (
          <BooleanInput source='settings.enableSendSmsWithOrderDetail' label='customRoot.systems.settings.enableSendSmsWithOrderDetail'/>
        )}
        <BooleanInput source='paymentSettings.active' label='customRoot.systems.list.active' onChange={(event) => { setIsActivePaymentCard(!isActivePaymentCard) }}/>
        {
          isActivePaymentCard && (
            <div style={{ width: '100%', display: "flex", flexDirection: "column" }}>
              {isOpenedPaymentCredentials && (
                <>  
                  <TextInput 
                    source='paymentSettings.paymentsOs.businessNumber' 
                    defaultValue={paymentsOs.businessNumber} 
                    label='customRoot.organizations.list.business_number' 
                    validate={isActivePaymentCard && required()} 
                  />
                  <TextInput 
                    source='paymentSettings.paymentsOs.publicKey' 
                    defaultValue={paymentsOs.publicKey} 
                    label='customRoot.organizations.list.public_key' 
                    validate={isActivePaymentCard && required()} 
                  />
                  <TextInput 
                    source='paymentSettings.paymentsOs.privateKey' 
                    defaultValue={paymentsOs.privateKey} 
                    label='customRoot.organizations.list.private_key' 
                    validate={isActivePaymentCard && required()} 
                  />
                  <TextInput 
                    source='paymentSettings.paymentsOs.appId' 
                    defaultValue={paymentsOs.appId} 
                    label='customRoot.organizations.list.app_id' 
                    validate={isActivePaymentCard && required()} 
                  />
                </>
              )}

              {!isOpenedPaymentCredentials && (
                <PaymentCredentials handleAction={showPaymnetCredentials}/>
              )}
              
              <TextInput source='paymentSettings.chargePage.logoLink' label='customRoot.organizations.list.logo_link' />
              <TextInput source='paymentSettings.chargePage.titleText' label='customRoot.organizations.list.title_text' />
              <TextInput source='paymentSettings.chargePage.bodyText' label='customRoot.organizations.list.body_text' />
              <TextInput source='paymentSettings.chargePage.businessAddress' label='customRoot.organizations.list.business_address' />
              <TextInput source='paymentSettings.chargePage.businessPhone' label='customRoot.organizations.list.business_phone' />
            </div>
          )
        }
      </SimpleFormStyled>
    </Edit>
  )
}

export default inject('authStore')(observer(SystemsEdit))
