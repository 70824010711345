import React, { useState, useEffect } from 'react';
import styled, { keyframes } from "styled-components";




const Image = styled.img`
  width: 60px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

const Container = styled.div`
font-family: monaco,Consolas,Lucida Console,monospace; 
  max-width: 400px;
  min-width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid пкфн;
  border-radius: 5px;
  padding: 8px 10px;
`

const Title= styled.div`
 font-size: 18px;
 padding-bottom: 15px;
 padding-top: 15px;
 display:flex;
 align-items:center;
 justify-content: center;
 font-weight: bold;
`


const NotFoundError = (props) => {

  return (
    <>
      <Image src="/mekome_logo.png" alt="image" />
      <hr/>
        <Container>
        <Title>Page Not Found</Title> 
      </Container>
    </>
  );
};

export default NotFoundError;
