import React from "react"

import { Clear, Done } from "@material-ui/icons"
import { green, red } from "@material-ui/core/colors"
import { Typography } from "@mui/material"
import { useTranslate } from "react-admin"

const Icons = ({ customerSocialId, fontSize }) => {
  return (
    customerSocialId ?
      <Clear style={{ color: red[500] }} fontSize={fontSize} />
      :
      <Done style={{ color: green[500] }} fontSize={fontSize} /> )
}

const Text = ({ customerSocialId }) => {
  const translate = useTranslate();
  const type = customerSocialId ? 'external' : 'internal';
  return <Typography variant="caption" style={{ color: (customerSocialId ? red[500] : green[500]) }}>{ translate(`customRoot.customers.${type}_customer`) }</Typography>
}

const InternalUserFlag = ({ customerSocialId, fontSize, text }) => {
  return text ? <Text customerSocialId={customerSocialId} /> : <Icons customerSocialId={customerSocialId} fontSize={fontSize} />
}

export default InternalUserFlag
