import React, { useEffect, useRef, useState } from "react"
import {
  AutocompleteInput,
  Datagrid,
  ExportButton,
  Filter,
  FunctionField,
  List,
  ReferenceInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useListController,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
  useResourceContext,
  useTranslate,
  SearchInput
} from "react-admin"
import styled from "styled-components"
import { Add, Publish, GetApp as GetAppIcon } from "@material-ui/icons"
import {
  Button,
  Box,
  LinearProgress,
  linearProgressClasses,
  CircularProgress,
  circularProgressClasses,
  styled as styledMui
} from "@mui/material"
import { Link } from "react-router-dom"
import xlsx from "xlsx"
import ActionToolbar from "../common/ActionToolbar"
import useFetch from "use-http"
import { observer, inject } from 'mobx-react'
import FieldTitle from "../common/FieldTitle"
import { EditButton } from '../common/EditButton'
import { AutocompleteInputStyled, TextInputStyled } from "../common/InputFields"

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .column-price,
  .column-is_active,
  .column-undefined {
    width: 100px;
  }
`

const convertToItemsTemplate = (data, translate) => {
  return data.map(d => ({
    name: d.name,
    category: d.category,
    description: d.description,
    order: d.sortOrder,
    image: d.image,
    price: d.price,
    discount: d.discount,
    weight: d.weight,
    quantity: d.quantity,
    barcode: d.barcode,
    status: translate(`customRoot.products.status.${d.status}`)
  }))
}

const ItemsFilter =  inject('authStore')(observer(({ authStore, ...props}) => {
  const { filterValues, total } = props.cprops
  const secondFilter = filterValues ? { filter: { org_id: filterValues.org_id } } : {}

  const isAdmin = authStore.isSuperAdmin()
  const isManager = authStore.isOrgManager()

  return (
    <Filter {...props}>
      {(isAdmin || isManager) && (
        <ReferenceInput
          label='resources.organizations.singular'
          reference='organizations'
          source='org_id'
          alwaysOn
          perPage={1000}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInputStyled
            className="autocompeleteMekome"
            label='resources.organizations.singular'
            optionText="name"
            matchSuggestion={(filter, choice) => choice.name.match(filter)}
            optionValue="id"
          />
        </ReferenceInput>
      )}
      {(isAdmin || isManager) && (
        <ReferenceInput
          label='customRoot.organizations.list.system'
          reference='systems'
          source='system_id'
          alwaysOn
          {...secondFilter}
          perPage={1000}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInputStyled
            className="autocompeleteMekome"
            matchSuggestion={(filter, choice) => choice.name.match(filter)}
            label='customRoot.organizations.list.system'
            optionText="name"
            optionValue="id"
          />
        </ReferenceInput>
      )}
      <TextInputStyled source='name' alwaysOn resettable  />
    </Filter>
  )
}))

const PriceField = (props) => {
  const record = useRecordContext();
  return (
    <><span style={{ direction: "ltr", display: "inline-block", textAlign: "right" }}>&nbsp;{record[props.source]}&nbsp;</span>₪</>
  )
}

const CButton = styled(Button)`
  color: #2196f3;
  padding: 2px;
`

const Input = styled.div`
  display: none;
`

const DownloadText = styled.a`
  color: #2196f3;
  text-decoration: none;
`
const LoaderFilesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10rem;
  z-index: 4;
`

const BorderLinearProgress = styledMui(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: 'relative', left: '45%' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const LoaderFiles = ({ isLoadFile }) => {
  const [progressValue, setProgressValue] = useState(0)
  const intervalRef = useRef()
  const minTimLoadingRef = useRef(0)
  const minTimLoadingIntervalRef = useRef()
  const [timer, setTimer] = useState(100)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isLoading) {
      minTimLoadingIntervalRef.current =  setInterval(()=> {
        minTimLoadingRef.current = minTimLoadingRef.current + 50
      }, 50)
    } else {
      clearInterval(minTimLoadingIntervalRef.current)
    }

  }, [isLoading])

  useEffect(() => {
    clearInterval(intervalRef.current)

    if (isLoading) {
      intervalRef.current = setInterval(()=> {
        setProgressValue(prev => prev + 1)
      }, timer)
    }

  }, [timer, isLoading])

  useEffect(() => {
    if (isLoadFile) {
      if (progressValue === 30 ) {
        setTimer(300)
      }

      if (progressValue === 50 ) {
        setTimer(500)
      }

      if (progressValue === 70 ) {
        setTimer(700)
      }

      if (progressValue === 80 ) {
        setTimer(1000)
      }

      if (progressValue === 90 ) {
        setTimer(2000)
      }
    }

    if (progressValue === 100) {
      clearInterval(intervalRef.current)
    }
  }, [progressValue, isLoadFile])

  useEffect(() => {
    if (!isLoadFile && isLoading) {
      if (minTimLoadingRef.current < 4000) {
        setTimer(2)
      }

      if (progressValue === 100) {
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      }
    }
  }, [isLoadFile, isLoading, minTimLoadingRef.current, progressValue])

  useEffect(() => {
    if (isLoadFile && !isLoading) {
      setIsLoading(true)
    }

    if (!isLoadFile && isLoading && minTimLoadingRef.current > 4000) {
      setTimer(1)
      setTimeout(() => {
        setIsLoading(false)
      }, 1500)
    }

    if (!isLoading) {
      setProgressValue(0)
      minTimLoadingRef.current = 0
    }
  }, [isLoadFile, isLoading])

  return (isLoading && (
    <LoaderFilesWrapper>
      <Box sx={{ flexGrow: 1 }}>
        <FacebookCircularProgress />
        <br />
        <BorderLinearProgress variant="determinate" value={progressValue} />
      </Box>
    </LoaderFilesWrapper>
    )
  );
}

const Actions = inject('authStore')(observer(({ authStore, setIsLoadFile, ...props }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const input = useRef()
  const resource = useResourceContext();
  const resultStorageByIds = authStore._ids

  const { maxResults } = props
  const { currentSort, filterValues, total } = useListContext()
  const { org_id, system_id } = filterValues

  const translate = useTranslate()

  const orgId = org_id || resultStorageByIds?.org_id
  const systemId = system_id || resultStorageByIds?.system_id

  const { post } = useFetch(`/api/v1/organizations/${orgId}/systems/${systemId}/products/import`, { cachePolicy: "no-cache" })

  const handleClick = () => {
    input.current.click()
  }

  const handleChange = (e) => {
    e.preventDefault();
    const form = new FormData()
    const file = e.target.files[0]

    setIsLoadFile(true)
    form.append("file", file)

    post(form).then((r) => {
      if (r.data) {
        setIsLoadFile(false)
        notify(translate("customRoot.upload.successMessage"), { type: "success" })
      }
      if (r.errors) {
        setIsLoadFile(false)
        const notifications = []
        r.errors.forEach(error => {
          if (error.data) {
            if (error.data.fields) {
              const fields = error.data.fields.map(field => translate(`customRoot.items.${field}`))
              error.data.fields = fields.join(',')
            }
            if (error.data.field) {
              error.data.field = translate(`customRoot.items.${error.data.field}`)
            }
          }
          notifications.push(translate(`errors.import_items.${error.code}`, { ...error.data }))
        })
        notify(notifications.join(', '), { type: 'error', multiLine: true });
      }
      refresh()
    })
  }

  const DownloadButton = ({ disabled }) => (
    <CButton disabled={disabled}>
      <GetAppIcon fontSize='small' />
      <DownloadText style={{ color: disabled ? "rgba(0, 0, 0, 0.26)" : "#2196f3" }} href='template.xlsx' download>
        {translate("customRoot.upload.templateText")}
      </DownloadText>
    </CButton>
  )
  const isProducts = total === 0

  return (
    <TopToolbar>
      {authStore.isCreateProductPermissions() && (
        <CButton
          component={Link}
          onClick={() => authStore.setResource(resource, { org_id, system_id })}
          disabled={!orgId || !systemId}
          to={{
            pathname: `/products/create?org_id=${orgId}&system_id=${systemId}`,
            state: {
              org_id: orgId,
              system_id: systemId
            }
          }}
        >
          <Add />
          {translate("ra.action.create")}
        </CButton>
      )}
      {authStore.isExportProductPermissions() && (
        <CButton disabled={!orgId || !systemId} onClick={handleClick}>
          <Publish fontSize='small' />
          {translate("ra.action.import")}
        </CButton>
      )}
      {authStore.isExportProductPermissions() && <DownloadButton disabled={!orgId || !systemId} />}

      {authStore.isDownloadProductPermissions() && (
        <ExportButton disabled={isProducts} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={maxResults} />
      )}

      <Input>
        <input
          type='file'
          ref={input}
          accept={[".xlsx", ".csv"]}
          onChange={(e) => {
            handleChange(e);
            e.target.value = null
          }}
        />
      </Input>
    </TopToolbar>
  )
}))

const ItemList = ({ authStore, ...props}) => {
  const translate = useTranslate()
  const controllerProps = useListController(props)
  const resource = useResourceContext();
  const { filterValues } = controllerProps
  const { org_id, system_id } = filterValues

  const [isLoadFile, setIsLoadFile] = useState(false)

  const exporter = (data) => {
    const wb = xlsx.utils.book_new()
    const sdata = convertToItemsTemplate(data, translate)

    const s = xlsx.utils.json_to_sheet(sdata,
      { header: [
          'name',
          'category',
          'description',
          'order',
          'image',
          'price',
          'discount',
          'weight',
          'quantity',
          'barcode',
          'status',
        ]})

    xlsx.utils.book_append_sheet(wb, s, "Sheet 1")
    xlsx.writeFile(wb, `products.xlsx`)
  }

  const isSortName = useRef(false);
  const isSortOrder = useRef(false);

  const [localData, setLocalData] = useState([]);
  const [toggleName, setToggleName] = useState(true);
  const [toggleOrder, setToggleOrder] = useState(true);

  const handleSortOrder = (array = [], isOrder = true) => {
    if (isOrder) {
      const newArray = array.sort((a,b) => a.sortOrder - b.sortOrder)
      return newArray
    } else {
      const newArray = array.sort((a,b) => b.sortOrder - a.sortOrder)
      return newArray
    }
  }

  const handleSortName = (array = [], isABC = true) => {
    const sortABC = (a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })
    const sortCBA = (a, b) => b.name.localeCompare(a.name, undefined, { numeric: true })

    if (isABC) {
      const arrayABC = array.sort(sortABC)
      return arrayABC
    } else {
      const arrayCBA = array.sort(sortCBA)
      return arrayCBA
    }
  }

  const handleSetToggleOrder = () => {
    isSortOrder.current = true
    setToggleOrder(prev => !prev)
  }

  const handleSetToggleName = () => {
    isSortName.current = true
    setToggleName(prev => !prev)
  }

  useEffect(() => {
    if (!!controllerProps.data && !localData.length) {
      const newData = handleSortName(controllerProps.data)
      setLocalData(newData)
      return
    }

    if (controllerProps?.data?.length > localData.length || controllerProps?.data?.length < localData.length) {
      setLocalData(controllerProps.data)
    }
  }, [controllerProps, localData])

  useEffect(() => {
    if (!!controllerProps.data && isSortName.current ) {
      const newData = handleSortName(controllerProps.data, toggleName);
      setLocalData(newData)
      isSortName.current = false;
    }
  }, [controllerProps])

  useEffect(() => {
    if (!!controllerProps.data && isSortOrder.current ) {
      const newData = handleSortOrder(controllerProps.data, toggleOrder);
      setLocalData(newData)
      isSortOrder.current = false;
    }
  }, [controllerProps])


  return (
    <Container>
      <LoaderFiles isLoadFile={isLoadFile} />
      <List
        {...props}
        filters={<ItemsFilter  cprops={controllerProps} />}
        perPage={25}
        exporter={exporter}
        actions={<Actions {...props} setIsLoadFile={setIsLoadFile} />}
        empty={false}
      >
        <Datagrid bulkActionButtons={false} data={localData} >
          <FunctionField source='status' render={record => translate(`customRoot.products.status.${record.status}`) } label={<FieldTitle label='ra.report_pos_system_admin.columns.status'/>} sortable={false} />
          <TextField
            source='name'
            label={<FieldTitle label='customRoot.orders.items.item_name'  onClick={handleSetToggleName} />}
            sortable={true}
          />
          <TextField source='category' label={<FieldTitle label='customRoot.orders.items.category'/>} sortable={false} />
          <PriceField source='price' label={<FieldTitle label='customRoot.orders.items.price'/>} sortable={false} />
          <FunctionField
            source='sortOrder'
            label={<FieldTitle label='customRoot.orders.items.sort_order' onClick={handleSetToggleOrder} />}
            sortable={true}
            render={(record) => record.sortOrder == 0 ? '' : record.sortOrder}
          />
          {authStore.isEditProductPermissions() && (
            <ActionToolbar>
              <EditButton query={`?org_id=${org_id}&system_id=${system_id}`} onClick={() => authStore.setResource(resource, { org_id, system_id })} />
            </ActionToolbar>
          )}
        </Datagrid>
      </List>
    </Container>
  )
}

export default inject('authStore')(observer(ItemList))

