import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Paper, TableRow, TableHead, TableContainer, Table, TableBody, TableCell  } from "@mui/material"
import { useTranslate } from "react-admin"

const useStyles = makeStyles({
  table: {
    // minWidth: 550
  }
})

const ItemList = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const { items } = props.record
  const totalPrice = items?.reduce((a, { pricePerOne, quantity }) => a + pricePerOne * quantity, 0).toFixed(2) || 0
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>{translate("customRoot.orders.items.category")}</TableCell>
            <TableCell>{translate("customRoot.orders.items.item_name")}</TableCell>
            <TableCell>{translate("customRoot.orders.items.quantity")}</TableCell>
            <TableCell>{translate("customRoot.orders.items.price")}</TableCell>
            <TableCell>{translate("customRoot.orders.items.remarks")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row, idx) => (
            <TableRow hover key={idx}>
              <TableCell>{row.category}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell><span style={{ direction: "ltr", display: "inline-block", textAlign: "right" }}>{row.pricePerOne}</span></TableCell>
              <TableCell>{row.remarks}</TableCell>
            </TableRow>
          ))}
          <TableRow hover>
            <TableCell colSpan={2} />
            <TableCell>{translate("customRoot.orders.items.total")}</TableCell>
            <TableCell colSpan={2}><span style={{ direction: "ltr", display: "inline-block", textAlign: "right" }}>{totalPrice}</span></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
ItemList.defaultProps = {
  data: {},
  ids: []
}
export default ItemList
