import React from "react"
import { Admin, CustomRoutes } from "react-admin"
import { QueryClient } from 'react-query';
import { Provider as FetchProvider } from "use-http"
import { create } from "jss"
import { createTheme } from '@mui/material/styles';
import blue from '@mui/material/colors/blue';
import polyglotI18nProvider from "ra-i18n-polyglot"

import { Login } from './components/login/Login'
import { authProvider, layout } from "./core"
import restProvider, { addUploadFeature } from "./core/restProvider"
import getRes from "./core/Resources"
import { Dashboard } from "./components/main"
import { he, en } from "./i18n"
import settings from "./tools/settings"

import { Edit } from "./components/orderSettings"
import { Route, BrowserRouter, Routes } from "react-router-dom"
import { CashierTab, Checkout, Confirm, Charge, ChargingSuccess, ChargePublic, ChargePublicSuccess } from "./components/cashier"
import { PasswordReset, PasswordSend } from "./components/password"
import ReceiptPublic from "./components/receipt/Receipt";
import PageNotFoundError from "./components/errors/NotFousError";
import { Provider as MobxProvider } from 'mobx-react'
import * as stores from './components/publicOrder/stores'
import authStore from './core/stores/auth.store'
import Private from "./core/Private"

import { 
  Home, 
  Checkout as OrderCheckout, 
  Charge as OrderCharge,
  Success 
} from "./components/publicOrder";


import rtlPlugin from 'stylis-plugin-rtl';
import {prefixer} from 'stylis';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});


const uploadCapableDataProvider = addUploadFeature(restProvider)

const messages = {
  he,
  en
}
const i18nProvider = polyglotI18nProvider((locale) => (messages[locale] ? messages[locale] : messages.he), "he")

const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: blue  
  },
})

const options = {
  credentials: "include",
  headers: {
    Accept: "application/json",
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App  = () => {
    return (
      <MobxProvider {...stores} authStore={authStore}>
      <CacheProvider value={cacheRtl}>
        <FetchProvider url={settings.apiUrl} options={options}>
          <Admin
            loginPage={Login}
            theme={theme}
            i18nProvider={i18nProvider}
            layout={layout}
            dashboard={Dashboard}
            dataProvider={uploadCapableDataProvider}
            authProvider={authProvider}
            queryClient={queryClient}
            disableTelemetry
          >
            {getRes}
            <CustomRoutes>
              <Route path='/cashier' element={<Private><CashierTab/></Private>} />
              <Route path='/checkout' element={<Private><Checkout/></Private>} />
              <Route path='/confirm' element={<Private><Confirm/></Private>} /> 
              <Route path='/charge' element={<Private><Charge/></Private>} />
              <Route path='/charging-success' element={<Private><ChargingSuccess/></Private>} />
              <Route path='/order-settings' element={<Private><Edit /></Private>} />
            </CustomRoutes>
            
            <CustomRoutes noLayout>
              <Route exact path="/404" element={<PageNotFoundError/>} ></Route>
              <Route exact path="/r/:code" element={<ReceiptPublic/>} ></Route>
              <Route exact path="/payment-form" element={<ChargePublic/>} />
              <Route exact path='/success' element={<ChargePublicSuccess/>} />
              <Route exact path="/password/send" element={<PasswordSend/>} />
              <Route exact path="/password/reset/:token" element={<PasswordReset/>} />  
              <Route exact path="/order/:id" element={<Home/>} />
              <Route exact path="/order/:id/checkout" element={<OrderCheckout/>} />
              <Route exact path="/order/:id/charge" element={<OrderCharge/>} />
              <Route exact path="/order/:id/success" element={<Success/>} />
            </CustomRoutes>
          </Admin>
        </FetchProvider>
      </CacheProvider>
      </MobxProvider>
    )
  
}

export default App
