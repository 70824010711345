import React from 'react'
import styled from 'styled-components'
import Spinner from './Spinner'

const LoadingWrap = styled.div`
  display:flex;
  flex:1;
  width:100%;
  justify-content:center;
  align-items:center;
  color: blue;
  position:${p => p.wrapPosition ? p.wrapPosition : 'static'};
`

const Loading = ({ wrapPosition, size }) => <LoadingWrap wrapPosition={wrapPosition}><Spinner size={size} /></LoadingWrap>

export default Loading