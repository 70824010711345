import React, { useEffect, useState } from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  useNotify,
  useTranslate,
  SelectInput
} from "react-admin"
import { useNavigate } from 'react-router-dom';
import useFetch from "use-http"
import styled from "styled-components"
import { makeStyles } from "@mui/styles"

const SimpleFormStyled = styled(SimpleForm)`
  @media (min-width: 800px) {
    width: 50%;
  }  

  width: 100%;
  .ra-input {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "256px"
  }
}))


const OrgCreate = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const navigate = useNavigate();
  const notify = useNotify()
  const [ slikaOrganizations, setSlikaOrganizations ] = useState([])
  const { get } = useFetch(`/api/v1/organizations/slika/organizations`, { cachePolicy: "no-cache" })

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/organizations');
  };

  const getSlikaOrganizations = () => {
    get().then(res => {
      setSlikaOrganizations(res.data)
    })
  }

  useEffect(() => {
    getSlikaOrganizations()
  }, [])

  return (
    <Create 
      {...props} 
      mutationOptions={{ onSuccess }}
      mutationMode="pessimistic"
    >
      <SimpleFormStyled>
          <TextInput source='name' label='customRoot.organizations.list.org_name' validate={required()} />
          <SelectInput
            source='slika_org_id'
            optionText='id'
            optionValue='id'
            label='customRoot.organizations.list.slika_org_id'
            choices={slikaOrganizations}
            validate={required()}
          />
          <TextInput source='displayName' label='customRoot.organizations.list.display_name' validate={required()} />
          <BooleanInput label='customRoot.organizations.list.active' source='active' />
      </SimpleFormStyled>
    </Create>
  )
}
export default OrgCreate
