export const paymentsOS = {
    script: 'https://js.paymentsos.com/v2/latest/token.min.js',
    errorResponse: {
        apiError: 'api_request_error',
        clientError: 'client_validation_error',
        clientValidationError: 'client_validation_error',
        invalidCardNumber: 'invalid_card_number',
        invalidCardCvv:  'invalid_card_cvv'
    }
}
