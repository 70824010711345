import _ from "lodash"
import { useState } from "react"
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"

export const round = (num) => {
  return Math.round( num * 100 + Number.EPSILON ) / 100
}

export const formatMobile = (phone) => {
  const ISRAEL_PREFIX = "+972"
  const ISRAEL_INTERNATIONAL_NUMBER = "972"
  const GENERAL_PHONE_PREFIX = "0"
  if (_.isEmpty(phone)) {
    return phone
  }
  if (phone.startsWith(ISRAEL_INTERNATIONAL_NUMBER)) {
    return GENERAL_PHONE_PREFIX + phone.replace(ISRAEL_INTERNATIONAL_NUMBER, "")
  }
  if (phone.startsWith(ISRAEL_PREFIX)) {
    return GENERAL_PHONE_PREFIX + phone.replace(ISRAEL_PREFIX, "")
  }
  return phone
}

export const useExtendedState = (initialState) => {
  const [state, setState] = useState(initialState)
  const getLatestState = () => {
    return new Promise((resolve, reject) => {
      setState((s) => {
        resolve(s)
        return s
      })
    })
  }
  return [state, setState, getLatestState]
}

export const getExporter = (fileName) => async (sheetName, columns, records) => {
  const wb = new ExcelJS.Workbook()
  const ws = wb.addWorksheet(sheetName)
  ws.columns = columns
  records.forEach((record) => {
    ws.addRow(record)
  })
  const b = await wb.xlsx.writeBuffer()
  const f = new Blob([b], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
  saveAs(f, `${fileName}.xlsx`)
}

export const getExporterV2 = async (fileName, sheetName, cb) => {
  const wb = new ExcelJS.Workbook()
  const ws = cb(wb.addWorksheet(sheetName))

  const b = await wb.xlsx.writeBuffer()
  const f = new Blob([b], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
  saveAs(f, `${fileName}.xlsx`)
}

export const Enum = (obj) => Object.freeze(obj)
