import React from "react"
import { Resource } from "react-admin"

import { PersonAdd, Settings, AddShoppingCart, LocalOffer, People, LibraryBooks } from "@material-ui/icons"

import { userCreate, userEdit, userList } from "../components/users"
import { orgCreate, orgEdit, orgList } from "../components/org"
import { orderList } from "../components/orders"
import { ItemCreateOrEdit, itemList } from "../components/items"
import { CustomersList, CustomersCreateOrEdit } from "../components/customers"
import { SystemsCreate, SystemsEdit, SystemsList } from "../components/systems"

import authStore, { RolesEnum } from './stores/auth.store'

const getRes = ({ roles, permissions }) => {

  const rolePassed = (allowedRoles) => {
    if (!roles) {
      return false
    }  

    const userRoles = roles
    const hasAccess = allowedRoles.filter(role => userRoles.includes(role)).length > 0

    return hasAccess
  } 

  return [
    rolePassed([RolesEnum.SUPER_ADMIN, RolesEnum.ORG_MANAGER, RolesEnum.POS_MANAGER]) ?
      <Resource 
        options={{ label: "customRoot.myMenu.users" }} 
        name='users' 
        list={userList} 
        edit={userEdit} 
        create={userCreate} 
        icon={PersonAdd} 
      />
    : null,
    
    rolePassed([RolesEnum.SUPER_ADMIN, RolesEnum.ORG_MANAGER]) ?
      <Resource 
        options={{ label: "customRoot.myMenu.organizations" }} 
        name='organizations' 
        list={orgList} 
        edit={orgEdit} 
        create={orgCreate} 
        icon={Settings} 
      />
    : null,
    
    rolePassed([RolesEnum.SUPER_ADMIN, RolesEnum.ORG_MANAGER, RolesEnum.POS_MANAGER]) ?
      <Resource
        options={{ label: "customRoot.myMenu.systems" }}
        name='systems'
        list={SystemsList}
        edit={SystemsEdit}
        create={SystemsCreate}
        icon={LibraryBooks}
      />
    : null,
    
    rolePassed([RolesEnum.SUPER_ADMIN, RolesEnum.ORG_MANAGER, RolesEnum.POS_MANAGER, RolesEnum.OPERATOR]) ?
      <Resource
        options={{ label: "customRoot.myMenu.orders" }}
        name='orders'
        list={orderList}
        icon={AddShoppingCart} 
      />
    : null,
    
    rolePassed([RolesEnum.SUPER_ADMIN, RolesEnum.ORG_MANAGER, RolesEnum.POS_MANAGER, RolesEnum.OPERATOR]) ?
      <Resource
        options={{ label: "customRoot.myMenu.products" }}
        name='products'
        list={itemList}
        edit={ItemCreateOrEdit}
        create={ItemCreateOrEdit}
        icon={LocalOffer}
      />
    : null,
     
    rolePassed([RolesEnum.SUPER_ADMIN, RolesEnum.ORG_MANAGER, RolesEnum.POS_MANAGER, RolesEnum.OPERATOR]) ?
      <Resource
        options={{ label: "customRoot.myMenu.customers" }}
        name='customers'
        list={CustomersList}
        edit={CustomersCreateOrEdit}
        create={CustomersCreateOrEdit}
        icon={People}
      />
    : null,
  ]
}

export default getRes
