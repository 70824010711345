import { paymentsOS } from "./settings"

export const isDinersCreditCard = (vendor) => /DINERS/i.test(vendor);
export const isAmericanCreditCard = (vendor) => /AMERICAN/i.test(vendor);
export const invalidExpiration = (json) => (json.category === paymentsOS.errorResponse.apiError
    || json.category === paymentsOS.errorResponse.clientError)
  && /expiration date/i.test(json.more_info);
export const invalidCreditCardNumber = (json) => json.category === paymentsOS.errorResponse.clientValidationError
  || json.sub_category === paymentsOS.errorResponse.invalidCardNumber;
export const invalidCVV = (json) => json.sub_category === paymentsOS.errorResponse.invalidCardCvv;
