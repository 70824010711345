import React from "react"
import styled from "styled-components"
import { makeStyles } from "@mui/styles"
import { useNavigate } from 'react-router-dom';
import { Create, FormDataConsumer, SimpleForm, TextInput, PasswordInput, required, minLength, maxLength, email, SelectInput, usePermissions, useTranslate, useNotify } from "react-admin"
import { observer, inject } from 'mobx-react'

const validateFirstName = [required(), minLength(2), maxLength(15)]
const validatePassword = [required(), minLength(7)]
const validateEmail = [required(), email()]

const SimpleFormStyled = styled(SimpleForm)`
  @media (min-width: 800px) {
    width: 50%;
  }  

  width: 100%;
  .ra-input {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "256px"
  }
}))

const UserCreate = ({ authStore, ...props }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const navigate = useNavigate();
  const notify = useNotify()

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/users');
  };

  const onError = (error) => {
    if(error.status == 400) {
      notify(translate(`ra.message.${error.body.error}`), { type: 'error' });   
    } else {
      notify(translate(`ra.message.error`), { type: 'error' });
    }
  };

  const roles = {
    admin: translate("customRoot.roles.super admin"),
    org_manager: translate("customRoot.roles.organization manager"),
    pos_manager: translate("customRoot.roles.pos manager"),
    operator: translate("customRoot.roles.operator")
  }

  return (
    <Create {...props} mutationOptions={{ onSuccess, onError }} mutationMode="pessimistic">
      <SimpleFormStyled>
      <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
        <TextInput
          label='ra.auth.username'
          source='username'
          validate={validateFirstName}
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off"
            }
          }}
        />
        <PasswordInput
          label='ra.auth.password'
          source='password'
          type='password'
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off"
            }
          }}
          validate={validatePassword}
        />
        <TextInput
          label='customRoot.users.create.first_name'
          source='firstName'
          validate={validateFirstName}
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off"
            }
          }}
        />
        <TextInput
          label='customRoot.users.create.last_name'
          source='lastName'
          validate={validateFirstName}
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off"
            }
          }}
        />
        <TextInput
          label='customRoot.users.list.email'
          source='email'
          validate={validateEmail}
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off"
            }
          }}
        />
        <TextInput
          label='customRoot.users.list.phone'
          source='phoneNumber'
          validate={required()}
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off"
            }
          }}
        />
        <SelectInput
          label='customRoot.users.list.role'
          source='roles'
          choices={authStore.permissionCreateRoles(roles)}
          validate={required()}
          inputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off"
            }
          }}
        />
        </>)
        }}
        </FormDataConsumer>
      </SimpleFormStyled>
    </Create>
  )
}
export default inject('authStore')(observer(UserCreate))
