import React, {useEffect, useState} from "react"
import styled from "styled-components"
import { makeStyles } from "@mui/styles"
import { observer, inject } from 'mobx-react'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  ImageInput,
  ImageField,
  Button,
  BooleanInput,
  useInput,
  required,
  Toolbar,
  SaveButton,
  DeleteButton,
  maxLength,
  useNotify,
  useTranslate,
  usePermissions,
  FormDataConsumer
} from "react-admin"
import CreateOrEdit from "../common/CreateOrEdit"
import { Navigate, useLocation } from "react-router-dom"
import ImagePicker from "./imagePicker"
import useFetch from "use-http";
import {RoutesEnum} from "../../core/restProvider";
import Spinner from "../common/Spinner";


const SimpleFormStyled = styled(SimpleForm)`
  @media (min-width: 800px) {
    width: 50%;
  }  

  width: 100%;
  .ra-input {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const ImageWrap = styled.div`
  width: 256px;
  margin-top: 1rem;
`

const SImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Switcher = styled(BooleanInput)`
  margin-top: 25px;
`

const Image = ({uploadedImage, ...props}) => {
  const { field: { value, onChange }} = useInput(props)
  const onClear = () => { 
    onChange("")
    props.clearUploadedImage()
  }

  if (uploadedImage) {
    return <ImageWrap>
    <SImage src={uploadedImage} alt='' />
    <Button label='customRoot.items.clear' onClick={onClear} style={{ color: "#cd3636" }} />
  </ImageWrap>
  }

  if (!value || value === "") {
    return null
  }

  return (
    <ImageWrap>
      <SImage src={value} alt='' />
      <Button label='customRoot.items.clear' onClick={onClear} style={{ color: "#cd3636" }} />
    </ImageWrap>
  )
}

const SImageInput = styled(ImageInput)`
  width: 256px;
  border: 1px dashed #ddd;
`

const StyledImageField = styled(ImageField)`
  display: flex;
  justify-content: center;
  
  img {
    width: 100%;
  }
`

const Or = styled.div`
  width: 256px;
  padding: 1rem 110px;
`

const Loading = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 13vw;
  min-height: 15vh;
`

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    width: "256px"
  }
}))

const ItemCreateOrEdit = ({ authStore, ...props }) => {
  let location = useLocation();
  const classes = useStyles()
  const notify = useNotify()
  const translate = useTranslate()

  const [open, setOpen] = useState(false)
  const [ids, setIds] = useState(null)
  const [itemImage, setItemImage] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const resultStorageByResource = authStore.getResource(RoutesEnum.PRODUCTS)
    const resultStorageByIds = authStore._ids

    const orgIdStorage = resultStorageByResource?.org_id || resultStorageByIds?.org_id
    const systemIdStorage = resultStorageByResource?.system_id || resultStorageByIds?.system_id

    if (orgIdStorage && systemIdStorage) {
      setIds({org_id: orgIdStorage, system_id: systemIdStorage})
    }
  }, [])

  const clearUploadedImage = () => {
    setItemImage('')
  }

  const onClick = () => setOpen(true)
  const { post } = useFetch(`/api/v1/organizations/${ids?.org_id}/systems/${ids?.system_id}/upload`, { cachePolicy: "no-cache" })

  const addNewImg = (file) => {
    if (!file) {
      setItemImage(null)
    }

    if (file) {
      const form = new FormData()
      form.append("file", file)
      setLoading(true)

      post(form).then((resp) => {
        if (resp.data) {
          setItemImage(resp.data.image);
        }

        if (resp.errors) {
          notify(translate(`ra.validation.${resp.errors[0].msg}`), { type: "error" })
        }

        setLoading(false)
      })
    }
  }

  const isCreate = /create/.test(location.pathname)
  const { system_id } = location.state || {}

  //console.log(system_id)

  /*if (isCreate && !system_id) {
    return <Navigate to='/products' />
  }*/

  const convertStringToNumber = (value) => {
    const float = parseFloat(value)
    return isNaN(float) ? 0 : float
  }

  const convertStringPriceToNumber = (value) => {
    const float = parseFloat(value)
    return isNaN(float) ? 0 : float
  }

  const SSaveButton = props => {
    const transform = (data) => {
      data.image = !itemImage ? data.image : itemImage
      data.file = undefined
      return data;
    }
    return <SaveButton type="button" {...props} transform={transform} />;
   }

  const ProductEditToolbar = (props) => {
    return (
        <Toolbar className={classes.toolbar} {...props}>
          <SSaveButton/>

          {authStore.isDeleteProductPermissions() && <DeleteButton/>}
        </Toolbar>
    )
  }

  const validate = [required(), maxLength(256)]

  return (
    <CreateOrEdit {...props} redirect='list' title='resources.items.name'>
      <SimpleFormStyled  toolbar={<ProductEditToolbar />}>
      <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
        <TextInput source='name' label='customRoot.items.name' validate={validate} />
        <TextInput source='barcode' label='customRoot.items.barcode' validate={maxLength(256)} />
        <TextInput source='category' label='customRoot.items.category' validate={validate} />
        <TextInput source='description' label='customRoot.items.description' validate={maxLength(256)} />
        <TextInput
          source='sortOrder'
          label='customRoot.items.sort_order'
          helperText='customRoot.items.sort_order_helper'
          parse={convertStringToNumber}
          validate={maxLength(100000)}
        />
        <TextInput source='weight' label='customRoot.items.weight' parse={convertStringToNumber} validate={maxLength(256)} />
        <TextInput source='quantity' label='customRoot.items.quantity' parse={convertStringToNumber} validate={maxLength(256)} />
        <TextInput 
          class="MuiFormControl-root MuiTextField-root ra-input ra-input-discount muirtl-17lk10m-MuiFormControl-root-MuiTextField-root-RaResettableTextField-root priceProductInput"
          source='price' 
          label='customRoot.items.price' 
          parse={convertStringPriceToNumber} 
          validate={validate} 
        />

        <TextInput source='discount' style={{ display: 'none' }} label='customRoot.items.discount' parse={convertStringToNumber} />

        <SImageInput source='file' accept='image/*' label='customRoot.items.image' onChange={addNewImg}>
          {loading ? (
              <Loading>
                <Spinner />
              </Loading>
          ) : (<StyledImageField source='image' />)}

        </SImageInput>
        <Or>אוֹ</Or>
        <Button style={{ width: '100%' }} label='customRoot.items.select' variant='contained' onClick={onClick}/>
        <Image source='image' uploadedImage={itemImage} clearUploadedImage={clearUploadedImage}/>
        <ImagePicker 
          ra_props={props} 
          orgId={ids?.org_id || ''} 
          systemId={ids?.system_id || ''} 
          source='image' 
          open={open} 
          onClose={() => setOpen(false)} 
        />
        <SelectInput
          style={{ marginTop: '20px' }}
          key={'status'}
          validate={required()}
          source='status'
          label={translate('ra.report_pos_system_admin.columns.status')}
          optionText={({ name }) => translate(`customRoot.products.status.${name}`)}
          optionValue='name'
          choices={[{name: "available"}, {name: "hidden"}, {name: "unavailable"}]}
        />
        </>
        )
          }}
          </FormDataConsumer>
      </SimpleFormStyled>
    </CreateOrEdit>
  )
}

export default inject('authStore')(observer(ItemCreateOrEdit))
