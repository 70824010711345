import React from "react"
import { Create, Edit } from "react-admin"
import { useLocation } from 'react-router-dom';

function CreateOrEdit({ ...props }) {
  let location = useLocation();
  const isCreate = /create/.test(location.pathname)
  return isCreate ? <Create {...props} mutationMode="pessimistic"/> : <Edit {...props} mutationMode="pessimistic"/>
}

export default CreateOrEdit
