import { Add } from "@material-ui/icons"
import React from "react"
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  TextInput,
  useListController,
  ReferenceInput,
  useResourceContext,
  SelectInput,
  Filter,
  ExportButton,
  Link,
  TopToolbar,
  useListContext,
  useTranslate,
  FunctionField,
  AutocompleteInput,
  usePermissions,
  SearchInput
} from "react-admin"
import styled from "styled-components"
import { Button } from "@mui/material"
import { EditButton } from "../common/EditButton"
import xlsx from "xlsx"
import ActionToolbar from "../common/ActionToolbar"
import { observer, inject } from 'mobx-react'
import FieldTitle from "../common/FieldTitle"
import { AutocompleteInputStyled, TextInputStyled } from "../common/InputFields"


function convertToUser({ username, role, id, name = "", org_id = 0, system_id = 0, phone = "", email = "" }) {
  return {
    username,
    role,
    id,
    name,
    org_id,
    system_id,
    phone,
    email
  }
}

const exporter = (data) => {
  const sdata = data.map((d) => convertToUser(d))
  const wb = xlsx.utils.book_new()
  const s = xlsx.utils.json_to_sheet(sdata, { header: ["username", "role", "id", "name", "org_id", "system_id", "phone", "email"] })

  xlsx.utils.book_append_sheet(wb, s, "Sheet 1")
  xlsx.writeFile(wb, `users.xlsx`)
}

const ItemsFilter = ({ isAdmin, isPos, isManager, ...props }) => {
  const { filterValues } = props.cprops
  const secondFilter = filterValues ? { filter: { org_id: filterValues.org_id } } : {}


  return (
    <Filter {...props}>
      {(isAdmin || isManager) && (
        <ReferenceInput
          label='resources.organizations.singular'
          reference='organizations'
          source='org_id'
          alwaysOn
          perPage={1000}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInputStyled
            className={`autocompeleteMekome autocompeleteMekome-200`}
            label='resources.organizations.singular'
            matchSuggestion={(filter, choice) => choice.name.match(filter)}
            optionText="name" 
            optionValue="id"
          />
        </ReferenceInput>
      )}
      {!isPos && (
        <ReferenceInput    
          label='customRoot.organizations.list.system'
          reference='systems'
          source='system_id'
          alwaysOn
          {...secondFilter}
          perPage={1000}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInputStyled
            className="autocompeleteMekome"
            label='customRoot.organizations.list.system'
            matchSuggestion={(filter, choice) => choice.name.match(filter)}
            optionText="name" 
            optionValue="id"
          />
        </ReferenceInput>
      )}

      <TextInputStyled
        source='name' 
        
        alwaysOn 
        resettable
       />
    </Filter>
  )
}

const CButton = styled(Button)`
  color: #2196f3;
  padding: 2px;
`

const Actions =  inject('authStore')(observer(({ maxResults, authStore, disabledHandler, ...props }) => {
  const { currentSort, filterValues, total } = useListContext()
  const resource = useResourceContext();

  const { org_id, system_id } = filterValues

  const translate = useTranslate()

  return (
    <TopToolbar>
      {authStore.isCreateUserPermissions() && (
        <CButton
          component={Link}
          onClick={() => authStore.setResource(resource, { org_id, system_id })}
          disabled={disabledHandler()}
          to={{
            pathname: `/users/create?org_id=${org_id}&system_id=${system_id}`,
            state: {
              org_id,
              system_id
            }
          }}
        >
          <Add />
          {translate("ra.action.create")}
        </CButton>
      )}

      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filterValues={filterValues} maxResults={maxResults} />
    </TopToolbar>
  )
}))

const UserList = ({ authStore, ...props }) => {
  const resource = useResourceContext();
  const controllerProps = useListController(props)
  const { filterValues } = controllerProps
  const { org_id, system_id } = filterValues

  const resultStorageByIds = authStore.getSettings()
  const orgId = org_id || resultStorageByIds?.org_id
  const systemId = system_id || resultStorageByIds?.system_id

  const translate = useTranslate()
  
  const isAdmin = authStore.isSuperAdmin()
  const isManager = authStore.isOrgManager()
  const isPos = authStore.isPosManager()

  const disabledHandler = () => {
    if (isAdmin || isManager) {
      return !orgId || !systemId
    }
    if (isManager) {
      return !systemId
    }
    return false
  }

  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<ItemsFilter cprops={controllerProps} isAdmin={isAdmin} isPos={isPos} isManager={isManager} />}
      actions={<Actions {...props} disabledHandler={disabledHandler} />}
      exporter={exporter}
    >
      <Datagrid  bulkActionButtons={false}>
        <TextField source='firstName' label={<FieldTitle label='customRoot.users.create.first_name'/>} sortable={false} />
        <TextField source='username' label={<FieldTitle label='ra.auth.username'/>} sortable={false} />
        <FunctionField
          render={(record) => Array.isArray(record.roles) && translate(`customRoot.roles.${record.roles[0]}`)}
          label={<FieldTitle label='customRoot.users.list.role'/>}
          sortable={false}
        />
        <EmailField source='email' label={<FieldTitle label='customRoot.users.list.email'/>} sortable={false} />
        <TextField source='phoneNumber' label={<FieldTitle label='customRoot.users.list.phone'/>} sortable={false} />
        {authStore.isEditUserPermissions() && (
          <ActionToolbar>
            <EditButton query={`?org_id=${orgId}&system_id=${systemId}`} disabled={disabledHandler()} onClick={() => authStore.setResource(resource, { org_id: orgId, system_id: systemId })} />
          </ActionToolbar>
        )}
      </Datagrid>
    </List>
  )
}

export default inject('authStore')(observer(UserList))
