import authProvider from './authProvider'

const baseUrl = process.env.REACT_APP_API
const options = {
    credentials: "include",
    headers: new Headers({
        Accept: "application/json"
    })
}

export default {
    generateCredsForExternalClient: async (organizationId, systemId) => {
      const response = await fetch(`${baseUrl}/api/v1/organizations/${organizationId}/systems/${systemId}/external/credentials`, {
        method: 'POST',
        credentials: "include",
        headers: new Headers({
          Accept: "application/json"
        }),
        body: JSON.stringify({ newClient: 1 })  
      })

      return response.json()
    }, 
    getCredsForExternalClient: async (organizationId, systemId) => {
      const response = await fetch(`${baseUrl}/api/v1/organizations/${organizationId}/systems/${systemId}/external/credentials`, options)
      return response.json()
    }, 
    
    validatePasswordToken: async (token) => {
        const response = await fetch(`${baseUrl}/api/v1/password/reset/${token}`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
            })
        })
        
        return response
    }, 
    resetPassword: async (token, data) => {
        const response = await fetch(`${baseUrl}/api/v1/password/reset/${token}`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(data)
        })
        
        return response
    } ,
    sendEmailToResetPassword: async (email) => {
        const response = await fetch(`${baseUrl}/api/v1/password/reset/send`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ email })
        })

        return await response.json()
    },
    sendSmsToCharge: async (orderId, phone) => {
        const response = await fetch(`${baseUrl}/api/v1/send_sms_to_charge`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ order_id: orderId, phone: phone })
        })
        return await response.json()
    }
}