import authStore from "../core/stores/auth.store"

const isPublic = (hash) => {
  const routes = [
    /^#\/404$/g,
    /^#\/payment-form$/g,
    /^#\/success$/g,
    /^#\/password\/send$/g,
    /^#\/password\/reset\/[a-zA-z0-9_]+$/g,
    /^#\/r\/[a-zA-z0-9_]+$/g,  
    /^#\/order\/[a-zA-z0-9_]+$/g,
    /^#\/order\/[a-zA-z0-9_]+\/checkout$/g,
    /^#\/order\/[a-zA-z0-9_]+\/charge$/g,
    /^#\/order\/[a-zA-z0-9_]+\/success$/g
  ]

  const mathedRoutes = routes.filter(route => route.test(hash))
  return mathedRoutes.length > 0;
} 

export default {
  login: ({ username, password }) => {
    const p = authStore.login({ username, password }).then(res => res)
    return p
  },

  logout: () => {
    const p = authStore.logout().then(res => null);
    return p;
  },

  checkError: (error) => {
    if (error.status === 401) {
      return Promise.reject()
    }
    
    return Promise.resolve()
  },

  checkAuth: () => {
    if (isPublic(window.location.hash)) {
      return Promise.resolve()
    }

    const res =  authStore.checkAuth()
    .then(res => {
      return res;
    }).catch(err => { console.error(err); throw err });

    return res
  },

  getPermissions: () => {  
    if (isPublic(window.location.hash)) {
      return Promise.resolve(1)
    }

    if (authStore.isAutenticated) {
      Promise.resolve({ roles: authStore.getRoles(), permissions: authStore._perms })
    }

    return authStore.checkAuth().then(res => ({ roles: authStore.getRoles(), permissions: authStore._perms })) 
  },

  getSettings: () => {
    const settings = null
    return settings ? Promise.resolve(settings) : Promise.reject()
  },

  getToken: () => ""
}
