import { Button, ListItemText } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslate } from "react-admin"
import styled from 'styled-components'
import Text from './Text'
import theme from '../utils/theme'

const SListItem = styled.div`
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
`
const Category = styled.div`
  color: white;
  font-weight: 300;
  display:inline-block;
  background: ${p => p.color};
  box-shadow: 0 5px 9px -4px ${p => p.color};
  padding: 0 0.5rem;
  border-radius: 8px;
  font-size: 0.75rem;
`
const Measure = styled(Text)`
  color: ${theme.color.grey};
  text-align: right;
  white-space: nowrap;
  font-size: 0.8em;
`
const Price = styled(Text)`
  text-align: right;
  white-space: nowrap;
  direction: ltr;
`
const AddButton = styled(Text)`
  text-align: right;
  color: ${theme.color.blue}
`

const secondaryTypographyProps = { component: 'div' }

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`
const ImageWrap = styled.div`
  width: 100px;
  height: 100px;
  margin-left: 1rem;
  border-radius: 8px;
`

const ProductItem = ({ handleSelect, id, name, measure, price, category, image }) => {
  const t = useTranslate()
  const hue =  useMemo(() => category.split('').reduce((num, curr, index) => index + curr.charCodeAt(0) + ((num << 5) - num), 0) % 360, [category])
  const color = `hsla(${hue}, 45%, 60%, 75%)`
  return (
    <SListItem onClick={handleSelect({
      id,
      name,
      measure,
      price,
      category
    })}>
      <div style={{ width:'70%', display: 'flex' }}>
        {image && <ImageWrap><Image src={image} alt={name + ' image'} height='100' /></ImageWrap>}
        <ListItemText
          primary={name}
          secondaryTypographyProps={secondaryTypographyProps}
          secondary={<Category color={color}>{category}</Category>}
        />
      </div>
      <div style={{ width: '15%' }}>
        <Price thin>₪ {price}</Price>
        <Measure thin>{''}</Measure>
      </div>
      <Button>
        <AddButton name={`${t`publicOrder.add_to_cart`} ${name}`}>{t`publicOrder.add_to_cart`}</AddButton>
      </Button>
    </SListItem>
  )
}

export default ProductItem