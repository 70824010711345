import React, { useState, useEffect, Fragment } from "react"
import { makeStyles } from "@mui/styles"
import { IconButton, DialogContent, Dialog, DialogTitle} from '@mui/material';
import styled from "styled-components"
import DownloadIcon from "@material-ui/icons/GetApp"
import IconCancel from '@material-ui/icons/Cancel';
import { restProvider } from "../../../core"
import { RoutesEnum } from "../../../core/restProvider"
import { getExporterV2 } from "../../../tools/utils"
import { observer, inject } from 'mobx-react'
import moment from "moment-timezone"
import { 
  useNotify,
  useTranslate, 
  SimpleForm,
  TextInput,
  useDataProvider, 
  GET_ONE, 
  GET_LIST,
  Button, 
  SelectInput, 
  Toolbar,
  SaveButton,
  FormDataConsumer
} from "react-admin"

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "256px"
  }
}))

const CancelButton = styled(Button)`
  padding: 6px 16px;
  color: white;
  line-height: 1.75;
  background: linear-gradient(160deg, rgb(193, 54, 54) 0%, rgb(193, 54, 54) 100%);
  box-shadow: rgb(193, 54, 54 / 75%) 0px 6px 10px -4px;
  border-radius: 4px;
  margin-right: 10px;
  border: none !important;
  font-size: 0.875rem;
  font-weight: 500;
`
const SimpleFormStyled = styled(SimpleForm)`
  .ra-input {
    display: flex;
    justify-content: center;
  }
`
const StyledToolbar = styled(Toolbar)`
  justify-content:center;
  background-color: white;
`

const ExportPaymentWithCreditCard = ({ authStore, filterValues, currentSort }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  
  const isAdmin = authStore.isSuperAdmin()

  const [openModal, setModalStatus] = useState(false)
  const [organizations, setOrganizationList] = useState([])
  const [systems, setSystemList] = useState([])
  const [showSystems, setShowSystems] = useState(false)
  
  const query = {
    organizationId: '',
    systemId: '',
    timezone: 'Asia/Tel_Aviv',
    date: ''
  }

  const handleCloseClick = () => {
    setModalStatus(false)  
  }

  const handleOpenClick = () => {
    setModalStatus(true)  
  }

  useEffect(() => {
    let mounted = true
    getOrganizations(mounted)
    
    return () => (mounted = false)
  }, [])

  const getOrganizations = async (mounted) => {
    try {
      let orgs = []

      if (isAdmin) {
        const { data } = await restProvider(GET_LIST, RoutesEnum.ORGANIZATIONS, { filter: {}, sort: {}, pagination: { page: 1, perPage: 1000 } })
        orgs = data
      } else {
        const { data } = await restProvider(GET_ONE, RoutesEnum.ME, {  })
        orgs = [data.organization]
      }

      if (mounted) {
        setOrganizationList(orgs)
      }
    } catch (error) {
      notify(translate('requestResult.error'), { type: "error" })
    }
  }

  const getSystems = async (orgId) => {
    try {
      const { data } = await restProvider(GET_LIST, RoutesEnum.SYSTEMS, {
        filter: { org_id: orgId },
        sort: {},
        pagination: { page: 1, perPage: 1000 }
      })

      setSystemList(data)

    } catch (error) {
      notify(translate('requestResult.error'), { type: "error" })
    }
  }

  const onChangeOrganization = (obj) => {
    const resourceId = obj.target.value
    getSystems(resourceId)
    setShowSystems(true)
  }

  const ExportToolbar = (props) => {
    return (
      <StyledToolbar {...props}>
        <SaveButton
          label="ra.action.export"
          icon={<DownloadIcon />}
        />    
        <CancelButton type="button"
          label="ra.action.cancel"
          onClick={handleCloseClick}
          variant="contained"
          size="medium"
        >
          <IconCancel />
        </CancelButton>
      </StyledToolbar>
    )
  }

  const handleValidate = (values) => {
    const errors = {}

    if (!values.organizationId) {
      errors.organizationId = translate('ra.validation.required')
    }

    if (!values.systemId) {
      errors.systemId = translate('ra.validation.required')
    }

    if(!values.date) {
      errors.date = translate('ra.validation.required')
    } else if (!/^([1-9]|1[012])\/\d{4}$/i.test(values.date)) {
      errors.date = translate('ra.validation.invalid_date_format')
    }

    return errors
  }

  const handleSave = (data) => {
    const { 
      organizationId,
      systemId,
      timezone,
      date
    } = data

    dataProvider(GET_ONE, RoutesEnum.REPORT_WITH_PAID_ORDERS, {
      organizationId,
      systemId,
      query: {
        timezone,
        date  
      }  
    }).then((res) => {
      const { data, meta } = res
      exportToXls(data, meta)
    })
  }

  const exportToXls = async (data, meta) => {
    try {
      const fileName = `${meta.posName.replace('/\s+/', '_')}_${meta.month}_${meta.year}`
      const sheetName = `${meta.month}-${meta.year}` 

      getExporterV2(fileName, sheetName, (ws) => {
        const minimalWidth = 10

        ws.addRow([`POS Number: ${meta.posNumber}`, '',  `POS name: ${meta.posName}`])
        ws.addRow([`Year: ${meta.year}`, '', `Month: ${meta.month}`])
        ws.addRow(['', '', '', '', ''])
        ws.addRow(['Date', 'First Name', 'Last Name', 'Budget Number', 'Social ID', 'Transaction Number', 'Amount', 'Phone', 'Items'])
        
        data.map((item) => {
          ws.addRow([
            moment.utc(item.createdAt).tz('Asia/Tel_Aviv').format("DD-MM-YYYY HH:mm:ss"), 
            item.firsName, 
            item.lasName, 
            item.budgetNumber,
            item.socialId,
            item.transactionNumber,
            item.amount,
            item.phone,
            item.items.join(', ')
          ])
        })
        
        ws.getRow(4).font = {bold: true };

        ws.columns.forEach((column) => {
          let maxColumnLength = 0;
          column.eachCell({ includeEmpty: true }, (cell) => {
            maxColumnLength = Math.max(
              maxColumnLength,
              minimalWidth,
              cell.value ? cell.value.toString().length : 0
            );
          });

          column.width = maxColumnLength + 2;
        });

        return ws
      })
    } catch (e) {
      notify(translate('requestResult.error'), { type: "error" })
    }
  }
    
  return (
    <Fragment>
      <IconButton size='small' color='primary' onClick={handleOpenClick}>
        <DownloadIcon />
        {translate("customRoot.creditReport.title")}
      </IconButton>

      <Dialog
        fullWidth
        open={openModal}
        onClose={handleCloseClick}
        aria-label={translate("customRoot.creditReport.title")}
       >
        <DialogTitle>{translate("customRoot.creditReport.title")}</DialogTitle>
        <DialogContent>
          <SimpleFormStyled
            style={{alignItems: "center", justifyContent: "center"}} 
            name='do1' 
            record={query} 
            onSubmit={handleSave} 
            toolbar={<ExportToolbar/>}
            validate={handleValidate}
          >
            <FormDataConsumer>
          {({ formData }) => {
            return (
              <div className={classes.formContainer}>
            <TextInput 
              source="date" 
              placeholder="9/2022"
              label="customRoot.orders.list.date" 
            />
            <SelectInput
              source='organizationId'
              optionText='name'
              optionValue='id'
              label='customRoot.myMenu.organizations'
              choices={organizations}
              onChange={onChangeOrganization}
            />
            {showSystems && (
              <SelectInput
                source='systemId'
                optionText='name'
                optionValue='id'
                label='customRoot.myMenu.systems'
                choices={systems}
              />
            )}
            </div>
            )
          }}
          </FormDataConsumer>
          </SimpleFormStyled>
        </DialogContent>
      </Dialog>
    </Fragment>
  );   
}

export default inject('authStore')(observer(ExportPaymentWithCreditCard))
