// in src/Menu.js
import React, { forwardRef, useEffect, useState } from 'react'

import { useMediaQuery } from "@mui/material"
import { MenuItemLink, useResourceDefinitions, useTranslate, usePermissions, useSidebarState } from "react-admin"
import MenuItem from '@mui/material/MenuItem';
import DefaultIcon from "@material-ui/icons/ViewList"
import Brightness5Icon from "@material-ui/icons/Brightness5"
import { observer, inject } from 'mobx-react'
import StoreIcon from "@material-ui/icons/Store"
import { ReleaseNotesDialog } from "../components/common/ReleaseNotesDialog"

const Menu = ({ authStore, onMenuClick, logout }) => {
  const translate = useTranslate()
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"))
  const [open] = useSidebarState();
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
  const [hasOrderSettingsPerms, changeOrderSettingsPerms] = useState(authStore.isEditOrderSettingsPermissions())

  useEffect(() => {
    changeOrderSettingsPerms(authStore.isEditOrderSettingsPermissions())
  }, [authStore._perms])

  return (
    <div>
      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translate(`customRoot.myMenu.${resource.name}`)}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {hasOrderSettingsPerms && (
        <MenuItemLink
          key={"order settings"}
          to={`/order-settings`}
          primaryText={translate(`customRoot.myMenu.order_settings`)}
          leftIcon={<Brightness5Icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}

      <MenuItemLink 
        key={"cashier"}
        to='/cashier' 
        primaryText={translate(`customRoot.myMenu.cashier`)} 
        leftIcon={<StoreIcon />} 
        onClick={onMenuClick} 
        sidebarIsOpen={open}
      />

      {isXSmall && logout}
      <ReleaseNotesDialog/>
    </div>
  )
}

export default inject('authStore')(observer(Menu))
