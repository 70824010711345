const toSave = any => typeof any === 'object' ? JSON.stringify(any) : any

const veryLocalStorage = {
  set(key, value) {
    try {
      localStorage.setItem(key, toSave(value))
    } catch {
      console.error('--- very Local Storage.js -> set -> ', key)
    }
  },

  get(key) {
    const data = localStorage.getItem(key)
    try {
      const json = JSON.parse(data)
      return json
    } catch {
      return null
    }
  },

  remove(key) {
    try {
      localStorage.removeItem(key)
    } catch {
      console.error('--- very Local Storage.js -> remove -> ', key)
    }
  }
}

export default veryLocalStorage