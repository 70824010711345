import * as React from 'react';
import { styled as MuiStyled } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { version } from "../../../package.json"
import styled from "styled-components";
import relNotes from '../../ReleaseNotes.txt';
import { useTranslate } from "react-admin"

const BootstrapDialog = styled(Dialog)``

const SIconButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  color: gray;
`

const SDialogTitle = styled(DialogTitle)`
  & button {
    padding: 0px;
  }
`

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <SDialogTitle {...other}>
      {children}
      {onClose ? (
        <SIconButton
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </SIconButton>
      ) : null}
    </SDialogTitle>
  );
}

export const ReleaseNotesDialog = () => {
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [notes, setNotes] = React.useState('');
  const handleClickOpen = () => {
    fetch(relNotes)
      .then(r => r.text())
      .then(text => {
        setNotes(text);
      });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className='versionDialogView' onClick={handleClickOpen}>v{version}</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={0}
      >
        <BootstrapDialogTitle className="versionDialogTitle" id="customized-dialog-title" onClose={handleClose}>
          <span>{`Release Note v${version}`}</span>
        </BootstrapDialogTitle>
        <DialogContent className='versionDialogContent' dividers>
        What's new:
          <Typography variant="subtitle2" gutterBottom>
            <pre>{notes}</pre>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}