import React from 'react'
import styled from 'styled-components'
import { Button as MUIButton } from '@mui/material'

const SButton = styled(MUIButton)`
  color: white;
  background: ${p => p.background || 'linear-gradient(160deg, hsl(127, 53%, 68%) 0%, hsl(121,60%,37%) 100%)'};
  box-shadow: 0 6px 10px -4px hsla(121,60%,37%, 0.75);
  border-radius: 8px;
  border: none !important;
  ${p => p.fullwidth && 'width: 100%;'}
  &.Mui-disabled {
    opacity: 0.7;
    color: white;
  }
`

const Button = props => <SButton {...props} />

export default Button