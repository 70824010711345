import React, { Fragment } from "react"
import {  useTranslate } from "react-admin"
import styled, { keyframes } from "styled-components"
import { DialogTitle, DialogContent, DialogActions, Dialog, Button } from "@mui/material"

export const StyledButton = styled(Button)`
  {
    color: red;
  }
`;

const SaveCreditCardConfirmdDialog = ({ open, handleConfirm, handleClose, ...props }) => {
    const t = useTranslate()

    return (
      <Fragment>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id='form-dialog-title'>
            {t("confirm_dialog")}
          </DialogTitle>
          <DialogContent>
            {t("customRoot.cashier.confirm_save_cc_info")}
          </DialogContent>
          <DialogActions style={{ justifyContent: "flex-start" }}>
            <StyledButton onClick={handleClose} variant="outlined" color="error">
              {t('customRoot.orders.modal.cancel')}
            </StyledButton>            
            <Button onClick={handleConfirm} variant="outlined">
              {t('customRoot.orders.modal.confirm')}
            </Button> 
          </DialogActions>
        </Dialog>
      </Fragment>
    )
}


export default SaveCreditCardConfirmdDialog