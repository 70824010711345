import React, { useState, useRef, cloneElement, useEffect } from "react"
import { observer, inject } from 'mobx-react'
import { useTranslate, useNotify } from "react-admin"
import { Page, Space, Col, SearchInput, ProductItem, Accessibility, Text, Loading, Add } from "../components"
import { useParams, useNavigate } from "react-router-dom"
import { LocalMallOutlined } from '@mui/icons-material'
import { List, AutoSizer } from 'react-virtualized'
import { DesktopCart } from "../cart"
import styled from "styled-components"
import { isMobile } from "../utils/helpers"

const PPage = styled(Page)`
  padding-top: 20px;
  label {
    position: absolute;
  }
  .ReactVirtualized__List {
    scrollbar-width: none;
    padding-bottom: 150px;
    & ::-webkit-scrollbar {
      width: 0;
    }
  }
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Disabled = styled.img`
  position: absolute;
  top: 2rem;
  right: 1rem;
  width: 32px;
  height: 32px;
  opacity: 0.6;
  @media (max-width: 700px) {
    top: 9rem;
  }
  body[dir='rtl'] & {
    left: 1rem;
    right: unset;
  }
`

const disabled = ''
const s = { direction: 'inherit' }
const renderer = (data, listItem) => ({ index, key, style }) => <div key={key} style={style}>{cloneElement(listItem, { index, ...data[index] })}</div>

const days = [
  'יום ראשון', 'יום שני', 'יום שלישי', 'יום רביעי', 'יום חמישי', 'יום שישי', 'יום שישי ערב',
  'ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שישי ערב'
]

const Home = ({ productStore, cartStore }) => {
  const t = useTranslate();

  const params = useParams()
  const navigate = useNavigate();

  const [accessibility, setAccessibility] = useState(false)
  const [category, setCategory] = useState('all')
  const [selected, setSelected] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [search, setSearch] = useState('')

  const list = useRef()
  const categories = []

  const handleFilter = e => setCategory(e.target.value)
  const handleChange = e => {
    setSearch(e.target.value)
    productStore.getItems(params.id, e.target.value)
    //if (list.current) list.current.scrollToPosition(0)
  }
  const handleSelect = item => () => {
    setSelected(true)
    setSelectedItem(item)
  }
  const handleClose = () => setSelected(false)
  const handleAdd = (item) => {
    if (item.quantity) {
      cartStore.addItem(item)
    }
    setSelected(false)
  }

  useEffect(() => {
    productStore.getItems(params.id).then((res) => {
      if (!res) navigate('/404')
    })
  }, [])

  return (
    <>
      <PPage>
      <Col style={{ minHeight: '85vh' }}>
          <SearchInput 
            value={search} 
            category={category} 
            categories={categories} 
            handleChange={handleChange} 
            handleFilter={handleFilter}
            setSearch={setSearch} 
          />
          <Space height={1} />
          <Row>
            <Text.H6 bold>{productStore.systemName}</Text.H6>
            <Text>{productStore.orgName}</Text>
          </Row>
          {productStore.loading && <Loading />}
          {!productStore.items.length && !productStore.loading && (
            <div style={{ textAlign: 'center' }}>
              <Space height={2} />
              <LocalMallOutlined style={{ color: 'rgb(220, 0, 78)', fontSize: '2rem' }} />
              <Text style={{ textAlign: 'center' }}>{t`publicOrder.no_items`}</Text>
            </div>
          )}
          {productStore.items.length > 0 && !productStore.loading && (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  ref={list}
                  width={width}
                  height={height}
                  rowHeight={({ index }) => productStore.items[index]?.image ? 122 : 91}
                  rowCount={productStore.items.length}
                  rowRenderer={renderer(productStore.items, <ProductItem handleSelect={handleSelect} />)}
                  overscanRowCount={10}
                  style={s}
                />
              )}
            </AutoSizer>
          )}
        </Col>
        {!isMobile && (
          <Col>
            <DesktopCart open={true} permanent anchor='left' />
          </Col>
        )}
        <Add open={selected} onClose={handleClose} onConfirm={handleAdd} item={selectedItem} />
        <Accessibility open={accessibility} onClose={() => setAccessibility(false)} />
      </PPage>
    </>
  )
}

export default inject('productStore', 'cartStore')(observer(Home))
