import React, { useMemo } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, List, ListItem, Divider, ListItemText, Button, Tooltip } from "@mui/material"
import styled from "styled-components"

import { useTranslate } from "react-admin"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  inline: {
    display: "inline"
  }
}))

const SListItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justifu-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
`

const Category = styled.div`
  color: white;
  font-weight: 300;
  display: inline-block;
  background: ${(p) => p.color};
  box-shadow: 0 5px 9px -4px ${(p) => p.color};
  padding: 0 0.5rem;
  border-radius: 8px;
  font-size: 0.60rem;
  margin-left: 0.5rem;
  width: max-content;
`

const Measure = styled(Typography)`
  color: rgb(153, 153, 153);
  text-align: right;
  white-space: nowrap;
  font-size: 1em;
`

const Price = styled(Typography)`
  line-height: 1;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  word-break: normal;
  color: rgb(96, 96, 119);
  text-align: start;
`

const AddButton = styled(Typography)`
  text-align: right;
  color: rgb(32, 149, 173);
  font-size: 1.3rem;
`
const ListItemStyled = styled(ListItem)`
  padding-right: 2px;
  padding-left: 2px;
`

const secondaryTypographyProps = { component: "div" }

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`

const ImageWrap = styled.div`
  width: 100px;
  height: 100px;
  margin-left: 0.5rem;
  border-radius: 8px;
  border: 1px solid gray;
`

const ProductsList = ({ handleSelect, products }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const renderText = (text) => {
    if (text.length > 60) {
      return text.substring(0, 60) + "..."
    }

    return text
  }

  return (
    <List className={classes.root}>
      {products.map((product) => {
        const { id, name, measure, price, category, image, status } = product
        const hue = category.split("").reduce((num, curr, index) => index + curr.charCodeAt(0) + ((num << 5) - num), 0) % 360
        const color = `hsla(${hue}, 45%, 60%, 75%)`
        const isOutOfStocks = status == 'unavailable'

        return (
          <Tooltip title={isOutOfStocks ? translate('customRoot.products.status.unavailable') : ''} >
          <div key={product.id} style={ isOutOfStocks ? {opacity: '0.3'} : {}}>
            <ListItemStyled>
              <SListItem>
                {image && (
                  <ImageWrap>
                    <Image src={image || ""} height='100' />
                  </ImageWrap>
                )}

                <ListItemText
                  primary={renderText(name, true)}
                  secondaryTypographyProps={secondaryTypographyProps}
                  secondary={<Category color={color}>{renderText(category)}</Category>}
                />

                <div>
                  <Price thin='true'>
                  <span style={{ direction: "ltr", display: "inline-block", textAlign: "right" }}>{price}</span> ₪
                  </Price>
                  <Measure thin='true'>{translate("customRoot.cashier.measure_unit")}</Measure>
                </div>
                <Button
                  disabled={isOutOfStocks}
                  onClick={() =>
                    handleSelect({
                      id,
                      name,
                      measure,
                      price,
                      category
                    })
                  }
                >
                  <AddButton name={`${translate("customRoot.cashier.add_to_cart")} ${name}`}>{translate("customRoot.cashier.add_to_cart")}</AddButton>
                </Button>
              </SListItem>
            </ListItemStyled>
            <Divider />
          </div>
          </Tooltip>
        )
      })}
    </List>
  )
}

export default ProductsList
