import React from "react"
import {
  email,
  number,
  required,
  SimpleForm,
  TextInput, useListController,
  useNotify,
  useRecordContext, useResourceContext,
  useTranslate
} from "react-admin"
import CreateOrEdit from "../common/CreateOrEdit"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import useFetch from "use-http"
import styled from "styled-components"
import { useEffect, useState } from "react"
import { isValidIsraeliID } from '@meshek-gal/validation'
import { observer, inject } from 'mobx-react'

const SimpleFormStyled = styled(SimpleForm)`
  @media (min-width: 800px) {
    width: 50%;
  }  

  width: 100%;
  .ra-input {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const Form = ({ isCreate, socialIds, ...props }) => {
  const location = useLocation();
  const record = useRecordContext(props);
  const translate = useTranslate();

  const is_create_external = isCreate ? (location.state.is_create_external || '') : !!record.socialId

  const validateEmail = email()

  const validateSocialId = (value) => {
    if (!isCreate) return undefined;

    if (socialIds.includes(value)) {
      return translate('errors.customers.social_id.exists');
    }
    if (!isValidIsraeliID(value)) {
      return translate('errors.customers.social_id.invalid');
    }
    return undefined;
  };

  return <SimpleFormStyled {...props} redirect='list'>
    { is_create_external ?
      <TextInput source='socialId' label='customRoot.customers.social_id' disabled={!isCreate} validate={[required(), number(), validateSocialId]}/>
      :
      <TextInput source='budgetNumber' label='customRoot.customers.budget_number' disabled={!isCreate} validate={required()} />
    }
    <TextInput source='firstName' label='customRoot.customers.firstname' disabled={!isCreate} validate={required()} />
    <TextInput source='lastName' label='customRoot.customers.lastname' disabled={!isCreate} validate={required()} />
    <TextInput source='mobile' label='customRoot.customers.phone' defaultValue=""/>
    <TextInput source='email' label='customRoot.customers.email' validate={validateEmail} defaultValue=""/>
  </SimpleFormStyled>;
}

const CustomersCreateOrEdit = ({ authStore, ...props }) => {
  let location = useLocation();
  const translate = useTranslate()
  const navigate = useNavigate();
  const notify = useNotify()
  const resource = useResourceContext();
  const [socialIds, setSocialIds] = useState([])

  const isCreate = /create/.test(location.pathname)

  const controllerProps = useListController(props)
  const { filterValues } = controllerProps

  const redirect = location.state.redirect || '/customers'
  const resultStorageByResource = authStore.getResource(resource)

  const org_id = filterValues.org_id || resultStorageByResource?.org_id
  const system_id = filterValues.system_id || resultStorageByResource?.system_id

  const { get } = useFetch(`/api/v1/organizations/${org_id}/systems/${system_id}/customers`, { cachePolicy: "no-cache" })

  const getCustomers = () => {
    get().then(res => {
      setSocialIds(res.data.map(customer => customer.socialId).filter(n => n))
    })
  }

  useEffect(() => {
    getCustomers()
  }, [org_id, system_id])

  if (isCreate && !system_id) {
    return <Navigate to='/customers' />
  }

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate(redirect);
  };

  return (
    <CreateOrEdit {...props} mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <Form isCreate={isCreate} socialIds={socialIds}/>
    </CreateOrEdit>
  )
}

export default inject('authStore')(observer(CustomersCreateOrEdit))
