import {  makeObservable, observable, action, computed, toJS, reaction } from 'mobx'
import settings from "../../tools/settings"
import { Enum } from "../../tools/utils"

export const PermissionsEnum = Enum({
  create_org_manager: "create_org_manager",
  create_pos_manager: "create_pos_manager",
  create_operator: "create_operator",
  update_org_manager: "update_org_manager",
  update_pos_manager: "update_pos_manager",
  update_operator: "update_operator",
  delete_org_manager: "delete_org_manager",
  delete_pos_manager: "delete_pos_manager",
  delete_operator: "delete_operator",
  create_org: "create_org",
  update_org: "update_org",
  delete_org: "delete_org",
  get_list_org: "get_list_org",
  create_system: "create_system",
  update_system: "update_system",
  delete_system: "delete_system",
  get_list_system: "get_list_system",
  create_order: "create_order",
  update_order: "update_order",
  delete_order: "delete_order",
  get_list_order: "get_list_order",
  update_order_settings: "update_order_settings",
  upload_product: "upload_product",
  create_product: "create_product",
  update_product: "update_product",
  delete_product: "delete_product",
  get_list_product: "get_list_product",
  upload_customer: "upload_customer",
  update_customer: "update_customer",
  get_list_customer: "get_list_customer",
  delete_customer: "delete_customer",
  create_customer: "create_customer"
})

export const RolesEnum = Enum({
  SUPER_ADMIN: "super admin",
  ORG_MANAGER: "organization manager",
  POS_MANAGER: "pos manager",
  OPERATOR: "operator"
})


const apiUrl = settings.apiUrl

class AuthStore {
  _roles = []
  _perms = []
  _ids = {}
  _user = {}
  isAutenticated = false

  resource = '';
  resouceData = {};
  
  constructor() {
    makeObservable(this, {
      _roles: observable,
      _perms: observable,
      _user: observable,
      _ids:  observable,
      resource: observable,
      resouceData: observable,
      isAutenticated: observable,
      login: action,
      getAuthData: action,
      getUser: action,
      getRoles: action,
      checkAuth: action,
      logout: action,

      setResource: action,
      getResource: action,
      getSettings: action,
      getPerms: action,

      isSuperAdmin: action,
      isOperator: action,
      isOrgManager: action,
      isPosManager: action,
      permissionCreateRoles: action,
      permissionUpdateRoles: action,
      isEditUserPermissions: action,
      isCreateUserPermissions: action,
      isDeleteUserPermissions: action,
      isCreateOrgPermissions: action,
      isEditOrgPermissions: action,
      isDeleteOrgPermissions: action,
      isDownloadOrgPermissions: action,
      isDownloadSystemPermissions: action,
      isCreateSystemPermissions: action,
      isEditSystemPermissions: action,
      isDeleteSystemPermissions: action,
      isDownloadOrderPermissions: action,
      isCreateOrderPermissions: action,
      isEditOrderPermissions: action,
      isDeleteOrderPermissions: action,
      isDownloadProductPermissions: action,
      isExportProductPermissions: action,
      isCreateProductPermissions: action,
      isEditProductPermissions: action,
      isDeleteProductPermissions: action,
      isDownloadCustomerPermissions: action,
      isExportCustomerPermissions: action,
      isCreateCustomerPermissions: action,
      isEditCustomerPermissions: action,
      isEditOrderSettingsPermissions: action,
    })
  }

  checkAuth = async () => {
    if (!this.isAutenticated) {
      await this.getAuthData();

      return this.isAutenticated;
    } 

    return true;
  }

  setResource = (resource, data) => {
    this.resource = resource;
    this.data = data
  }

  getResource = (resource) => {
    if (resource != this.resource) {
      return { }
    }
 
    return this.data
  }

  getSettings = () => {
    return toJS(this._ids)
  }
  
  getUser = () => {
    return toJS(this._user)
  }

  getRoles = () => {
    return toJS(this._roles)
  }

  getPerms = () => {
    return toJS(this._perms)
  } 

  getAuthData = async () => {
    const response = await fetch(
      new Request(`${apiUrl}/api/v1/me`, {
        method: "GET",
        credentials: "include",
        headers: new Headers({ "Content-Type": "application/json" })
      })
    )

    const { data } = await response.json()
    
    if (response.status !== 200) throw new Error('')
 
    const permissions = data.permissions.reduce((accumulator, value) => {
      return { ...accumulator, [value.name]: value.isAllowed }
    }, {})

    this._roles = data.roles
    this._perms = permissions
    this._user = data
    
    if (!this.isSuperAdmin()) {
      this._ids = { org_id: data.organizationId, system_id: data.systemId }
    }

    this.isAutenticated = true

    return true
  }
  
  login = async ({ username, password }) => {
    const request = new Request(`${apiUrl}/api/v1/auth/login`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    })

    const loginResponse = await fetch(request)
    if (loginResponse.status !== 200) throw new Error('הכניסה נכשלה, אנא נסו שנית')
  }

  logout = async () => {
    try {
      const request = new Request(`${apiUrl}/api/v1/auth/logout`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ logout: true }),
        headers: new Headers({ "Content-Type": "application/json" })
      })

      const loginResponse = await fetch(request)
      //if (loginResponse.status !== 200) throw new Error(loginResponse.statusText)

      this.isAutenticated = false
      this._roles = []
      this._perms = {}
      this._user = null
      this.isSuper = false
      this._ids = null

      return true
    } catch (e) {
      return true
    }  
  }

  isSuperAdmin = () => toJS(this._roles).find((el) => el === RolesEnum.SUPER_ADMIN)
  isOperator = () => { console.log(toJS(this._roles)); return toJS(this._roles).find((el) => el === RolesEnum.OPERATOR) && toJS(this._roles).length === 1 }
  isOrgManager = () => toJS(this._roles).find((el) => el === RolesEnum.ORG_MANAGER)
  isPosManager = () => toJS(this._roles).find((el) => el === RolesEnum.POS_MANAGER)

  permissionCreateRoles = (roles) => {
    const isCreateOrgManager = toJS(this._perms)[PermissionsEnum.create_org_manager]
    const isCreatePosManager = toJS(this._perms)[PermissionsEnum.create_pos_manager]
    const { admin, org_manager, pos_manager, operator } = roles

    return [
      !!this.isSuperAdmin() && { id: "super admin", name: admin },
      isCreateOrgManager && { id: "organization manager", name: org_manager },
      isCreatePosManager && { id: "pos manager", name: pos_manager },
      { id: "operator", name: operator }
    ]
  }

  permissionUpdateRoles = (roles) => {
    const isUpdateOrgManager = toJS(this._perms)[PermissionsEnum.update_org_manager]
    const isUpdatePosManager = toJS(this._perms)[PermissionsEnum.update_pos_manager]
    const { admin, org_manager, pos_manager, operator } = roles

    return [
      !!this.isSuperAdmin() && { id: "super admin", name: admin },
      isUpdateOrgManager && { id: "organization manager", name: org_manager },
      isUpdatePosManager && { id: "pos manager", name: pos_manager },
      { id: "operator", name: operator }
    ]
  }

  isEditUserPermissions = () => {
    if (!!this.isSuperAdmin()) {
      return true
    } else {
      const isOrgManager = toJS(this._perms)[PermissionsEnum.update_org_manager]
      const isPosManager = toJS(this._perms)[PermissionsEnum.update_pos_manager]
      const isOperator = toJS(this._perms)[PermissionsEnum.update_operator]
      return isOrgManager || isPosManager || isOperator
    }
  }

  isCreateUserPermissions = () => {
    if (!!this.isSuperAdmin()) {
      return true
    } else {
      const isOrgManager = toJS(this._perms)[PermissionsEnum.create_org_manager]
      const isPosManager = toJS(this._perms)[PermissionsEnum.create_pos_manager]
      const isOperator = toJS(this._perms)[PermissionsEnum.create_operator]
      return isOrgManager || isPosManager || isOperator
    }
  }

  isDeleteUserPermissions = () => {
    if (!!this.isSuperAdmin()) {
      return true
    } else {
      const isOrgManager = toJS(this._perms)[PermissionsEnum.delete_org_manager]
      const isPosManager = toJS(this._perms)[PermissionsEnum.delete_pos_manager]
      const isOperator = toJS(this._perms)[PermissionsEnum.delete_operator]
      return isOrgManager || isPosManager || isOperator
    }
  }

  isCreateOrgPermissions         = () => toJS(this._perms)[PermissionsEnum.create_org]
  isEditOrgPermissions           = () => toJS(this._perms)[PermissionsEnum.update_org]
  isDeleteOrgPermissions         = () => toJS(this._perms)[PermissionsEnum.delete_org]
  isDownloadOrgPermissions       = () => toJS(this._perms)[PermissionsEnum.get_list_org]
  isDownloadSystemPermissions    = () => toJS(this._perms)[PermissionsEnum.get_list_system]
  isCreateSystemPermissions      = () => toJS(this._perms)[PermissionsEnum.create_system]
  isEditSystemPermissions        = () => toJS(this._perms)[PermissionsEnum.update_system]
  isDeleteSystemPermissions      = () => toJS(this._perms)[PermissionsEnum.delete_system]
  isDownloadOrderPermissions     = () => toJS(this._perms)[PermissionsEnum.get_list_order]
  isCreateOrderPermissions       = () => toJS(this._perms)[PermissionsEnum.create_order]
  isEditOrderPermissions         = () => toJS(this._perms)[PermissionsEnum.update_order]
  isDeleteOrderPermissions       = () => toJS(this._perms)[PermissionsEnum.delete_order]
  isDownloadProductPermissions   = () => toJS(this._perms)[PermissionsEnum.get_list_product]
  isExportProductPermissions     = () => toJS(this._perms)[PermissionsEnum.upload_product]
  isCreateProductPermissions     = () => toJS(this._perms)[PermissionsEnum.create_product]
  isEditProductPermissions       = () => toJS(this._perms)[PermissionsEnum.update_product]
  isDeleteProductPermissions     = () => toJS(this._perms)[PermissionsEnum.delete_product]
  isDownloadCustomerPermissions  = () => toJS(this._perms)[PermissionsEnum.get_list_customer]
  isExportCustomerPermissions    = () => toJS(this._perms)[PermissionsEnum.upload_customer]
  isCreateCustomerPermissions    = () => toJS(this._perms)[PermissionsEnum.create_customer]
  isEditCustomerPermissions      = () => toJS(this._perms)[PermissionsEnum.update_customer]
  isEditOrderSettingsPermissions = () => toJS(this._perms)[PermissionsEnum.update_order_settings]
}

const authStore = new AuthStore()
export default authStore