import axios from 'axios'

const baseUrl = process.env.REACT_APP_API
const client = axios.create({
  baseURL: baseUrl,
})

export const endpoints = {
  items: (hash, q) => `/api/v1/orders/new/${hash}${q != '' ? '?q='+q : ''}`,
  settings: (hash) => `/api/v1/orders/new/${hash}/settings`,
  addOrder: (hash) => `/api/v1/orders/new/${hash}`,
  addOrderFs: (hash) =>  `/api/v1/orders/new/${hash}/fs`,
  getOrder: (hash) => `/api/v1/orders/new/${hash}/public`,
  chargeOrder: (hash) => `/api/v1/orders/new/${hash}/charge`,
}

export default client