import React from "react"
import {
  List,
  Datagrid,
  TextField,
  ReferenceInput,
  SelectInput,
  Filter,
  useListController,
  TopToolbar,
  useListContext,
  useTranslate,
  AutocompleteInput,
  usePermissions,
  useResourceContext
} from "react-admin"

import { Add } from "@material-ui/icons"
import GenerateButton  from "./GenerateButton"
import styled from "styled-components"
import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import ActionToolbar from "../common/ActionToolbar"
import { observer, inject } from 'mobx-react'
import FieldTitle from "../common/FieldTitle"
import { AutocompleteInputStyled } from "../common/InputFields"
import { EditButton } from '../common/EditButton'

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .column-price,
  .column-is_active,
  .column-undefined {
    width: 100px;
  }
`

const ItemsFilter = ({ isAdmin, isManager, ...props }) => {
  return (
    <Filter {...props}>
      {(isAdmin || isManager) && (
        <ReferenceInput
          label='resources.organizations.singular'
          reference='organizations'
          source='org_id'
          alwaysOn
          perPage={1000}
          sort={{ field: "name", order: "ASC" }}
        >
           <AutocompleteInputStyled
             className="autocompeleteMekome"
             label='resources.organizations.singular'
             matchSuggestion={(filter, choice) => choice.name.match(filter)}
             optionText="name" 
             optionValue="id"
            />
        </ReferenceInput>
      )}
    </Filter>
  )
}

const CButton = styled(Button)`
  color: #2196f3;
  padding: 2px;
`

const Actions = inject('authStore')(observer(({ authStore, ...props }) => {
  const { filterValues } = useListContext()
  const resource = useResourceContext();

  const { org_id } = filterValues
  const translate = useTranslate()

  return (
    <TopToolbar>
      {authStore.isCreateSystemPermissions() && (
        <CButton
          onClick={() => authStore.setResource(resource, { org_id })}
          component={Link}
          disabled={!org_id}
          to={{
            pathname: `/systems/create?org_id=${org_id}`,
            state: { org_id }
          }}
        >
          <Add />
          {translate("ra.action.create")}
        </CButton>
      )}
    </TopToolbar>
  )
}))

const SystemsList = ({ authStore,  ...props }) => {
  const resource = useResourceContext();
  const controllerProps = useListController(props)
  const { filterValues } = controllerProps

  const { org_id } = filterValues

  const resultStorageByIds = authStore.getSettings()
  const orgId = org_id || resultStorageByIds?.org_id
  
  const isAdmin = authStore.isSuperAdmin()
  const isManager = authStore.isOrgManager()

  return (
    <Container>
      <List
        {...props}
        filters={<ItemsFilter cprops={controllerProps} isAdmin={isAdmin} isManager={isManager} />}
        perPage={25}
        actions={<Actions {...props} />}
        empty={false}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source='name' label={<FieldTitle label='customRoot.organizations.list.display_name' />} sortable={false} />
          <TextField source='email' label={<FieldTitle label='customRoot.users.list.email'/>} sortable={false} />
          {authStore.isEditSystemPermissions() && (
            <ActionToolbar>
              <EditButton query={`?org_id=${orgId}`} onClick={() => authStore.setResource(resource, { org_id: orgId })} />
              {isAdmin ? <GenerateButton organizationId={orgId} /> : <></>}
            </ActionToolbar>
          )}
        </Datagrid>
      </List>
    </Container>
  )
}

export default inject('authStore')(observer(SystemsList))
