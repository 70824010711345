import React from "react"
import { makeStyles } from "@mui/styles"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@mui/material/Slide"
import styled from "styled-components"
import TextField from "@mui/material/TextField"
import ItemList from "./itemList"
import { useTranslate } from "react-admin"

const FormContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    direction: rtl;
    align-items: center;
  }
`

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  button: {
    margin: "0 auto",
    color: "#000000"
  },
  marginBottom: {
    marginBottom: "20px"
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const OrderModal = ({ open, setOpen, record }) => {
  const translate = useTranslate()
  const { orderNumber, customer } = record
  const { email, firstName, lastName, address, phone, budgetNumber, socialId } = customer
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  const handlePrint = () => {
    window.print()
  }

  return (
    <StyledDialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar className="noPrintOrderHeader">
          <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Button autoFocus variant='contained' onClick={handlePrint} className={classes.button} color="inherit">
            {translate("customRoot.orders.modal.print")}
          </Button>
        </Toolbar>
      </AppBar>
      <FormContainer>
        <TextField className="orderDetailMekome" fullWidth disabled defaultValue={orderNumber} label={translate("customRoot.orders.list.order_number")} />
        {
          socialId ?
            <TextField className="orderDetailMekome" fullWidth disabled defaultValue={socialId} label={translate("customRoot.orders.list.social_id")} />
            :
            <TextField className="orderDetailMekome" fullWidth disabled defaultValue={budgetNumber} label={translate("customRoot.orders.list.budget_number")} />
        }
        <TextField className="orderDetailMekome" fullWidth disabled defaultValue={`${firstName} ${lastName}`} label={translate("customRoot.orders.list.customer_name")} />
        <TextField className="orderDetailMekome" fullWidth disabled defaultValue={address} label={translate("customRoot.orders.list.customer_address")} />
        <TextField className="orderDetailMekome" fullWidth disabled defaultValue={email} label={translate("customRoot.orders.list.customer_email")} />
        <TextField className="orderDetailMekome" fullWidth disabled defaultValue={phone} label={translate("customRoot.orders.list.phone")} />
        <ItemList record={record} />
      </FormContainer>
    </StyledDialog>
  )
}

export default OrderModal
