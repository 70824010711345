import React, { useState } from "react"
import { SimpleForm, TextInput, required, email, Create, BooleanInput, useNotify, useTranslate } from "react-admin"
import { useNavigate } from 'react-router-dom';
import { observer, inject } from 'mobx-react'
import styled from "styled-components"

const SimpleFormStyled = styled(SimpleForm)`
  @media (min-width: 800px) {
    width: 50%;
  }  

  width: 100%;
  .ra-input {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const SystemsCreate = ({ authStore, ...props }) => {
  const validateRequired = [required(), email()];
  const [isActivePaymentCard, setIsActivePaymentCard ] = useState(false);

  const isAdmin = authStore.isSuperAdmin()

  const translate = useTranslate()
  const navigate = useNavigate();
  const notify = useNotify()

  const onSuccess = () => {
    notify(translate("customRoot.upload.successMessage"), { type: "success" })
    navigate('/systems');
  };

  return (
    <Create {...props} mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <SimpleFormStyled>
        <TextInput source='name' label='customRoot.organizations.list.display_name' validate={required()} />
        <TextInput source='email' label='customRoot.users.list.email' validate={validateRequired} />
        <TextInput source='externalId' label='customRoot.systems.list.externalId' />
        { isAdmin && (
          <BooleanInput source='settings.enableSendSmsWithOrderDetail' label='customRoot.systems.settings.enableSendSmsWithOrderDetail'/>
        )}
         <BooleanInput source='paymentSettings.active'defaultValue={false} label='customRoot.systems.list.active' onChange={() => {setIsActivePaymentCard(!isActivePaymentCard)}}/>
        {
          isActivePaymentCard && (
            <div style={{ width: '100%', display: "flex", flexDirection: "column" }}>
              <TextInput defaultValue={''} source='paymentSettings.paymentsOs.businessNumber' label='customRoot.organizations.list.business_number' validate={isActivePaymentCard && required()} />
              <TextInput defaultValue={''} source='paymentSettings.paymentsOs.publicKey' label='customRoot.organizations.list.public_key' validate={isActivePaymentCard && required()} />
              <TextInput defaultValue={''} source='paymentSettings.paymentsOs.privateKey' label='customRoot.organizations.list.private_key' validate={isActivePaymentCard && required()} />
              <TextInput defaultValue={''} source='paymentSettings.paymentsOs.appId' label='customRoot.organizations.list.app_id' validate={isActivePaymentCard && required()} />
              <TextInput defaultValue={''} source='paymentSettings.chargePage.logoLink' label='customRoot.organizations.list.logo_link' />
              <TextInput defaultValue={''} source='paymentSettings.chargePage.titleText' label='customRoot.organizations.list.title_text' />
              <TextInput defaultValue={''} source='paymentSettings.chargePage.bodyText' label='customRoot.organizations.list.body_text' />
              <TextInput defaultValue={''} source='paymentSettings.chargePage.businessAddress' label='customRoot.organizations.list.business_address' />
              <TextInput defaultValue={''} source='paymentSettings.chargePage.businessPhone' label='customRoot.organizations.list.business_phone' />
            </div>
          )
        }
      </SimpleFormStyled>
    </Create>
  )
}

export default inject('authStore')(observer(SystemsCreate))
