import React, { useState } from 'react'
import styled from 'styled-components'
import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    Select, 
    DialogActions, 
    MenuItem, 
    Button, 
    DialogContentText,
    TextField 
} from '@mui/material'
import { Add as AddIcon, Clear } from '@mui/icons-material'
import theme from '../utils/theme'
import Input from './Input'
import Space from './Space'
import { useInput } from '../utils/hooks'
import { useTranslate } from 'react-admin'
import { Grid } from "@mui/material"

const StyledSelect = styled(Select)`
  width: 100%;
  padding: 0 0.5rem;
`
const DialogContainer = styled(DialogContent)`
  min-width: 20vw;
  @media (max-width: 700px) {
    min-width: 70vw;
  }
`
const StyledTextField = styled(TextField)`
  width: 100%;
  margin-top: 20px;
`

const Add = ({ item, onClose, onConfirm, ...props }) => {
  const t = useTranslate()

  const { id = '', name = '', measure = '', price = 0, category = '' } = item
  const range = measure === "kg" 
    ? [...Array(20).keys()].map((key) => ((key + 1) * 0.1).toFixed(3)) 
    : [...Array(20).keys()].map((key) => key + 1)
  
  const remarks = useInput({
    label: t`publicOrder.item_remarks`,
    placeholder: t`publicOrder.item_remarks_placeholder`,
  })
  
  const [quantity, setQuantity] = useState(range[0])
  const [quantityType, setQuantityType] = useState('count')
  const [value, setValue] = useState('')

  const handleClear = () => {
    setQuantityType('count')
    setQuantity(1)
    setValue('')
  }

  const handleClose = (e) => {
    onClose(e)
    handleClear()
  }

  const handleSelectWeight = (e) => {
    const value = e.target.value

    if (value == '') {
      setQuantity('')
    }

    if (/^(0|[1-9]+)(\.\d{1,3})?$/g.test(value)) {
      setQuantity(value)
    }

    if (/^\.\d{1,3}$/g.test(value)) {
      setQuantity('0' + value)
    }

    if (/^(0|[1-9]+)\.$/g.test(value)) {
      setQuantity(value.trim('.'))
    }
  }

  const handleSelect = e => setQuantity(e.target.value)
  const handleConfirm = () => {
    const orderDetail = { id, name, price, quantity, category, remarks: remarks.value }
    onConfirm(orderDetail)
    handleClear()
  }

  const handleSelectQuantityType = (e)  => {
    setQuantityType(e.target.value)

    if (e.target.value == 'weight') {
      setQuantity('1.250')
    }
    if (e.target.value == 'count') {
      setQuantity(range[0])
    }
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }
  
  return (
    <Dialog {...props} onExit={handleClear} maxWidth='md'>
      <DialogTitle>{t('publicOrder.how_many')}</DialogTitle>
      
      <DialogContainer dividers>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledSelect value={quantityType} onChange={handleSelectQuantityType}>
              <MenuItem key={'count'} value={'count'}>
                {t("customRoot.cashier.how_many")}
              </MenuItem>
              <MenuItem key={'weight'} value={'weight'}>
                {t("customRoot.cashier.weight")}
              </MenuItem>
            </StyledSelect>
          </Grid>  
          <Grid item xs={6}>
            {quantityType == 'count' && (
              <StyledSelect value={quantity} onChange={handleSelect}>
                {range.map((unit) => (
                  <MenuItem key={unit} value={unit}>{unit}</MenuItem>
                ))}
              </StyledSelect>
            )}

            {quantityType == 'weight' && (
              <TextField
                placeholder={1.500}
                id='outlined-multiline-static'
                variant='outlined'
                value={quantity}
                onChange={handleSelectWeight}
              />
            )}
          </Grid>
       </Grid>
        <StyledTextField
          label={t("customRoot.cashier.item_remarks")}
          placeholder={t("customRoot.cashier.item_remarks_placeholder")}
          multiline
          id='outlined-multiline-static'
          rows={4}
          variant='outlined'
          value={value}
          onChange={handleChange}
        />
      </DialogContainer>

      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button style={{ color: `${theme.color.green}` }} onClick={handleConfirm} disabled={!quantity}><AddIcon />{t('publicOrder.add_to_cart')}</Button>
        <Button style={{ color: `${theme.color.red}` }} onClick={handleClose}><Clear />{t('publicOrder.cancel')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default Add