import React, { useEffect, useState } from "react"
import { CREATE, GET_LIST, GET_ONE, Title, UPDATE, useDataProvider, usePermissions, useTranslate } from "react-admin"
import styled from "styled-components"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import SendToMobileIcon from "@material-ui/icons/Smartphone"
import { observer, inject } from 'mobx-react'

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Snackbar,
  TextField,
  Typography
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { RoutesEnum } from "../../core/restProvider"
import CartList from "./CartList"
import TotalInfo from "./TotalInfo"
import { Check, ChevronLeft, Clear } from "@material-ui/icons"
import CustomerSearch from "./CustomerSearch"
import CreateExternalCustomerButton from "../customers/CreateExternalCustomerButton"

const Container = styled.div``

const ProductContainer = styled.div`
  flex: 1;
  margin-right: 5vh;
  @media (max-width: 900px) {
    margin-right: 0;
  }
`

const CustomerContainer = styled.div`
  flex: 1;
  @media (max-width: 900px) {
    min-height: 20vh;
  }
`

const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  @media (max-width: 900px) {
    padding: 0 10px;
    flex-direction: column;
  }
`

const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 15px;
`

const StyledFormContainer = styled.div`
  margin-top: 40px;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const BackButton = styled(Button)`
  color: rgb(32, 149, 173);
`

const CheckoutTitle = styled(Typography)`
  font-size: 1.3rem;
  font-weight: 700;
  word-break: normal;
  text-align: start;
`

const ConfirmButton = styled(Button)`
  width: 100%;
  min-width: calc(30% - 10px);
  color: white;
  background: linear-gradient(160deg, rgb(130, 217, 140) 0%, rgb(38, 151, 40) 100%);
  box-shadow: rgb(38 151 40 / 75%) 0px 6px 10px -4px;
  border-radius: 8px;
  border: none !important;
  margin-right: 10px;
`

const PaymentButton = styled(Button)`
  width: 100%;
  min-width: calc(30% - 10px);
  color: white;
  background: linear-gradient(160deg, rgb(32, 149, 173) 0%, rgb(32, 149, 173) 100%);
  box-shadow: rgb(32, 149, 173 / 75%) 0px 6px 10px -4px;
  border-radius: 8px;
  border: none !important;
`

const PaymentViaSmsButton = styled(PaymentButton)`
  margin-left: 10px;
`

const CancelButton = styled(Button)`
  width: 100%;
  min-width: calc(30% - 10px);
  color: white;
  background: linear-gradient(160deg, rgb(193, 54, 54) 0%, rgb(193, 54, 54) 100%);
  box-shadow: rgb(193, 54, 54 / 75%) 0px 6px 10px -4px;
  border-radius: 8px;
  border: none !important;
`

const ControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Checkout = ({ authStore }) => {
  const restProvider = useDataProvider()
  const navigate = useNavigate()

  const translate = useTranslate()
  const [isOpenSnackbar, setOpenSnackbar] = useState(false)
  const [snackbar, setSnackbar] = useState({})
  const [search, setSearch] = useState("")
  const [sum, setSum] = useState(0)
  const [products, setSelectedProducts] = useState([])
  const [customers, setCustomers] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [systems, setSystems] = useState({})
  const [disableButton, setDisableButton] = useState(false)
  const [customerLocationState, setCustomerLocationState] = useState({
    org_id: null,
    system_id: null
  })

  const [ids, setIds] = useState(null)
  const [customer, setCustomer] = useState({
    firstName: "required",
    lastName: "required",
    budgetNumber: "required",
    address: "required",
    email: "required",
    phone: "required",
    showAddExternalCustomer: "hidden"
  })

  const [open, setOpen] = useState(false)
  const [isShowErrorField, setIsShowErrorField] = useState({
    email: false,
    mobile: false
  })

  const handleBack = () => navigate(-1)
  const handleCancel = () => navigate('/cashier')

  const openSnackbar = (message, severity) => {
    setOpenSnackbar(true)
    setSnackbar({ message: message, severity: severity })
  }

  const getCustomers = async (org_id, system_id) => {
    try {
      const { data } = await restProvider(GET_LIST, RoutesEnum.CUSTOMERS, {
        filter: { org_id, system_id },
        sort: {},
        pagination: { page: 1, perPage: 1000 }
      })

      const { data: systemsData } = await restProvider(GET_ONE, RoutesEnum.SYSTEMS, {
        id: system_id, withPublicPaymentCreds: 1
      })
      setCustomers(data)
      setSystems(systemsData)
    } catch (error) {
      openSnackbar(error.message, "error")
    }
  }

  useEffect(() => {
    const state = JSON.parse(localStorage.getItem("cashier"))
    if (state) {
      const { selectedProducts, sum, orgId, systemId } = state
      
      setSelectedProducts(selectedProducts)
      setIds([orgId, systemId])
      setCustomerLocationState({
        org_id: orgId,
        system_id: systemId
      })
      setSum(sum)
      getCustomers(orgId, systemId)
      if (orgId && systemId) {
        getOrderSettings(orgId, systemId)
      }
    }
  }, [])

  const snackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }

  const onChange = async (_, value) => {
    setIsShowErrorField({ email: false, mobile: false })
    setSelectedCustomer(value)
  }


  const getOrderSettings = async (organizationId, systemId) => {
    const { data } = await restProvider(GET_ONE, RoutesEnum.ORDER_SETTINGS, {
      organizationId,
      systemId
    })
    setCustomer(data.customer)
  }

  const handleCheckValidField = () => {
    let isOneErrorFields = false

    if (!selectedCustomer.email && customer.email === "required") {
      setIsShowErrorField((prev) => ({ ...prev, email: true }))
      isOneErrorFields = true
    }

    if (!selectedCustomer.mobile && customer.phone === "required") {
      setIsShowErrorField((prev) => ({ ...prev, mobile: true }))
      isOneErrorFields = true
    }

    return isOneErrorFields
  }

  const createOrder = async (orderData) => {
    const { data } = await restProvider(CREATE, RoutesEnum.ORDERS, {
      filter: orderData
    })

    return data
  }

  const payWithSMS = async () => {
    setDisableButton(true)
    const isCheckValidField = handleCheckValidField()

    if (isCheckValidField) {
      setOpen(true)
      setDisableButton(false)
    } else {
      try {
        await restProvider(UPDATE, RoutesEnum.BY_OPERATOR, {
          data: selectedCustomer
        })

        const orderData = {
          customer: { ...selectedCustomer, phone: selectedCustomer.mobile, address: "NY" },
          items: products.map((product) => ({ pricePerOne: product.price, ...product })),
          organizationId: ids[0],
          systemId: ids[1],
          paymentMethod: 'payment_with_sms',
          status: 'received'
        }

        await createOrder(orderData)
        navigate("/confirm")
        setDisableButton(false)

      } catch (error) {
        setDisableButton(false)
        if (error?.body &&  error?.body?.error) {
          openSnackbar(error.body.error, "error")
        } else {
          openSnackbar(error.message, "error")
        }
      }
    }
  }

  const handleOrder = async ({ isPayment }) => {
    const isCheckValidField = handleCheckValidField()
    setDisableButton(true)


    if (isCheckValidField) {
      setOpen(true)
      setDisableButton(false)
    } else {
      try {
        await restProvider(UPDATE, RoutesEnum.BY_OPERATOR, {
          data: selectedCustomer
        })

        const orderData = {
          customer: { ...selectedCustomer, phone: selectedCustomer.mobile, address: "NY" },
          items: products.map((product) => ({ pricePerOne: product.price, ...product })),
          organizationId: ids[0],
          systemId: ids[1],
          paymentMethod: 'payment_with_fs',
          status: 'done'
        }

        if (isPayment) {
          localStorage.setItem("payments", JSON.stringify({ systems, order: orderData }))
          navigate(`/charge`)
          setDisableButton(false)
          return
        }

        await createOrder(orderData)
        navigate("/confirm")
        setDisableButton(false)

      } catch (error) {
        openSnackbar(error.message, "error")
        setDisableButton(false)
      }
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setSelectedCustomer((prev) => ({ ...prev, [name]: value }))

    if (event.target.value && isShowErrorField[name]) {
      setIsShowErrorField((prev) => ({ ...prev, [name]: false }))
    }

    if (!event.target.value && !isShowErrorField[name]) {
      const customerField = !!customer[name] ? customer[name] : customer.phone

      if (customerField === "required") {
        setIsShowErrorField((prev) => ({ ...prev, [name]: true }))
      }
    }
  }
  const { permissions } = usePermissions()

  return (
    <>
    <Title title={translate("customRoot.myMenu.cashier")} />
    <Container>
      <BodyContainer>
        <CustomerContainer>
          <TitleContainer>
            <CheckoutTitle>{translate("customRoot.cashier.checkout_title")}</CheckoutTitle>
            <BackButton onClick={handleBack}>
              {translate("customRoot.cashier.back")} <ChevronLeft />
            </BackButton>
          </TitleContainer>
          <CustomerSearch
            customers={customers}
            value={search}
            handleChange={onChange}
            setSearch={setSearch}
            setSelectedCustomer={setSelectedCustomer}
            selectedCustomer={selectedCustomer}
          />

          { authStore.isCreateCustomerPermissions() && customer.showAddExternalCustomer !== "hidden" && (
            <CreateExternalCustomerButton
              style={{ "margin-top": "30px" }}
              navigationUrl='../customers/create'
              orgId={customerLocationState.org_id}
              systemId={customerLocationState.system_id}
              redirect='/checkout'
            />
          )}

          { selectedCustomer && (
            <StyledFormContainer>
              { selectedCustomer.socialId ?
                <>
                  { customer.budgetNumber !== "hidden" && (
                    <StyledTextField
                      variant='outlined'
                      label={translate("customRoot.customers.social_id")}
                      value={selectedCustomer.socialId}
                      required={customer.budgetNumber === "required"}
                      disabled
                    />)
                  }
                </>
                :
                <>
                  { customer.budgetNumber !== "hidden" && (
                    <StyledTextField
                      variant='outlined'
                      label={translate("customRoot.customers.budget_number")}
                      value={selectedCustomer.budgetNumber}
                      required={customer.budgetNumber === "required"}
                      disabled
                    />)
                  }
                </>
              }
              {customer.firstName !== "hidden" && (
                <StyledTextField
                  variant='outlined'
                  label={translate("customRoot.customers.firstname")}
                  value={selectedCustomer.firstName}
                  required={customer.firstName === "required"}
                  disabled
                />
              )}
              {customer.lastName !== "hidden" && (
                <StyledTextField
                  variant='outlined'
                  label={translate("customRoot.customers.lastname")}
                  value={selectedCustomer.lastName}
                  required={customer.lastName === "required"}
                  disabled
                />
              )}
              {customer.phone !== "hidden" && (
                <StyledTextField
                  name='mobile'
                  variant='outlined'
                  value={selectedCustomer.mobile}
                  error={isShowErrorField.mobile}
                  required={customer.phone === "required"}
                  label={translate("customRoot.customers.phone")}
                  onChange={handleChange}
                />
              )}
              {customer.email !== "hidden" && (
                <StyledTextField
                  name='email'
                  variant='outlined'
                  value={selectedCustomer.email}
                  error={isShowErrorField.email}
                  required={customer.email === "required"}
                  label={translate("customRoot.customers.email")}
                  onChange={handleChange}
                />
              )}
              <ControlContainer>
                <CancelButton variant='contained' onClick={handleCancel} disabled={disableButton}>
                  <Clear />
                  {translate("customRoot.cashier.cancel")}
                </CancelButton>
                { !selectedCustomer.socialId && selectedCustomer.budgetNumber &&
                  (
                    <ConfirmButton variant='contained' onClick={() => handleOrder({ isPayment: false })} disabled={disableButton}>
                      <Check /> {translate("customRoot.cashier.place_order")}
                    </ConfirmButton>
                  )
                }
              </ControlContainer>
              <ControlContainer>
                {sum > 0 && systems?.paymentSettings?.active && (
                  <PaymentViaSmsButton variant='contained' onClick={() => payWithSMS()} disabled={disableButton}>
                    <SendToMobileIcon /> {translate("customRoot.cashier.send_with_sms")}
                  </PaymentViaSmsButton>
                )}
                {sum > 0 && systems?.paymentSettings?.active && (
                  <PaymentButton variant='contained' onClick={() => handleOrder({ isPayment: true })} disabled={disableButton}>
                    <ArrowBackIcon /> {translate("payments.payment_order")}
                  </PaymentButton>
                )}
              </ControlContainer>
              {systems.settings.enableSendSmsWithOrderDetail && (
                <div style={{ marginTop: '20px' }}>סמס עם קישור לפרטי הקניה יישלח ללקוח עם השלמת הרכישה.</div>
              )}
            </StyledFormContainer>
          )}
        </CustomerContainer>
        <ProductContainer>
          <TotalInfo sum={sum} />
          <CartList list={products} />
        </ProductContainer>
      </BodyContainer>
      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={() => setOpen(false)} aria-describedby='alert-dialog-slide-description'>
        <DialogTitle>{translate("customRoot.cashier.popup_title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>{translate("customRoot.cashier.popup_text")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{translate("customRoot.cashier.popup_complete")}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isOpenSnackbar}
        autoHideDuration={6000}
        onClose={snackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
      >
        <Alert onClose={snackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
    </>
  )
}

export default inject('authStore')(observer(Checkout))
